<template>
  <header class="page-header" v-ui-test="'header'" :class="{ 'page-header--minimized': minimized }">
    <div class="page-header__wrapper">
      <MQ :query="{ maxWidth: breakpointWidth.Rabbit - 1 }" v-if="$slots.navigationToggle">
        <div class="page-header__navigation-toggle">
          <slot name="navigationToggle"></slot>
        </div>
      </MQ>
      <div class="page-header__brand">
        <div class="page-header__logo">
          <slot name="brandLogo"></slot>
        </div>
      </div>
      <MQ :query="{ minWidth: breakpointWidth.Rabbit }" v-if="$slots.navigation">
        <div class="page-header__navigation">
          <slot name="navigation"></slot>
        </div>
      </MQ>
      <div class="page-header__tools">
        <HeaderTools />
      </div>
    </div>
  </header>
</template>

<style lang="scss" src="./header.scss" />

<script lang="ts">
import HeaderTools from '@/src/core/components/header/header-tools/header-tools.vue';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { ScrollEvent, ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import Vue from 'vue';
import Component from 'vue-class-component';
import Media from '@/src/core/components/util/media-query/media-query.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    HeaderTools,
    MQ: Media,
  },
})
export default class Header extends Vue {
  @Prop({ default: false }) public minimize: boolean;
  public uiStore = useUIStore();
  public breakpointWidth = BreakpointWidth;
  private siteType = Site;

  private get site() {
    return this.uiStore.site;
  }

  private get isDropDownOpen() {
    return this.uiStore.navigationDropdownId !== null;
  }

  public get minimized(): boolean {
    return this.uiStore.navigationMinimized || this.minimize;
  }

  public set minimized(isMinimized) {
    if (isMinimized !== this.minimized) {
      this.uiStore.setNavigationMinimized({ minimized: isMinimized });
    }
  }

  private onScroll(scrollEvent: ScrollEvent) {
    this.minimized = scrollEvent.scrollPosition >= 50;
  }

  public mounted() {
    if (!this.minimize) {
      ScrollEventBus.$on('scroll', this.onScroll);
    } else {
      this.minimized = true;
    }
  }
  public destroyed() {
    if (!this.minimize) {
      ScrollEventBus.$off('scroll', this.onScroll);
    }
  }
}
</script>
