import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { useModalStore } from '@/src/core/stores/modal';
import { useScrollLockStore } from '@/src/core/stores/scroll-lock';
import { useUIStore } from '@/src/core/stores/ui';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class LockScroll extends Vue {
  public scrollLockStore = useScrollLockStore();
  public uiStore = useUIStore();
  public modalStore = useModalStore();
  private HTMLElement: HTMLElement;
  private noScrollClass: string = 'no-scroll';
  private noScrollSelectClass: string = 'no-scroll--select';
  private breakpointWidth = BreakpointWidth;

  private appContainer: HTMLElement | null;

  private get lastScrolledPosition() {
    return this.uiStore.lastScrollPos;
  }

  private set lastScrolledPosition(value) {
    this.uiStore.setLastScrollPosition({ scrollPos: value });
  }

  private get scrollLocked() {
    return this.scrollLockStore.isLocked;
  }

  private get selectIsOpen(): boolean {
    return !!this.uiStore.dropdownIsOpen;
  }

  private get modalIsOpen(): boolean {
    return this.modalStore.isVisible;
  }

  private isCat() {
    return this.breakpointWidth.Cat < window.innerWidth;
  }

  @Watch('selectIsOpen') private selectOpenLock(selectOpen: boolean) {
    if (!this.isCat()) {
      this.lockScrollPositionHandling(selectOpen, this.noScrollSelectClass);
    }
  }

  @Watch('scrollLocked') private scrollLockChanging(scrollLocked: boolean) {
    this.lockScrollPositionHandling(scrollLocked, this.noScrollClass);
  }

  private mobileScrollLockPositionHandling(locked: boolean) {
    const elements = document.querySelectorAll('.l-app-container');

    if (!elements) {
      return;
    }

    if (locked) {
      this.lastScrolledPosition = window.pageYOffset;
      this.HTMLElement.style.top = -this.lastScrolledPosition + 'px';
    } else {
      window.scrollTo(0, this.lastScrolledPosition);
      this.lastScrolledPosition = 0;
      this.HTMLElement.style.top = '';
    }
  }

  private lockScrollPositionHandling(scrollLocked: boolean, toggleClass: string) {
    if (this.HTMLElement) {
      if (scrollLocked) {
        this.mobileScrollLockPositionHandling(scrollLocked);
        this.HTMLElement.classList.add(toggleClass);
      } else {
        this.HTMLElement.classList.remove(toggleClass);
        this.mobileScrollLockPositionHandling(scrollLocked);
      }
    }
  }

  public mounted() {
    this.HTMLElement = document.documentElement;
  }
}
