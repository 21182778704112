import { SellerApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { MarketplaceMasterShop, MarketplaceShop } from '@/src/core/types/api';
import { IWindowServerRendered } from '@/src/core/types/ui';
import { srUnescape } from '@/src/core/utils/unescape';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const srSeller: HTMLElement | undefined = (window as unknown as IWindowServerRendered).srSeller;

export const useSellerDetailsStore = defineStore('sellerDetailsStore', () => {
  const content = ref<string>((srSeller && srSeller.innerHTML) || '');
  const id = ref<string>((srSeller && srSeller.getAttribute('sr-seller')) || '');
  const isBusy = ref<boolean>(false);
  const mastershop = ref<MarketplaceMasterShop | null>(null);
  const marketplaceShops = ref<MarketplaceShop[]>([]);

  const contentUpdated = (payload: { content: string; id: string }) => {
    content.value = srUnescape(payload.content);
    id.value = payload.id;
  };

  const reset = () => {
    content.value = '';
    id.value = '';
    isBusy.value = false;
    mastershop.value = null;
    marketplaceShops.value = [];
  };

  const fetchSellerDetails = async (payload: { id: string; reload?: boolean }) => {
    if (!payload.reload && payload.id === id.value) {
      return;
    } else {
      reset();
    }
    isBusy.value = true;
    const { IsSuccess, Data } = await Req({
      url: SellerApi.SellerDetails(payload.id),
    });

    if (IsSuccess && Data) {
      contentUpdated({
        content: Data,
        id: payload.id,
      });
    }

    isBusy.value = false;
    return { IsSuccess };
  };

  const fetchMastershopById = async (payload: { id: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      url: SellerApi.FetchMastershopById(payload.id),
    });

    if (IsSuccess && Data) {
      mastershop.value = Data;

      if (mastershop.value?.Shops && mastershop.value?.Shops.length > 0) {
        const orderedPromises = mastershop.value.Shops.map(({ Id }) => fetcShopById({ id: Id }));
        await Promise.all(orderedPromises);
      }
    }

    isBusy.value = false;
    return { IsSuccess, Data };
  };

  const fetcShopById = async (payload: { id: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      url: SellerApi.FetchShopById(payload.id),
    });

    if (IsSuccess && Data) {
      marketplaceShops.value = [...marketplaceShops.value, Data];
    }

    isBusy.value = false;
    return { IsSuccess, Data };
  };

  const setSellerDetails = async (payload: { content: string; id: string }) => {
    contentUpdated({
      content: payload.content,
      id: payload.id,
    });
  };

  const clear = async () => {
    reset();
  };

  return {
    content,
    id,
    isBusy,
    mastershop,
    marketplaceShops,
    fetchSellerDetails,
    fetchMastershopById,
    fetcShopById,
    setSellerDetails,
    clear,
    reset,
  };
});
