import { RouteConfig } from 'vue-router';

import { FeatureMap } from '@/src/core/services/features';
import { IAdobeTargetUIOptions } from '@/src/core/types/adobe';
import {
  AuthenticationPipelineModule,
  Carrier,
  CarrierSelection,
  Cart,
  CartEntryDetails,
  Facets,
  MarketplaceShopDocument,
  Order,
  Product,
  ProductAdditionalInfo,
  ProductAvailable,
  ProductInterchangeableItem,
  ProductList,
  ProductListEntry,
  ProductListErrors,
  ProductPlantsList,
  ProductPriceEntry,
  ProductSuggestions,
  Quote,
  QuoteEntryRequest,
  QuoteList,
  SellerGroup,
  Sorts,
  User,
  UserProfile,
  UserSearch,
} from '@/src/core/types/api';
import { UiSize } from '../components/ui/ui.types';

export interface IRootState {
  globalError?: string;
}

export interface ISearchList {
  Query: string;
  Page: number;
  TotalPages: number;
  ProductSkus: string[];
  ResultIds: ISearchResultId[];
  Products?: Product[];
  ActiveProductIndex: number;
  Count: number | null;
  Facets: Facets[];
  IsBusy: boolean;
  WarningEnabled: boolean;
  WarningText: string;
}

export interface ISearchResultId {
  Sku: string;
  Id: string;
}

export interface IProductQueueItem {
  OfferId?: string;
  ProductCode?: string; // DEPRECATED
  Quantity: number;
  WarehouseCode?: string;
}

export interface ICartQueueItem {
  productId: string;
  quantity: number;
  entryId?: number;
  calculated?: boolean;
  notify?: boolean;
  warehouse?: string | null;
}

export interface IProductAdditionalData extends ProductAdditionalInfo {
  IsBusy?: boolean;
  HasFailed?: boolean;
}

export interface ProductAirbusPart extends AirbusPart {
  IsBusy?: boolean;
  HasFailed?: boolean;
  interchangeables: [];
  exclusionRemark?: string;
}

export interface IWarehouses {
  CurrentQuantity?: number;
  Warehouses: CartEntryDetails[];
}

export interface ITextItem {
  [key: string]: string;
}

export interface ILinkItem {
  Key: string;
  Value: string;
  Data?: string;
  Title?: string;
}

export interface ILinkObject {
  Key: string;
  Reference: ILinkItem[];
}

export interface IAuthenticationObject extends AuthenticationPipelineModule {
  Authorized: boolean;
  ASM: IASM;
  IsBusy: boolean;
}

export interface IASM {
  Active: boolean;
  Name?: string;
  Id?: string;
  Email?: string;
}

export interface ICheckoutValidation {
  SellerId: string;
  PONumber: string;
  Priority: string;
  AcReg: string;
  Carrier: string;
  TermsAccepted: boolean;
  CustomerCode?: string;
  OrderType?: string;
  Remarks?: boolean;
}

export interface OrderType {
  ApplicableMastershops: string[];
  Code: string;
  Name: string;
}

export interface ICart extends Cart {
  SellerGroup: ISellerGroup[];
}
export interface ISellerGroup extends SellerGroup {
  TermsAndPolicies: MarketplaceShopDocument[];
  TermsAccepted: boolean;
  PONumber: string;
  CustomerCode?: string;
  OrderType?: string | OrderType;
}

export interface ISellerGroupPO {
  shopId: string;
  poNumber: string;
}

export interface ICarriers extends CarrierSelection {
  RecentCarriers: Carrier[];
}

export interface IProductPlantAvailabilityList {
  ProductPlantAvailabilities: IProductPlantAvailabilities[];
}

export interface IProductPlantAvailabilities extends ProductPlantsList {
  IsBusy: boolean;
  Timestamp: number;
}

export interface IProductRelationEntry {
  Timestamp: number;
  Sku: string;
  IsInterchangeablesLoaded?: boolean;
  HasInterchangeables?: boolean;
  IsInterchangeablesChecked?: boolean;
  IsRecommendationsChecked?: boolean;
  Interchangeables?: IDahSuggestionItem[];
  Recommendations?: IDahSuggestionItem[];
}

export interface IDahSuggestionItem extends ProductInterchangeableItem {
  Product?: Product;
  IsLoaded: boolean;
  Success: () => boolean;
}

export interface IProductSuggestionEntry extends ProductSuggestions {
  PartNumber: string;
}

export interface IUmbracoContent {
  Content?: string;
  Url: string;
  IsBusy: boolean;
}

export interface IFaqCategory {
  label?: string;
  tag?: string;
  url?: string;
}

export interface ImportErrors {
  ManufacturerAid: string;
  ManufacturerName: string;
  QtyString: string;
  Reason: string;
}

export interface IOrderPriority {
  Code: string;
  Name: string | undefined;
  Description: string | undefined;
  Restricted?: boolean;
}

export interface IOrderOverview {
  Orders: Order[];
  Count: number;
  Page: number;
  Query: string;
  TotalPages: number;
  TotalCount: number;
  CurrentOrderView: Order;
  RecentOrders?: Order[];
  Receipt: Order;
  Filters: IOrderListQuery;
  IsBusy: boolean;
}

export interface IOrderListQuery {
  purchaseOrderNumber?: string;
  code?: string;
  manufacturerAID?: string;
  masterShopName?: string;

  priority?: string;
  orderType?: string;
  status?: string;

  timePeriod?: string;
  fromDate?: string;
  toDate?: string;

  airCraftRegistrationNumber?: string;
}

export interface ILeaseOrderListQuery {
  purchaseOrderNumber?: string;
  orderNumber?: string;
  partNumber?: string;
  cageCode?: string;
  airCraftRegistrationNumber?: string;
  fromDate?: string;
  toDate?: string;
  status?: string;
}

export interface ILeaseOrderListFilters extends ILeaseOrderListQuery {
  [key: string]: string | undefined;
  timePeriod?: string;
}

export interface IRepairExchangeOrderListQuery {
  purchaseOrderNumber?: string;
  airbusSapOrderId?: string;
  orderNumber?: string;
  cageCode?: string;
  partNumber?: string;
  fromDate?: string;
  toDate?: string;
  airCraftRegistrationNumber?: string;
  status?: string;
}

export interface IRepairExchangeOrderListFilters extends IRepairExchangeOrderListQuery {
  [key: string]: string | undefined;
  timePeriod?: string;
}

export interface IQuoteListQuery {
  q?: string;
  qfacet?: string;
  originDisplay?: string;
  status?: string;
}

export interface Navigation {
  Status: string | null;
  CurrentId: number | null;
  Menu: NavigationItem[];
}

export interface NavigationItem {
  Id: number;
  Name: string;
  Url: string;
  SubItems: NavigationItem[];
  Breadcrumbs: NavigationBreadcrumbItem[];
  NoLink: boolean;
}

export interface NavigationBreadcrumbItem {
  Id: number;
  Name: string;
  Url: string;
}

export interface IPermissions {
  Newsletter?: boolean;
  Routine?: boolean;
  UrgentStockReplenishment?: boolean;
  WorkStoppage?: boolean;
  Aog?: boolean;
}

export interface IUser extends User {
  Permission?: IPermissions;
}

export interface TempUser extends User {
  Created?: boolean;
}

export interface IProfile extends UserProfile {
  CookiesAccepted: boolean;
  IsBusy: boolean;
}

export interface IList extends ProductList {
  CreatedBy: IUser;
  Entries: IListEntry[];
  ActiveProductIndex: number;
  UnresolvedEntries: ProductListErrors[];
  FilterOptions: IListFilterOptions;
}

export interface IListFilterOptions {
  SearchQuery?: string;
  IsCollapsed?: boolean;
}

export interface IListImportPreview {
  Data: string[][];
  Filename?: string;
  HasError: boolean;
  HeaderMappings: IListImportHeaders[];
  Mapping: IListImportMapping;
  HeadersUnidentified: boolean;
}

export interface IListImportMapping {
  HasHeaders: boolean;
  ManuIdx?: number;
  PnoIdx?: number;
  QtyIdx?: number;
  SkuIdx?: number;
}

export interface IListImportHeaders {
  IdxName: string;
  Label: string;
  Value: number;
}

export interface IListEntry extends ProductListEntry {
  Sku?: string;
  AvailabilityList: ProductAvailable[];
  EntryNumber: number;
}

export interface IListPagingOverview {
  ProductLists: IList[];
  Count: number;
  Page: number;
  TotalPages: number;
  Sorts: Sorts[];
}

export interface IQuoteDrafts extends ProductList {
  CreatedBy: IUser;
  Entries: IListEntry[];
  ActiveProductIndex: number;
  UnresolvedEntries: ProductListErrors[];
  FilterOptions: IListFilterOptions;
}

export interface IAdmin extends UserSearch {
  UserSearchQuery: string;
  CurrentUser: User;
  IsBusy: boolean;
}

export interface IUmbracoSearch {
  Count?: number;
  Results: IUmbracoSearchResult[];
}

export interface IUmbracoSearchResult {
  Score: number;
  Document: IUmbracoSearchDocument;
}

export interface IUmbracoSearchDocument {
  UmracoId: string;
  Created: string;
  Modified: string;
  Url: string;
  PageName: string;
  Structure: string[];
  Category?: string | null;
  Title: string;
  Answer?: string | null;
}

export interface IInitialWindow extends Window {
  routeConfig: RouteConfig[];
  umbracoId: number;
  features: FeatureMap;
}

export interface IModalLoadContent {
  key: string;
  track: boolean;
  targetOptions?: IAdobeTargetUIOptions;
  label: string;
  title?: string;
  url: string;
  size?: UiSize;
}

export interface IProductPriceEntry extends ProductPriceEntry {
  IsBusy?: boolean;
  HasFailed?: boolean;
}

export interface IFormData {
  [key: string]: IFormElement;
}

export interface IFormElement {
  value?: string | number | boolean;
  valid?: boolean;
}

export interface IQuoteOverview {
  Quotes: Quote[];
  Count: number;
  Page: number;
  Query: string;
  TotalPages: number;
  TotalCount: number;
  CurrentQuoteView: Order;
  IsBusy: boolean;
  Facets: Facets[];
}

export interface IUmbracoMediaSources {
  hex: string;
  images: ISources[];
}

export interface IReleaseNotes {
  BlockTitle: string;
  BlockContent: ContentBlock[];
  Url: string;
  IsLillyStyling: boolean;
}

export interface IQuoteSingle {
  CustomerEmail: string;
  Priority: string;
  RequestedDate: string;
  ReqDate?: string;
  ShippingAddressId: string;
  Reference: string;
  ItemId: string;
  ItemRequestedQuantity: string;
  ListCode: string;
}

export interface IQuoteEntryRequest extends QuoteEntryRequest {
  IsInactive?: boolean;
}

export interface IQuoteList extends QuoteList {
  ListCode?: string;
}

export interface ISearchResultObject {
  ProductId: string;
  IsMarketplace: boolean;
}
export interface ProductData {
  hazmatStandardCode: string;
  hazmatStandardType: string;
  airbusCrossPlantMaterialStatus: AirbusCrossPlantMaterialStatus;
}
export interface ProductRule {
  priceVisible: string;
  quotable: string;
  sellable: string;
  stockVisible: string;
}

export interface ApprovedSupplier {
  cageCode: string;
  name: string;
}

export interface BasePrice {
  currencyIso: string;
  formattedValue: string;
  formattedVolumeDiscountPercentage: string;
  maxQuantity: number;
  minQuantity: number;
  priceType: string;
  value: number;
  volumeDiscountPercentage: number;
}

export type DateString = string;

export interface LocationInfo {
  availableStock: number;
  sortIndex: number;
  warehouseLocationDescription: string;
  warehouseLocationInitials: string;
}
export interface PriceUnit {
  code: string;
  name: string;
  sapCode: string;
}
export interface PriceBreakInfo {
  basePrice: BasePrice;
  formattedVolumeDiscountPercentage: string;
  maxQuantity: number;
  minQuantity: number;
  priceUnit: PriceUnit;
  priceUnitFactor: number;
  quantity: number;
  volumeDiscountPercentage: number;
}

export interface UserPartInfo {
  operatingCycleCount: number;
  operatingTime: number;
  totalCycleReference: string;
}

export interface DocumentInfos {
  documentID: string;
  documentName: string;
}

export interface LeaseDetails {
  chargesPerDay: string;
  numberOfDays: string;
  percentagePerDay: string;
  subtotal: string;
}

export interface LeaseInfo {
  additionalLease: LeaseDetails;
  currency: string;
  handlingFee: string;
  loanUnitText: string;
  minimumLease: LeaseDetails;
}

export interface Procurement {
  availableStock: number;
  availabilityDate: string;
  basePrice: BasePrice;
  beforeDiscountPrice: BasePrice;
  batchNumber: string;
  certificateDate: DateString;
  certificateSource: string;
  certificateTrackingNumber: string;
  certificateType: string;
  discountEndDate: DateString;
  discountPercentage: string;
  documentInfos: DocumentInfos[];
  equipmentNumber: string;
  eroPart: boolean;
  leadTimeInDays: number;
  leaseInfo: LeaseInfo;
  locationInfos: LocationInfo[];
  manufactureDate: DateString;
  partExpirationDate: DateString;
  partStatusCode: string;
  priceExpirationDate?: string;
  priceBreakInfos: PriceBreakInfo[];
  priceUnit: PriceUnit;
  priceUnitFactor: number;
  procurementType: string;
  productRule: ProductRule;
  salesUnit: PriceUnit;
  serialNumber: string;
  shelfLifeExpirationDate: DateString;
  sortIndex: number;
  sourceName: string;
  supplier: string;
  totalPrice: BasePrice;
  usedPartInfo: UserPartInfo;
  unknownDeliveryDate: boolean;
  warehouseLocationDescription: string;
  warehouseLocationInitials: string;
}
export interface AirbusPart {
  productId: string;
  masterShopId: string;
  supplier: string;
  requestedQuantity: number;
  productData: ProductData;
  productRule: ProductRule;
  approvedSuppliers: ApprovedSupplier[];
  procurements: Procurement[];
}
export interface AirbusPartResponse {
  partResponseEntries: AirbusPart[];
}
export interface IPageDetails {
  prevPageName: string | null | undefined;
  pageName: string;
  pageType: string;
  platform?: string;
}
export interface AirbusCrossPlantMaterialStatus {
  code: string;
  name: string;
}

export interface AttachmentLink {
  name: string;
  link: string;
}

export enum CompanyDetailsTabs {
  SATAIR = 'companyDetailsSatair',
  AIRBUS = 'companyDetailsAirbus',
  BASE = 'companydetails',
}

export enum OrdersTabs {
  SALE = 'ordersSale',
  REPAIR_EXCHANGE = 'ordersRepairExchange',
  LEASE = 'ordersLease',
  BASE = 'orders',
}

export enum DocumentsTabs {
  SATAIR = 'documentsSatair',
  AIRBUS = 'documentsAirbus',
  BASE = 'documents',
}

export enum AccountStatementTabs {
  SATAIR = 'accountStatementSatair',
  AIRBUS = 'accountStatementAirbus',
  BASE = 'account-statement',
}

export interface AirbusExtendedOrderDetails {
  aircraftRegistrationNumber: string;
  cageCode: string;
  customInvoiceAvailable: boolean;
  customerIdentificationCode: string;
  entries: Entry[];
  errors: { message: string; type: string }[];
  expectedDeliveryDate: string;
  materialNumberExternal: string;
  orderCreatedDate: string;
  orderType: OrderType;
  partNumber: string;
  priority: string;
  purchaseOrderNumber: string;
  quantity: string;
  sapOrderId: string;
  shipToCode: string;
  totalPrice: Price;
  unit: Unit;
}

export interface Entry {
  basePrice: Price;
  cageCode: string;
  deliveries: Delivery[];
  interchangeabilityAvailable: boolean;
  itemCategory: string;
  materialNumberExternal: string;
  name: string;
  orderEntryNumber: string;
  partNumber: string;
  priority: string;
  quantity: string;
  totalPrice: Price;
  unit: Unit;
  plannedShipments: PlannedShipment[];
}

export interface PlannedShipment {
  confirmedQuantity: string;
  orderEntryNumber: string;
  plannedDeliveredQuantity: string;
  plannedDeliveryDate: string;
  plannedShipmentNumber: string;
  requestedDate: string;
  salesUnit: {
    code: string;
    name: string;
    sapCode: string;
    unitType: string;
  };
}

export interface Price {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface Delivery {
  accountNumber: string;
  airWayBillType: string;
  batchNumber: string;
  boxes: Box[];
  carrierDetails: string;
  carrierName: string;
  containerCount: string;
  containerWeight: string;
  deliveredQuantity: string;
  deliveryDate: Date;
  deliveryItem: string;
  deliveryNumber: string;
  deliveryStatus: string;
  documents: Document[];
  orderEntryNumber: string;
  salesUnit: Unit;
  shipmentNumber: string;
  shippedFromLocationCode: string;
  shippedFromLocationName: string;
  trackingNumber: string;
}

export interface Box {
  boxKey: string;
  carrierDetails: string;
  deliveryItem: string;
  deliveryNumber: string;
  dimensions: Dimensions;
  externalHandlingUnitId: string;
  forwarderTrackingId: string;
  internalHandlingUnitId: string;
  isCertificateAvailable: boolean;
  orderEntryNumber: string;
  quantity: string;
  shipmentNumber: string;
  trackingNumber: string;
}

export interface Dimensions {
  dimensionUnit: Unit;
  height: string;
  length: string;
  totalWeight: string;
  weightUnit: Unit;
  width: string;
}

export interface Unit {
  code: Code;
  name: Name;
  sapCode: SapCode;
  unitType: UnitType;
}

export type Code = 'CMT' | 'KGM' | 'EA';

export type Name = 'Centimeter' | 'Kilogram' | 'Each';

export type SapCode = 'CM' | 'KG' | 'EA';

export type UnitType = 'mirakl';

export interface Document {
  awbNumber: string;
  billingDate: Date;
  deliveryItem: string;
  deliveryNumber: string;
  invoiceNumber: string;
  invoicedQuantity: string;
  orderEntryNumber: string;
  salesUnit: Unit;
  shipmentCompletionDate: Date;
  shipmentNumber: string;
  status: string;
  totalPrice: Price;
  transportationDetails: string;
}

export interface OrderType {
  code: string;
  name: string;
}

export interface SellerLink {
  title: string;
  url: string;
  newtab: boolean;
}

export interface ISources {
  url: string;
  size: number;
  focalPointTop?: number;
  focalPointLeft?: number;
  originalWidth?: number;
  originalHeight?: number;
}
export interface IModalCreateQuoteParams {
  id: string;
  single: boolean;
  quantity: number;
  listCode: string;
  isTerms: boolean;
  referenceNumber?: string;
}

export enum TooltipInteraction {
  Click,
  Hover,
}

export enum TooltipDefaultPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export type HeavyMaintenanceDropdownField = 'msnPrefixCode' | 'aircraftTypeCode' | 'checkTypeCode';

export interface HeavyMaintenanceForm {
  aircraftTail: string;
  additionalInformation: string;
  aircraftTypeCode: number | null;
  checkTypeCode: number | null;
  countryCode: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  maintenanceStation: string;
  operator: string;
  fromDate: string;
  toDate: string;
  msnPrefixCode: number | null;
  msn: string;
}

export interface ModalRepairRequestQuoteParams {
  productId: string;
  serialNumberRequired: boolean;
  procurementType: string;
}

export interface RepairRequestQuoteForm {
  serialNumber: string;
  flightHours: string;
  flightHoursUnkown: boolean;
  acReg: string;
  removalDate: string;
  removalReason: string;
  certificateRequiredAfterRepair: number | null;
  reference: string;
  message: string;
}

export interface RepairRequestQuoteFormRequest {
  serialNumber: string;
  flightHours: string;
  flightHoursUnkown: boolean;
  acReg: string;
  removalDate: string;
  removalReason: string;
  certificateRequiredAfterRepair: string;
  reference: string;
  message: string;
}

export interface RequestExchangeForm {
  serialNumber: string | null;
  flightHours?: string;
  removalDate: string;
  removalReason: string;
  acReg: string;
  priority: string;
  purchaseOrderNumber: string;
  message?: string;
  removalComponentTechnicalDocumentSheet?: {
    content: string;
    mimeType: string;
    name: string;
  };
}

export type OrderStatus =
  | 'processing'
  | 'approved'
  | 'waiting'
  | 'created'
  | 'cancelled'
  | 'cancel'
  | 'ready'
  | 'picking'
  | 'dispatched'
  | 'open'
  | 'closed';

export interface RouteConfigMeta {
  title: string;
  type: string;
}

export interface UmbracoRouteConfig {
  name: string;
  menuname: string;
  path: string;
  meta: RouteConfigMeta;
  beta: boolean;
}

export enum UiProgressStatus {
  Inactive = 0,
  Active = 1,
  Default = 2,
}

export enum UiProgressActiveColor {
  Default = 0,
  Inactive = 1,
  Alert = 2,
}

export enum UiProgressIcon {
  Checkmark = 0,
  Cross = 1,
  Alert = 2,
}

export interface IStateItem {
  id: string[];
  status: UiProgressStatus;
  label: string;
  hidden?: boolean;
  activeColor?: UiProgressActiveColor;
  icon?: UiProgressIcon;
}
