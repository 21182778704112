import router from '@/src/core/router';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { trackingIntersectionObserver } from '@/src/core/services/tracking-utils-service';
import { QueuedProducts, SearchInformation } from '@/src/core/types/adobe';
import { Facets, OwningSystem, Product } from '@/src/core/types/api';
import { ISearchList, ISearchResultObject } from '@/src/core/types/interfaces';
import { useLocalStorageRemoveItem, useLocalStorageSetItem } from '@/src/core/utils/local-storage';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { SearchApi } from '../api/searchApi';
import { cleanFacets, offerSearchURLParams } from '../services/search-url-params';
import { useProductStore } from './product';
import { useProductAdditionalInfoStore } from './product-additional-info';

const util = {
  async setUrlSearchQuery(q: string, f: string) {
    const currentUrlFacetQueries = router.currentRoute.query.f;
    const currentUrlSearchQueries = router.currentRoute.query.q;
    const searchQuery = q && q.length >= 3 ? { q } : undefined;
    const facetsQuery =
      f && q.length >= 3 ? { ...{ f: currentUrlFacetQueries }, ...{ f } } : undefined;
    const isRelevanceOnly = facetsQuery?.f === ':relevance';

    const newUrlQuery = { ...searchQuery, ...facetsQuery } as { q?: string; f?: string };
    const noQueryChange: boolean =
      currentUrlSearchQueries === newUrlQuery.q && currentUrlFacetQueries === newUrlQuery.f;
    if (!searchQuery) {
      delete newUrlQuery.q;
    }

    if (!facetsQuery || isRelevanceOnly) {
      delete newUrlQuery.f;
    }

    if (!noQueryChange) {
      await router.replace({ query: newUrlQuery });
    }
  },
};

export const useSearchStore = defineStore('search', () => {
  const query = ref<string>('');
  const facetQuery = ref<string>('');
  const page = ref<number>(0);
  const totalPages = ref<number>(0);
  const productIds = ref<ISearchResultObject[]>([]);
  const activeProductIndex = ref<number>(1);
  const count = ref<number | null>(null);
  const facets = ref<Facets[]>([] as Facets[]);
  const isBusy = ref<boolean>(false);
  const warningText = ref<string>('');
  const warningEnabled = ref<boolean>(false);
  const localStorageSearchQuery = ref<string>('search-query'); //ref<>(1)h
  const localStorageSearchQueryExpiry = ref<number>(3600);
  const productStore = useProductStore();
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const productsQueue = ref<Product[]>([]);

  const isBusyUpdated = (payload: { isBusy: boolean }) => {
    isBusy.value = payload.isBusy;
  };

  const searchListUpdated = (payload: { searchList: ISearchList; loadMore?: boolean }) => {
    if (!payload.loadMore) {
      productIds.value = [];
    }

    facets.value = [];
    facets.value.push(...payload.searchList.Facets);
    count.value = payload.searchList.Count;
    page.value = payload.searchList.Page;
    totalPages.value = payload.searchList.TotalPages;
    warningEnabled.value = payload.searchList.WarningEnabled;
    warningText.value = payload.searchList.WarningText;

    if (payload.searchList.Products) {
      payload.searchList.Products.forEach((product: Product) => {
        productStore.addProduct({ product });
        productIds.value.push({ ProductId: product.Id, IsMarketplace: product.IsMarketplace });
      });
    }
  };

  const searchListCleared = (payload?: { searchQuery: string }) => {
    productIds.value = [];
    count.value = null;
    facets.value = [];
    query.value = payload?.searchQuery ? payload.searchQuery : '';
    facetQuery.value = '';
    warningEnabled.value = false;
    warningText.value = '';
  };

  const searchQueryUpdated = (payload: { query: string }) => {
    query.value = payload.query;
  };

  const facetQueryUpdated = (payload: { facet: string }) => {
    facetQuery.value = payload.facet;
  };

  const activeProductIndexUpdated = (payload: { index: number }) => {
    activeProductIndex.value = payload.index;
  };

  const reset = () => {
    productIds.value = [];
    count.value = null;
    facets.value = [];
    query.value = '';
    facetQuery.value = '';
    warningEnabled.value = false;
    warningText.value = '';
  };

  const resetProductSkus = () => {
    productIds.value = [];
  };

  const searchProducts = async (payload: { searchQuery?: string; facetQuery?: string }) => {
    isBusyUpdated({ isBusy: true });

    const newQuery = payload.searchQuery || query.value;
    const facet = payload.facetQuery || '';

    if (router.currentRoute.name === 'search') {
      util.setUrlSearchQuery(newQuery, facet);
    }
    facetQueryUpdated({ facet });

    useLocalStorageSetItem(
      localStorageSearchQuery.value,
      payload,
      localStorageSearchQueryExpiry.value,
    );

    const urlParams = offerSearchURLParams(newQuery, facet);

    const { IsSuccess, Data } = await Req(
      {
        url: SearchApi.OfferSearchQuery,
        params: { urlParams },
      },
      new ReqQueue(ReqQueueTypes.Default, 'search'),
    );

    if (IsSuccess) {
      searchListUpdated({ searchList: Data });
      activeProductIndexUpdated({ index: -1 });
    }

    isBusyUpdated({ isBusy: false });

    return { IsSuccess, Data };
  };

  const searchProductsHandling = async (payload: { searchQuery: string; facetQuery?: string }) => {
    if (payload.searchQuery?.length >= 3) {
      searchQueryUpdated({ query: payload.searchQuery });

      if (payload.facetQuery) {
        facetQueryUpdated({ facet: payload.facetQuery });
      }

      searchProducts({
        searchQuery: payload.searchQuery,
        facetQuery: payload.facetQuery,
      }).then(({ IsSuccess, Data }) => {
        if (IsSuccess) {
          const eventName = count.value && count.value > 0 ? 'internal_search' : 'null_search';
          const searchInformation = {
            term: payload.searchQuery,
            results: count.value ? count.value : 0,
          };
          const queuedProducts = [];

          for (const product of Data.Products) {
            if (product.OwningSystem === OwningSystem.RED) {
              queuedProducts.push({
                OfferId: String(product.Id),
                Quantity: Number(product.PackageQuantity),
              });
            }
          }
          const containsRedProduct = Data.Products.some(
            (product: Product) => product.OwningSystem === OwningSystem.RED,
          );
          searchTracking(
            queuedProducts,
            eventName,
            searchInformation,
            Data.Products,
            containsRedProduct,
          );

          productsQueue.value = [];
          setupTrackingIntersection();
          setTimeout(() => {
            if (productsQueue.value.length > 0) {
              AdobeLaunchTracking.productImpressionsTrackingOnSearch(productsQueue.value);
            }
          }, 800); // set a delay to allow setupTrackingIntersection() to observe completely
        }
      });
    } else {
      clearProducts({ searchQuery: payload.searchQuery });
      useLocalStorageRemoveItem(localStorageSearchQuery.value);
    }
  };

  const loadMoreProducts = async () => {
    isBusyUpdated({ isBusy: true });

    const searchQuery: string = query.value;
    const pageNumber: number = page.value + 1;
    const newFacetQuery: string = facetQuery.value;

    if (pageNumber === totalPages.value || productIds.value.length === 0) {
      return;
    }

    const urlParams = offerSearchURLParams(searchQuery, newFacetQuery, pageNumber);

    const { IsSuccess, Data } = await Req({
      url: SearchApi.OfferSearchQuery,
      params: { urlParams },
    });

    if (IsSuccess) {
      searchListUpdated({ searchList: Data, loadMore: true });
      const searchInformation = {
        term: searchQuery,
        results: (Data.Products as Product[]).length,
      };
      const queuedProducts = [];
      for (const product of Data.Products) {
        queuedProducts.push({
          OfferId: String(product.Id),
          Quantity: Number(product.PackageQuantity),
        });
      }
      const containsRedProduct = Data.Products.some(
        (product: Product) => product.OwningSystem === OwningSystem.RED,
      );
      searchTracking(
        queuedProducts,
        'search_load_more',
        searchInformation,
        Data.Products,
        containsRedProduct,
      );
    }
    isBusyUpdated({ isBusy: false });
  };

  const clearProducts = async (payload: { searchQuery: string }) => {
    isBusyUpdated({ isBusy: true });
    await searchListCleared({ searchQuery: payload.searchQuery });
    util.setUrlSearchQuery('', '');
    isBusyUpdated({ isBusy: false });
    useLocalStorageRemoveItem(localStorageSearchQuery.value);
  };

  const setUrlSearchParams = async () => {
    const searchQuery = query.value;

    if (!searchQuery) {
      return;
    }

    return util.setUrlSearchQuery(searchQuery, facetQuery.value);
  };

  const setActiveProductIndex = async (payload: { index: number }) => {
    if (activeProductIndex.value !== payload.index) {
      const adjustedIndex =
        payload.index < 0
          ? 0
          : payload.index >= productIds.value.length - 1
          ? productIds.value.length - 1
          : payload.index;
      activeProductIndexUpdated({ index: adjustedIndex });
    }
  };

  const resetFacets = async () => {
    isBusyUpdated({ isBusy: true });
    await searchProductsHandling({ searchQuery: query.value, facetQuery: '' });
    useLocalStorageRemoveItem(localStorageSearchQuery.value);
    isBusyUpdated({ isBusy: false });
  };

  const clearSearchResult = async () => {
    isBusyUpdated({ isBusy: true });
    await searchProductsHandling({ searchQuery: '' });
    useLocalStorageRemoveItem(localStorageSearchQuery.value);
    isBusyUpdated({ isBusy: false });
  };

  const searchTracking = async (
    queuedProducts: QueuedProducts[],
    eventName: string,
    searchInformation: SearchInformation,
    products: Product[],
    hasRedProduct: boolean,
  ) => {
    let productResponse;
    if (hasRedProduct) {
      productResponse = await productAdditionalInfoStore.fetchProductBundled(queuedProducts);
    } else {
      productResponse = productStore.products;
    }

    if (productResponse) {
      AdobeLaunchTracking.trackSearch(eventName, searchInformation, products);
    }
  };

  const search = computed(() => {
    return productIds.value;
  });

  const localStorageSearchQueryKey = computed(() => {
    return localStorageSearchQuery.value;
  });

  const cleanedFacets = computed(() => {
    return cleanFacets(facets.value);
  });

  const facetAsString = computed(() => {
    const facetQuery = cleanedFacets.value
      .map((facet) => {
        const name = facet.Name;
        return facet.Values.filter((item) => item.Selected)
          .map((val) => {
            return `:${name}:${val.Code}`;
          })
          .join('');
      })
      .join('');

    return facetQuery;
  });

  const setupTrackingIntersection = async () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };

    const observer = trackingIntersectionObserver(productsQueue.value, options);
    const elements = document.querySelectorAll('.search-item');

    setTimeout(() => {
      elements.forEach((element) => observer.observe(element));
    }, 500); // set a delay to observe elements after a search has been made
  };

  return {
    query,
    page,
    totalPages,
    productIds,
    activeProductIndex,
    count,
    cleanedFacets,
    isBusy,
    warningText,
    warningEnabled,
    localStorageSearchQuery,
    localStorageSearchQueryExpiry,
    search,
    facetAsString,
    localStorageSearchQueryKey,
    searchProducts,
    searchProductsHandling,
    loadMoreProducts,
    clearProducts,
    setUrlSearchParams,
    setActiveProductIndex,
    resetFacets,
    clearSearchResult,
    resetProductSkus,
    reset,
  };
});
