<template>
  <div class="faq-search">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInTranslateUp delayed"
      leave-active-class="animated fadeOutTranslateUp"
    >
      <SearchTextField
        v-model="searchQuery"
        :context="uiContext.ContentSite"
        :placeholder="placeholder"
        @keyup="onKeyup($event)"
        id="search-faq"
        v-if="!disableSearch && hasCategories"
      >
        <template #append>
          <DotLoader :loading="false" class="dot-loader">
            <template #icon>
              <svgicon name="ui-search"></svgicon>
            </template>
          </DotLoader>
        </template>
      </SearchTextField>
    </transition>
    <FaqSearchResult ref="searchResult"></FaqSearchResult>
  </div>
</template>

<style lang="scss" src="./faq-search.scss" scoped></style>

<script lang="ts" setup>
import { computed, getCurrentInstance, ref, watch } from 'vue';

import FaqSearchResult from '@/src/content/components/faq/faq-search/faq-search-results/faq-search-result.vue';
import { useFaqStore } from '@/src/content/stores/faq';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import SearchTextField from '@/src/core/components/ui/form/search-text-field/search-text-field.vue';
import { UiContext } from '@/src/core/components/ui/ui.types';
import { IeKeys } from '@/src/core/types/keys';
import debounce from 'lodash/debounce';
import { Key } from 'ts-key-enum';

const uiContext = UiContext;
const vm = getCurrentInstance()?.proxy;
const faqStore = useFaqStore();
const disableSearch = ref(false);
const query = ref('');

const currentCategory = computed(() => {
  return faqStore.current;
});

const placeholder = computed(() => {
  let categoryLabel: string = '';

  if (currentCategory.value && currentCategory.value.label && currentCategory.value.label !== '') {
    categoryLabel = 'Start searching in ' + currentCategory.value.label;
  } else {
    categoryLabel = 'Start searching for help';
  }

  return categoryLabel;
});

const searchQuery = computed({
  get: () => {
    if (faqStore.searchQuery === query.value || faqStore.searchQuery === '') {
      return faqStore.searchQuery;
    } else {
      return query.value;
    }
  },
  set: (newQuery: string) => {
    query.value = newQuery;
    searchHandler(newQuery);
  },
});

const hoveredUrl = computed(() => {
  return faqStore.searchResult && faqStore.searchResult[faqStore.hoveredSearchResult].Document.Url;
});

const searchHandler = debounce((query: string) => {
  search(query);
}, 300);

const search = async (query: string) => {
  if (query.length >= 2) {
    faqStore.faqSearch({
      query,
    });
  } else if (query.length === 0) {
    faqStore.clearFaqSearch();
  }
};

const hasCategories = computed(() => {
  return faqStore.categories && faqStore.categories.length > 0;
});

const onKeyup = ($event: KeyboardEvent) => {
  const key: string = $event.key;

  switch (key) {
    case Key.ArrowUp:
    case IeKeys.ArrowUp:
      $event.preventDefault();
      faqStore.hoverPreviousSearchResult();
      break;
    case Key.ArrowDown:
    case IeKeys.ArrowDown:
      $event.preventDefault();
      faqStore.hoverNextSearchResult();
      break;
    case Key.Enter:
      $event.preventDefault();
      vm?.$router.push({ path: hoveredUrl.value });
      break;
    case Key.Escape:
    case IeKeys.Escape:
      $event.preventDefault();
      faqStore.clearFaqSearch();
      break;
  }
};

watch(
  // TODO: Replace with useRoute.route after the Vue 3 update
  () => vm?.$route,
  () => {
    const DISABLE_SEARCH = 'disableSearch';
    disableSearch.value = !!vm?.$route.matched[1].props[DISABLE_SEARCH];
    faqStore.clearFaqSearch();
  },
  { deep: true, immediate: true },
);
</script>
