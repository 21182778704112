import { ListApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { IProductSuggestionEntry } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useProductSuggestionsStore = defineStore('product-suggestions', () => {
  const suggestions = ref<IProductSuggestionEntry[]>([]);

  const suggestionUpdate = (payload: { suggestion: IProductSuggestionEntry }) => {
    const relationIndex = suggestions.value.findIndex(
      (x) => x.PartNumber === payload.suggestion.PartNumber,
    );

    if (relationIndex >= 0) {
      suggestions.value.splice(
        relationIndex,
        1,
        Object.assign({}, suggestions.value[relationIndex], payload.suggestion),
      );
    } else {
      suggestions.value.push(payload.suggestion);
    }
  };

  const reset = () => {
    suggestions.value = [];
  };

  const fetchSuggestions = async (payload: { partNumber: string }) => {
    const { Data, IsSuccess } = await Req({
      url: ListApi.ProductSuggestions(payload.partNumber),
    });

    if (IsSuccess && Data && (Data as IProductSuggestionEntry)) {
      suggestionUpdate({
        suggestion: Object.assign(Data, { PartNumber: payload.partNumber }),
      });
    }

    return { IsSuccess };
  };

  const suggestionByPartNumber = (partNumber: string) => {
    return suggestions.value.find((x) => x.PartNumber === partNumber);
  };

  return {
    suggestions,
    suggestionByPartNumber,
    reset,
    fetchSuggestions,
  };
});
