import { ListApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { ProductList, QuoteListItem } from '@/src/core/types/api';
import { IQuoteList } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useUserStore } from '@/src/profile/stores/user';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const initialState: IQuoteList = {
  CustomerEmail: '',
  Priority: 'RTN',
  RequestedDate: '',
  ShippingAddressId: '',
  Reference: '',
  QuoteItems: [] as QuoteListItem[],
  ListCode: '',
};

export const useQuoteListStore = defineStore('quote-list', () => {
  const listsStore = useListsStore();
  const quoteList = ref<IQuoteList>(Object.assign({}, initialState));
  const isBusy = ref<boolean>(false);
  const quoteProductList = ref<ProductList>({} as ProductList);

  const quoteProductlistUpdated = (payload: { Data: ProductList }) => {
    if (!Object.isFrozen(quoteProductList.value)) {
      quoteProductList.value = Object.assign(quoteProductList.value, payload.Data);
      Object.freeze(quoteProductList.value);
    }
  };

  const quoteListUpdated = (payload: IQuoteList) => {
    quoteList.value = Object.assign(quoteList.value, payload);
  };

  const isBusyUpdated = (payload: boolean) => {
    isBusy.value = payload;
  };

  const reset = () => {
    quoteList.value = Object.assign(
      {},
      {
        CustomerEmail: '',
        Priority: 'RTN',
        RequestedDate: '',
        ShippingAddressId: '',
        Reference: '',
        QuoteItems: [] as QuoteListItem[],
      },
    );
  };

  const updateQuoteProductList = (payload: IQuoteList) => {
    quoteListUpdated(payload);
  };

  const getQuoteProductList = async () => {
    isBusyUpdated(true);

    const { IsSuccess, Data } = await Req({
      url: ListApi.GetQuoteList,
    });

    if (IsSuccess) {
      await quoteProductlistUpdated({ Data });
    }

    isBusyUpdated(false);

    return { IsSuccess };
  };

  const submitQuoteList = async () => {
    isBusyUpdated(true);
    if (!quoteList.value.RequestedDate) {
      quoteList.value.RequestedDate = '';
    }
    const { IsSuccess } = await Req({
      url: ListApi.CreateQuoteFromList,
      data: { createQuote: quoteList.value },
      method: 'POST',
    });

    if (IsSuccess) {
      reset();
    }

    isBusyUpdated(false);

    return { IsSuccess };
  };

  const populateQuoteList = async (payload: {
    listCode: string;
    single?: boolean;
    fetchOnly?: boolean;
  }) => {
    const { listCode, single, fetchOnly } = payload;
    const productList = (await listsStore.getCurrentList({ listCode, fetchOnly }))
      .Data as ProductList;
    const userStore = useUserStore();
    const userEmail = userStore.currentUser?.Email;

    productList.Entries.forEach(({ Id, Quantity }) => {
      const RequestedQuantity = String(Quantity) || '1';
      quoteList.value.QuoteItems.push({
        Id,
        RequestedQuantity,
      });
    });
    quoteList.value.CustomerEmail = userEmail;
    if (single) {
      quoteList.value.Reference = productList.Name;
    }
  };

  const getQuoteList = async (listCode: string) => {
    await populateQuoteList({ listCode });
    return quoteList.value;
  };

  return {
    quoteList,
    quoteProductList,
    isBusy,
    reset,
    getQuoteProductList,
    updateQuoteProductList,
    submitQuoteList,
    getQuoteList,
    populateQuoteList,
  };
});
