<template>
  <VSimpleTable
    class="product-details-table"
    :headers="['Certificate information', '']"
    v-if="isAuthenticated && certificates"
  >
    <tbody>
      <VSimpleTableRow
        v-ui-test="'product-document-certification-type'"
        v-if="certificates.certificateType"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Certificate type: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ certificates.certificateType }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-document-certification-date'"
        v-if="certificates.certificateDate"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Certificate date: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ date(certificates.certificateDate) }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-document-certification-source-name'"
        v-if="certificates.sourceName"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Source name: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ certificates.sourceName }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-document-certification-source'"
        v-if="certificates.certificateSource"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Certificate source: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ certificates.certificateSource }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-document-certification-tracking-number'"
        v-if="certificates.certificateTrackingNumber"
      >
        <VSimpleTableCell :style="{ width: '75%' }">
          Certificate tracking number:
        </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ certificates.certificateTrackingNumber }}
        </VSimpleTableCell>
      </VSimpleTableRow>
    </tbody>
  </VSimpleTable>
</template>

<style scoped src="./product-details-certification.scss" lang="scss"></style>

<script lang="ts" setup>
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { DateFormat } from '@/src/core/utils/dates';
import { useRoute } from '@/src/core/utils/router';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { computed } from 'vue';

interface Props {
  id: string;
  owningSystem: OwningSystem;
  hasCertificate: boolean;
  certificateType?: string;
}

const props = defineProps<Props>();

const date = DateFormat;
const authenticationStore = useAuthenticationStore();
const airbusPartStore = useAirbusPartStore();
const route = useRoute();

const additionalData = computed(() => airbusPartStore.airbusPartByOfferId(props.id));

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const certificates = computed(() => {
  if (isAuthenticated.value) {
    if (additionalData.value && isBlueOwningSystem(props.owningSystem)) {
      const procurement = getProcurementByRouteParams(
        route.params,
        additionalData.value.procurements,
      );

      if (!procurement?.certificateType) {
        return null;
      }

      return {
        certificateType: procurement?.certificateType,
        certificateDate: procurement?.certificateDate,
        sourceName: procurement?.sourceName,
        certificateSource: procurement?.certificateSource,
        certificateTrackingNumber: procurement?.certificateTrackingNumber,
      };
    } else {
      if (props.hasCertificate) {
        return { certificateType: props.certificateType };
      }
    }
  }
  return null;
});
</script>
