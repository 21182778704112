<template>
  <div class="text-boxes-module-wrapper">
    <slot></slot>
  </div>
</template>

<style lang="scss" src="./text-boxes.scss"></style>

<script lang="ts">
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    InViewWatcher,
  },
})
export default class ContentTextBoxesModule extends Vue {}
</script>
