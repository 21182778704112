import EditUserLayout from '@/src/account/layouts/admin/edit-user/edit-user-layout.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { pinia } from '@/src/core/utils/pinia-utils';
import { Route } from 'vue-router';

const routeConfigStore = useRouteConfigStore(pinia);
const uiStore = useUIStore();
const authenticationStore = useAuthenticationStore();

const AccountLayout = () => import('@/src/account/layouts/account-layout.vue');
const AccountDetailsLayout = () =>
  import('@/src/account/layouts/account-details/account-details.vue');
const AdminLayout = () => import('@/src/account/layouts/admin/admin-layout.vue');

const accountRoutes = [
  {
    path: routeConfigStore.getMenuConfigPath('account', '/account'),
    component: AccountLayout,
    redirect: { name: 'companydetails' },
    meta: {
      loginRestricted: true,
    },
    beforeEnter(to: Route, from: Route, next: () => void) {
      uiStore.setCurrentSite({ site: Site.Account });
      next();
    },
    children: [
      {
        path: routeConfigStore.getMenuConfigPath('companydetails', 'company-details'),
        name: 'companydetails',
        meta: {
          title: 'Company details',
          type: 'Company Details page',
          loginRestricted: true,
        },
        components: {
          accountRoute: AccountDetailsLayout,
        },
        children: [
          {
            path: routeConfigStore.getMenuConfigPath('companyDetailsSatair', 'satair'),
            name: 'companyDetailsSatair',
            meta: {
              title: 'Company details Satair',
              type: 'Company Details Satair page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountDetailsLayout,
            },
          },
          {
            path: routeConfigStore.getMenuConfigPath('CompanyDetailsAirbus', 'airbus'),
            name: 'companyDetailsAirbus',
            meta: {
              title: 'Company details Airbus',
              type: 'Company Details Airbus page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountDetailsLayout,
            },
          },
        ],
      },
      {
        path: routeConfigStore.getMenuConfigPath('admin', 'admin'),
        name: 'admin',
        props: {
          parent: 'companydetails',
        },
        meta: {
          title: 'User Administration',
          type: 'User Administration Page',
          loginRestricted: true,
          accessRestricted: () => {
            return authenticationStore.isAdmin;
          },
        },
        components: {
          accountRoute: AdminLayout,
        },
      },
      {
        path: routeConfigStore.getMenuConfigPath('edituser', '/account/admin/edit/:userid'),
        name: 'edituser',
        components: {
          accountRoute: AdminLayout,
          subContentRoute: EditUserLayout,
        },
        props: {
          parent: 'admin',
        },
        meta: {
          title: 'Edit user',
          type: 'Edit user page',
          loginRestricted: true,
          accessRestricted: () => {
            return authenticationStore.isAdmin;
          },
        },
      },
    ],
  },
];

export default accountRoutes;
