<template>
  <div class="planning-solution-icon">
    <PlanningLogo ref="logo" />
  </div>
</template>

<script setup lang="ts">
import PlanningLogo from '@/src/planning/components/ui/planning-logo/planning-logo.vue';
import { Power1, gsap } from 'gsap';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

interface Props {
  isHovered: boolean;
}

const props = defineProps<Props>();
const logo = ref<InstanceType<typeof PlanningLogo> | null>(null);
const timeline = gsap.timeline({ ease: Power1.easeOut, paused: true });

const hovered = computed(() => props.isHovered);

const createTimeline = () => {
  const firstRect = logo.value?.$refs.firstRect;
  const secondRect = logo.value?.$refs.secondRect;
  const thirdRect = logo.value?.$refs.thirdRect;
  const fourthRect = logo.value?.$refs.fourthRect;

  const accentColor = '#2eeca5';
  const baseColor = '#00bab3';

  const duration = 0.3;
  const delay = 0.4;
  const breakBetween = 0.6;

  gsap.set([fourthRect], { fill: accentColor });
  gsap.set([thirdRect], { fill: 'transparent' });

  timeline
    // in
    .to([fourthRect, thirdRect], { x: -68, y: 68, duration, delay }, 'loopIn')
    .to([secondRect], { x: -68, y: 68, duration }, `+=${delay}`)
    .to([fourthRect, thirdRect], { x: -136, y: 136, duration }, '<')
    // out
    .to([fourthRect], { duration: 0, fill: 'transparent' })
    .to([thirdRect], { duration: 0, fill: accentColor })
    .to([firstRect, secondRect, thirdRect], { duration: 0, fill: accentColor })
    .to([secondRect], { x: 0, y: 0, duration }, `+=${breakBetween}`)
    .to([thirdRect], { x: -68, y: 68, duration }, '<')
    .to([firstRect], { fill: baseColor, duration }, '<')
    .to([thirdRect], { x: 0, y: 0, duration }, `+=${delay}`)
    .to(
      [secondRect],
      {
        fill: baseColor,
        duration,
        onComplete: () => {
          if (hovered.value) {
            timeline.play('loopIn');
          }
        },
      },
      '<',
    );
};

watch(
  () => hovered.value,
  (newValue) => {
    if (newValue && !timeline.isActive()) {
      timeline.play('loopIn');
    }
  },
);

onMounted(() => {
  createTimeline();
});

onBeforeUnmount(() => {
  timeline.kill();
});
</script>

<style src="./planning-solution-icon.scss" lang="scss" scoped></style>
