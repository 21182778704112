<template>
  <InViewWatcher>
    <div class="newsletter-module-wrapper">
      <div class="newsletter-module">
        <div class="newsletter-module__inner">
          <div class="newsletter-module__icon">
            <div class="newsletter-module__lily">
              <svg class="lily-base" xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 168 193">
                <path
                  fill="#2e2e2e"
                  d="M81 192.3c-1.2 0-2.4-.2-3.5-.7L2.8 159.5c-3.2-1.4-5.3-4.5-5.3-7.9V85.9c0-3 1.6-5.8 4.2-7.4 27-15.9 53.7-24 79.3-24 25.6 0 52.2 8.1 79.3 24 2.6 1.5 4.2 4.4 4.2 7.4v65.7c0 3.4-2.1 6.5-5.3 7.9l-74.7 32.1c-1.1.5-2.3.7-3.5.7zm0-132.8c-24.7 0-50.5 7.8-76.7 23.3-1.1.7-1.8 1.8-1.8 3.1v65.7c0 1.4.9 2.7 2.2 3.3L79.4 187c1 .4 2.1.4 3.1 0l74.7-32.1c1.4-.6 2.2-1.9 2.2-3.3V85.9c0-1.3-.7-2.4-1.8-3.1-26.1-15.5-51.9-23.3-76.6-23.3z"
                ></path>
                <path
                  d="M121.5 38.1c0 4.9 4 8.9 8.9 8.9s8.9-4 8.9-8.9-4-8.9-8.9-8.9-8.9 4-8.9 8.9"
                  class="st1"
                ></path>
                <path
                  d="M56.5 5.7c0 3.1 2.5 5.7 5.6 5.7 3.1 0 5.7-2.5 5.7-5.7S65.3 0 62.2 0c-3.2 0-5.7 2.5-5.7 5.7"
                  class="st1"
                ></path>
              </svg>
              <transition name="fade" mode="out-in">
                <div class="lily-letter" v-if="!isComplete || previoussignedup" :key="0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 52">
                    <path
                      fill="#2e2e2e"
                      d="M80.2 50c0-.1.1-.1.1-.2s.1-.2.1-.2v-.2-.3V3c0-1.4-1.1-2.5-2.5-2.5H3C1.6.5.5 1.6.5 3v46.3c0 .1 0 .2.1.2 0 .1 0 .2.1.2 0 .1.1.1.1.2s.1.1.1.2.1.1.1.2.1.1.2.2l.2.2.1.1s.1 0 .1.1 0 .1.1.1.2.1.2.1c.1 0 .1 0 .2.1.1 0 .2.1.3.1H78.6c.1 0 .2-.1.3-.1.1 0 .1 0 .2-.1.1 0 .2-.1.2-.1.1 0 .1-.1.2-.1 0 0 .1 0 .1-.1l.1-.1.2-.2c.1-.1.1-.1.1-.2.1-.1.1-.1.1-.2.1 0 .1 0 .1-.1zM5.5 9.3l15.4 12.4L5.5 41.6V9.3zm35 21.8L8.7 5.5h63.6L40.5 31.1zm-15.7-6.2l14.1 11.4c.5.4 1 .6 1.6.6s1.1-.2 1.6-.6l13.8-11.1 16.9 21.2H8.1l16.7-21.5zm35-2.9L75.5 9.3v32.4L59.8 22z"
                    ></path>
                  </svg>
                </div>
                <div class="lily-letter" v-else :key="1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 48">
                    <polygon
                      fill="#2e2e2e"
                      points="63.3,0.2 21.9,40.5 3.8,22.7 0.2,26.3 21.8,47.5 66.7,3.8 "
                    />
                  </svg>
                </div>
              </transition>
            </div>
          </div>
          <div class="newsletter-module__signup typeset">
            <transition name="fade" mode="out-in">
              <div v-if="!isComplete && !previoussignedup" :key="0">
                <slot name="intro"></slot>
                <form @submit.prevent="submitSignup">
                  <div class="signup-input__full">
                    <VTextField
                      :type="uiVariant.Secondary"
                      input-type="email"
                      required
                      name="Email"
                      :label="textK('UI_COMMON_EMAIL')"
                      v-model="signup.email"
                      v-ui-test="'newsletter-signup-email'"
                    >
                    </VTextField>
                  </div>

                  <div class="signup-input__half">
                    <VTextField
                      :type="uiVariant.Secondary"
                      required
                      name="FirstName"
                      :label="textK('UI_COMMON_FIRSTNAME')"
                      v-model="signup.firstname"
                      v-ui-test="'newsletter-signup-firstname'"
                    >
                    </VTextField>
                  </div>
                  <div class="signup-input__half">
                    <VTextField
                      :type="uiVariant.Secondary"
                      required
                      name="LastName"
                      :label="textK('UI_COMMON_LASTNAME')"
                      v-model="signup.lastname"
                      v-ui-test="'newsletter-signup-lastname'"
                    >
                    </VTextField>
                  </div>
                  <VButton
                    :disabled="isBusy"
                    input-type="button"
                    :type="uiVariant.Default"
                    @click="submitSignup"
                    v-ui-test="'newsletter-signup-submit'"
                  >
                    {{ textK('UI_COMMON_SUBSCRIBE') }}
                  </VButton>
                  <small class="newsletter-module__policy"
                    >{{ textK('UI_COMMON_NEWSLETTER_POLICY') }}
                    <a
                      @click.prevent="
                        () => {
                          openModal({
                            modalComponent: 'ModalLoadContent',
                            params: {
                              key: 'PrivacyPolicy_ModalUrl',
                              title: 'PrivacyPolicy_Name',
                            },
                          });
                        }
                      "
                      >{{ textK('UI_COMMON_NEWSLETTER_POLICY_LINK') }}</a
                    ></small
                  >
                </form>
              </div>
              <div v-else :key="1">
                <slot name="feedback"></slot>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./newsletter-module.scss"></style>

<script lang="ts">
import { UmbracoApi } from '@/src/core/api/umbracoApi';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InViewWatcher,
    VTextField,
    ArrowIcon,
    VButton,
  },
})
export default class ContentNewsletterModule extends Vue {
  @Prop({ default: false }) public previoussignedup: boolean;
  public uiVariant = UiVariant;
  public isBusy: boolean = false;
  public isComplete: boolean = false;
  private isDirty: boolean = false;

  public textK = useText();
  public openModal = useModal();

  public signup: object = {
    email: '',
    firstname: '',
    lastname: '',
  };

  public async submitSignup() {
    this.isBusy = true;

    const { IsSuccess } = await Req({
      method: 'POST',
      url: UmbracoApi.newsletterSignUp,
      data: this.signup,
    });

    if (IsSuccess) {
      this.isComplete = true;
    }

    this.isBusy = false;
  }

  @Watch('signup', { deep: true }) private onDataChanged(newData: object, oldData: object) {
    if (!this.isDirty && Object.keys(oldData).length > 0 && Object.keys(newData).length > 0) {
      this.isDirty = true;
    }
  }
}
</script>
