<template>
  <div class="content-block">
    <template v-if="animation">
      <TransitionExpand mode="out-in">
        <DotLoader class="loader" v-if="isLoading || isLinkStoreBusy" :loading="true"></DotLoader>
        <div v-else class="content-block typeset">
          <Component :is="component"></Component>
        </div>
      </TransitionExpand>
    </template>
    <template v-else>
      <div class="content-block typeset">
        <Component :is="component"></Component>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped src="./content-block.scss"></style>

<script lang="ts" setup>
import ContentAccordionModule from '@/src/content/components/content-modules/accordion-module/accordion-module.vue';
import ContentArticleModule from '@/src/content/components/content-modules/article-module/article-module.vue';
import ContentBoxCtaModule from '@/src/content/components/content-modules/box-cta-module/box-cta-module.vue';
import ContentBoxListModule from '@/src/content/components/content-modules/box-list-module/box-list-module.vue';
import ContentBoxSpotModule from '@/src/content/components/content-modules/box-spot-module/box-spot-module.vue';
import ContentComparisonBoxesModule from '@/src/content/components/content-modules/comparison-boxes-module/comparison-boxes.vue';
import ConvertCalculatorModule from '@/src/content/components/content-modules/convert-calculator-module/convert-calculator-module.vue';
import ContentCtaBoxModule from '@/src/content/components/content-modules/cta-box-module/cta-box-module.vue';
import ContentFullWidthMediaModule from '@/src/content/components/content-modules/full-width-element-module/full-width-element.vue';
import ContentHeroModule from '@/src/content/components/content-modules/hero-module/hero-module.vue';
import ContentLinkBoxesModule from '@/src/content/components/content-modules/link-boxes-module/link-boxes.vue';
import LoginModule from '@/src/content/components/content-modules/login-module/login-module.vue';
import ContentNewsletterModule from '@/src/content/components/content-modules/newsletter-module/newsletter-module.vue';
import NotFoundModule from '@/src/content/components/content-modules/not-found-module/not-found-module.vue';
import ContentQuoteModule from '@/src/content/components/content-modules/quote-module/quote-module.vue';
import ContentTableModule from '@/src/content/components/content-modules/table-module/table-module.vue';
import ContentTextBoxesModule from '@/src/content/components/content-modules/textboxes-module/text-boxes.vue';
import ContentTypeformModule from '@/src/content/components/content-modules/typeform-module/typeform-module.vue';
import ContentModuleSection from '@/src/content/layouts/module-section/content-module-section.vue';
import Login from '@/src/core/components/login/login.vue';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import AccordionItem from '@/src/core/components/ui/accordion/accordion-item/accordion-item.vue';
import Accordion from '@/src/core/components/ui/accordion/accordion.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import BlogInformation from '@/src/core/components/ui/blog-information/blog-information.vue';
import BlogTag from '@/src/core/components/ui/blog-information/blog-tag/blog-tag.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ModalLink from '@/src/core/components/util/modal-link/modal-link.vue';
import { useContentBlocksStore } from '@/src/core/stores/content-blocks';
import { useLinksStore } from '@/src/core/stores/links';
import GenerateComponentName from '@/src/core/utils/generate-component-name';
import Vue, { computed, getCurrentInstance, ref, watch } from 'vue';

interface Props {
  linkKey?: string;
  url?: string;
  modal?: boolean;
  force?: boolean;
  animation?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  linkKey: '',
  url: '',
  modal: false,
  force: false,
  animation: true,
});

const contentBlocksStore = useContentBlocksStore();
const linksStore = useLinksStore();
const instance = getCurrentInstance();
const component = ref<string | null>(null);

const getUrl = computed(() => {
  let newUrl: string | undefined;

  if (props.url.length > 0) {
    newUrl = props.url;
  } else {
    newUrl = linksStore.getKey(props.linkKey) || undefined;
  }
  return newUrl;
});

const content = computed(() => {
  if (props.modal) {
    return contentBlocksStore.contentByUrl(getUrl.value).replace(/router-link/gi, 'modal-link');
  } else {
    return contentBlocksStore.contentByUrl(getUrl.value);
  }
});

const isLoading = computed(() => {
  return contentBlocksStore.isBlockBusy(getUrl.value);
});

const isLinkStoreBusy = computed(() => {
  return linksStore.isBusy;
});

const fetchContent = (force?: boolean) => {
  if (getUrl.value) {
    contentBlocksStore.fetchUmbracoContentBlock({ url: getUrl.value, force });
  }
};

const createComponent = () => {
  const name: string = GenerateComponentName();

  Vue.component(name, {
    template: `<div class="content-block-loader">${content.value}</div>`,
    components: {
      Accordion,
      AccordionItem,
      ArrowIcon,
      BlogInformation,
      BlogTag,
      ContentComparisonBoxesModule,
      ContentBoxSpotModule,
      ContentLinkBoxesModule,
      ContentArticleModule,
      ContentHeroModule,
      ContentCtaBoxModule,
      ContentBoxCtaModule,
      ContentTextBoxesModule,
      ContentFullWidthMediaModule,
      ContentTableModule,
      ContentAccordionModule,
      ContentBoxListModule,
      ContentQuoteModule,
      ContentModuleSection,
      ContentNewsletterModule,
      LoginModule,
      MediaHandler,
      ModalLink,
      NotFoundModule,
      VButton,
      Login,
      ContentTypeformModule,
      ConvertCalculatorModule,
    },
  });

  component.value = name;
  instance?.proxy?.$forceUpdate();
};

watch(
  () => content.value,
  () => {
    if (!content.value) {
      return;
    }
    createComponent();
  },
  { immediate: true },
);

watch(
  () => getUrl.value,
  () => {
    fetchContent(props.force);
  },
  { immediate: true },
);
</script>
