import { AirbusProductInterchangeableItem } from '@/src/core/types/api';
import { IDahSuggestionItem } from '@/src/core/types/interfaces';

export const isSatairInterchangeable = (
  data?: IDahSuggestionItem | AirbusProductInterchangeableItem | null,
): data is IDahSuggestionItem => {
  return (data as IDahSuggestionItem)?.Sku !== undefined;
};

export const isAirbusInterchangeable = (
  data?: IDahSuggestionItem | AirbusProductInterchangeableItem | null,
): data is AirbusProductInterchangeableItem => {
  return (data as AirbusProductInterchangeableItem)?.id !== undefined;
};
