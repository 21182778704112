import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class FinanceApi {
  public static GetDocuments = `${baseUrl}/api/finance/documents`;

  // DownloadDocuments (BULK) Example data (METHOD: POST)
  // documentsRequest[{DocCat: string, DocNum: string}]
  public static DownloadDocuments = `${baseUrl}/api/finance/documents/download`;

  // ShowAccountStatement Example data (METHOD: POST)
  // unitId = "SkyUnlimited_1100_10_10`;
  // fromDate = "2020-01-01`;
  // toDate = "2020-09-20`;
  public static ShowAccountStatement = `${baseUrl}/api/finance/account-statement`;
  public static DownloadAccountStatement = `${baseUrl}/api/finance/account-statement/download`;
}
