<template>
  <div ref="modal" class="modal">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="modal__overlay" v-if="visible"></div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInScale"
      leave-active-class="animated fadeOutScale"
    >
      <div class="modal__content" v-if="visible" @mousedown.self="closeModal" role="dialog">
        <div class="modal__inner-content">
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInTranslateDown"
            leave-active-class="animated fadeOutTranslateUp"
          >
            <component :is="component"></component>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" src="./modal.scss" scoped></style>

<script lang="ts" setup>
import { useModalStore } from '@/src/core/stores/modal';
import { useScrollLockStore } from '@/src/core/stores/scroll-lock';
import { useUIStore } from '@/src/core/stores/ui';
import { IeKeys } from '@/src/core/types/keys';
import { useRoute } from '@/src/core/utils/router';
import { Key } from 'ts-key-enum';
import Vue, { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import modals from '../modals';

const scrollLockStore = useScrollLockStore();
const uiStore = useUIStore();
const modalStore = useModalStore();
const route = useRoute();

const modal = ref<HTMLElement | null>(null);
const component = ref<string | null>(null);

const modalComponent = computed(() => modalStore.component);
const visible = computed(() => modalStore.isVisible);
const modalIsLocked = computed(() => modalStore.isLocked);

const closeModal = () => {
  if (!modalIsLocked.value) {
    modalStore.closeModal();
  }
};

const handleEscButton = ($event: KeyboardEvent): void => {
  if (uiStore.dropdownIsOpen) return;

  const key = $event.key;
  if ([Key.Escape, IeKeys.Escape].includes(key)) {
    $event.preventDefault();
    closeModal();
  }
};

watch(
  () => visible.value,
  (value) => {
    if (value) {
      scrollLockStore.scrollLock({ id: 'modal' });
    } else {
      scrollLockStore.reset();
      uiStore.setDropdownOpen({ id: '' });
    }
  },
);

watch(
  () => modalComponent.value,
  (newComponent) => {
    if (!newComponent) return;
    Vue.component(newComponent, modals[newComponent]);
    component.value = newComponent;
  },
);

watch(
  () => route,
  (newRoute, oldRoute) => {
    if (component.value && oldRoute.name && oldRoute.name !== newRoute.name) {
      closeModal();
    }
  },
);

onMounted(() => {
  document.addEventListener('keyup', handleEscButton);
});

onBeforeUnmount(() => {
  document.removeEventListener('keyup', handleEscButton);
});
</script>
