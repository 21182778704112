import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { InvoiceItem } from '@/src/core/types/api';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { FinanceApi } from '../api/financeApi';

export interface IAccountStatementQuery {
  fromDate?: string;
  toDate?: string;
  openItems?: boolean;
  overdueItems?: boolean;
}

const initialFilter: IAccountStatementQuery = {
  fromDate: dayjs().startOf('month').format(DisplayDateFormat),
  toDate: dayjs().format(DisplayDateFormat),
};

export const useAccountStatementStore = defineStore('account-statement', () => {
  const userStore = useUserStore();
  const statements = ref<InvoiceItem[]>([]);
  const isBusy = ref<boolean>(true);
  const filters = ref<IAccountStatementQuery>(Object.assign({}, initialFilter));

  const statementsUpdated = (payload: { statements: InvoiceItem[] }) => {
    statements.value = [];
    statements.value.push(...payload.statements);
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const reset = () => {
    filters.value = Object.assign({}, initialFilter);
    statements.value = [];
    isBusy.value = false;
  };

  const setFilters = (payload: IAccountStatementQuery) => {
    filters.value = Object.assign({}, filters.value, payload);
  };

  const resetFilters = () => {
    CancelReqCategory('accountStatement');
    filters.value = Object.assign({}, initialFilter);
  };

  const fetchStatements = async () => {
    const unitId = userStore.currentUser?.DefaultUnitUid;

    if (!unitId) {
      return;
    }
    const postData = Object.assign({}, { unitId });

    if (filters.value?.openItems) {
      Object.assign(postData, { openItems: true });
    } else if (filters.value?.overdueItems) {
      Object.assign(postData, { overdueItems: true });
    } else {
      Object.assign(postData, { fromDate: filters.value?.fromDate, toDate: filters.value?.toDate });
    }

    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: FinanceApi.ShowAccountStatement,
        data: postData,
        method: 'POST',
      },
      new ReqQueue(ReqQueueTypes.Default, 'accountStatement'),
    );

    if (IsSuccess && Data) {
      statementsUpdated({ statements: Data.InvoiceItems });
    }

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  return {
    statements,
    isBusy,
    filters,
    statementsUpdated,
    isBusyUpdated,
    reset,
    setFilters,
    resetFilters,
    fetchStatements,
  };
});
