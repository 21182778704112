<template>
  <InViewWatcher>
    <div
      class="box-cta-module-wrapper"
      :class="{ hovered: hasLink && hover, 'has-link': hasLink }"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
    >
      <slot :hovered="hover"></slot>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./box-cta-module.scss"></style>

<script lang="ts">
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    InViewWatcher,
  },
})
export default class ContentBoxCtaModule extends Vue {
  @Prop({ default: false }) public hasLink: boolean;
  public hover: boolean = false;

  public mouseOver() {
    this.hover = true;
    this.$emit('hovered', true);
  }
  public mouseOut() {
    this.hover = false;
    this.$emit('hovered', false);
  }
}
</script>
