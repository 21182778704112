import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { showErrorNotification } from '@/src/core/utils/notifications';
import { SSO_COOKIE_KEY_STATE, SSO_COOKIE_KEY_VERIFIER, hydrateState } from '@/src/core/utils/sso';
import { getAuthTokens, redirectToSSO } from '@/src/oidc/api/oidcApi';
import Cookies from 'js-cookie';
import { RouteConfig } from 'vue-router';

const oidcRoutes: RouteConfig[] = [
  {
    path: '/oidc/authorizationcode',
    name: 'oidc',
    async beforeEnter(to, from, next) {
      try {
        const url = new URL(location.href);
        const returnedDehydratedState = url.searchParams.get('state');
        const code = url.searchParams.get('code');
        const state = Cookies.get(SSO_COOKIE_KEY_STATE);
        const verifier = Cookies.get(SSO_COOKIE_KEY_VERIFIER);

        if (!state || !verifier) {
          throw new Error('failed to login, missing auth state');
        }

        if (!returnedDehydratedState || !code) {
          throw new Error('failed to login, invalid request');
        }

        if (state !== returnedDehydratedState) {
          throw new Error('failed to login, incorrect state');
        }

        const hydratedState = hydrateState(state);
        const path = hydratedState.url.substr(location.origin.length);

        const resp = await getAuthTokens(code, verifier, state);

        const authenticationStore = useAuthenticationStore();

        await authenticationStore.doLogin({
          mssoToken: resp.data.access_token,
          mssoRefreshToken: resp.data.refresh_token,
        });

        next({ path });
      } catch (e) {
        next({
          name: 'search',
        });
        const textK = useText();

        // setTimeout gives time for microcopies to load, so we can use textK
        setTimeout(() => {
          if (e instanceof Error) {
            // if error is known also log it for debugging purposes.
            console.warn(textK('UI_LOGIN_SSO_FAILED'), e);
          }
          showErrorNotification(textK('UI_LOGIN_SSO_FAILED'));
        }, 3000);
      }
    },
  },
  {
    path: '/oidc/autologin',
    name: 'oidc-autologin',
    async beforeEnter(to, from, next) {
      try {
        await redirectToSSO(location.host);
      } catch (e) {
        next({
          name: 'search',
        });
        const textK = useText();
        // setTimeout gives time for microcopies to load, so we can use textK
        setTimeout(() => {
          showErrorNotification(textK('UI_LOGIN_SSO_FAILED'));
        }, 3000);
      }
    },
  },
];

export default oidcRoutes;
