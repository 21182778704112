import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { OwningSystem } from '@/src/core/types/api';
import { isBlueCustomer, isRedCustomer } from '@/src/market/services/user-service';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { useAirbusPartStore } from '../stores/airbus-part';
import { useProductAdditionalInfoStore } from '../stores/product-additional-info';

export async function getProductSkuAsync(sku: string) {
  const { IsSuccess, Data } = await Req({
    url: ProductApi.ProductSku(),
    params: {
      sku,
    },
    headers: { BypassNotifications: true },
  });

  if (IsSuccess) {
    return Data;
  } else {
    return undefined;
  }
}

export async function getProductOfferAsync(id: string) {
  const { IsSuccess, Data } = await Req({
    url: ProductApi.ProductOffer(id),
    headers: { BypassNotifications: true },
  });

  if (IsSuccess) {
    return Data;
  } else {
    return undefined;
  }
}

export async function getProductInfoAsync(id: string) {
  const { IsSuccess, Data } = await Req({
    url: ProductApi.ProductInfo(id),
  });

  if (IsSuccess) {
    return Data;
  }
}

export const isAirbusProduct = (owningSystem?: string): boolean => {
  return owningSystem?.toLowerCase() === 'blue';
};

export const fetchProductAdditionalDataByUserOwningSystem = (
  id: string,
  productOwningSystem: string,
  quantity?: number,
) => {
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const productPriceStore = useProductPriceInfoStore();
  const airbusPartStore = useAirbusPartStore();

  if (id) {
    if (isBlueCustomer() && productOwningSystem === OwningSystem.BLUE) {
      airbusPartStore.queueAirbusParts({
        OfferId: id,
        Quantity: 1,
      });
    }

    if (isRedCustomer() && productOwningSystem !== OwningSystem.BLUE) {
      productAdditionalInfoStore.queueAdditionalProductData({
        OfferId: id,
        Quantity: quantity || 1,
      });
      productPriceStore.queueAdditionalProductData({
        OfferId: id,
        Quantity: quantity || 1,
      });
    }
  }
};
