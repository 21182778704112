<template>
  <div class="blog-post-module">
    {{ blogPost }}
    <slot></slot>
  </div>
</template>

<style lang="scss" src="./blog-post-module.scss"></style>

<script lang="ts">
import { IUmbracoContent } from '@/src/core/types/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
})
export default class BlogPostModule extends Vue {
  private blogPostContent: IUmbracoContent;

  public get blogPost() {
    return this.blogPostContent;
  }
}
</script>
