import { TextKey } from '@/src/core/services/text-key';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import { Dictionary } from 'vue-router/types/router';

export const filterUnusedProcurements = (procurementType: string) => {
  const unusedTypes = [
    ProcurementType.Inspection,
    ProcurementType.Scrapping,
    ProcurementType.Inspection,
  ] as string[];

  return !unusedTypes.includes(procurementType);
};

export const findProcurementTypes = (procurements?: Procurement[]) => {
  if (!procurements) {
    return [];
  }

  const procurementTypes = Object.values(ProcurementType).filter(
    filterUnusedProcurements,
  ) as string[];

  return procurements
    ?.map(({ procurementType }) => procurementType)
    .filter((procurement) => procurementTypes.includes(procurement));
};

export const getProcurementByRouteParams = (
  params: Dictionary<string>,
  procurements: Procurement[],
) => {
  if (!procurements) {
    return undefined;
  }

  if (params.serialNr) {
    return procurements.find(
      (procurement) => procurement.serialNumber?.toUpperCase() === params.serialNr.toUpperCase(),
    );
  }

  const procurementType = params.procurementType
    ? params.procurementType.toUpperCase()
    : ProcurementType.New;

  return procurements
    .filter(({ serialNumber }) => !serialNumber)
    .find((procurement) => procurement.procurementType === procurementType);
};

export const formatProcurementType = (procurementType?: string) => {
  switch (procurementType) {
    case ProcurementType.New:
      return TextKey('UI_PRODUCT_NEW');

    case ProcurementType.Used:
      return TextKey('UI_PRODUCT_USED');

    case ProcurementType.Repair:
      return TextKey('UI_PRODUCT_REPAIR');

    case ProcurementType.Lease:
      return TextKey('UI_PRODUCT_LEASE');

    case ProcurementType.ExchangeNew:
    case ProcurementType.ExchangeUsed:
      return TextKey('UI_PRODUCT_EXCHANGE');

    default:
      return TextKey('UI_PRODUCT_NEW');
  }
};

/**
 * Fetches the first procurement with type 'New' from an array of procurements.
 *
 * @module getNewProcurementType
 * @param {Procurement[]} procurements - Array of procurements to be searched.
 * @returns {Procurement | undefined} - The first procurement with type 'New' or undefined if no procurement with such type or if the input array is undefined or empty.
 * @throws {TypeError} - If 'procurements' is not an array or if one or more of its elements are not of type 'Procurement'.
 *
 */
export const getNewProcurementType = (procurements: Procurement[]) => {
  if (!procurements || !procurements.length) {
    return;
  }
  const newProcurement = procurements.find(
    (procurement) => procurement.procurementType === ProcurementType.New,
  );

  return newProcurement;
};

export const isExchangeType = (procurementType: string) => {
  const exhangeTypes = [
    ProcurementType.Exchange,
    ProcurementType.ExchangeUsed,
    ProcurementType.ExchangeNew,
  ] as string[];

  return exhangeTypes.includes(procurementType);
};

export const isLeaseTool = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Lease && product?.IsTool;

export const isLeasePart = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Lease && product?.AirbusProprietary;

export const isUsedTool = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Used && product?.IsTool;

export const isUsedPart = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Used && product?.AirbusProprietary;

export const getProcurementCondition = (procurement: Procurement, procurementType: string) => {
  if (procurement?.procurementType === ProcurementType.Used && procurement?.partStatusCode) {
    return procurement.partStatusCode;
  }

  return procurementType;
};
