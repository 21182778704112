<template>
  <section class="product-details-section">
    <div class="product-details-row">
      <div class="interchangeable-list" v-ui-test="'interchangeable-list'">
        <template v-if="hasInterchangeables && products.length === 0">
          <div
            class="interchangeable-list-header"
            :class="{ 'interchangeable-list-header--no-entries': !hasRelatedProducts }"
          >
            <h3 v-if="!hasRelatedProducts">
              {{ textK('UI_INTERCHANGEABLES_HEADLINE') }}
            </h3>
            <p>{{ textK('UI_INTERCHANGEABLES_NOT_AVAILABLE') }}</p>
          </div>
        </template>
        <template v-else>
          <div class="interchangeable-list-header typeset">
            <h3
              class="interchangeable-list-header__title"
              v-if="!hasRelatedProducts || !enableRecommendations"
            >
              {{ textK('UI_INTERCHANGEABLES_HEADLINE') }}
            </h3>
            <p class="interchangeable-list-header__description">
              {{ textK('UI_INTERCHANGEABLES_DISCLAIMER') }}
            </p>
          </div>

          <div class="interchangeable-list-items">
            <ProductDetailsRelationsLoader
              v-for="item in products.slice(0, results)"
              :is-airbus="isAirbus"
              :item="item"
              :sku="sku"
              :key="item.Sku"
              :type="uiVariant.Secondary"
              :is-interchangeable-item="true"
              :is-leader-part="isAirbus && item.leaderPart"
              :is-two-way="isAirbus ? item.interchangeabilityCode === 2 : item.TwoWay"
              :parent-owning-system="owningSystem"
            >
            </ProductDetailsRelationsLoader>
          </div>
          <button v-if="products.length > startResults" class="load-more" @click="loadMore">
            <span v-if="results === startResults">Show all</span>
            <span v-else>Show less</span>
          </button>
        </template>
      </div>
    </div>
  </section>
</template>
<style scoped src="./product-details-interchangeable-list.scss" lang="scss"></style>

<script lang="ts" setup>
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import ProductDetailsRelationsLoader from '@/src/market/components/product/product-details/product-details-relations-loader/product-details-relations-loader.vue';
import {
  isAirbus as isAirbusService,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import { computed, ref } from 'vue';

interface Props {
  sku: string;
  id: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const productRelationsStore = useProductRelationsStore();

const uiVariant = UiVariant;
const startResults = ref(3);
const results = ref(3);

const isAirbus = computed(() => isAirbusService(productAdditionalData.value));

const products = computed(() => {
  if (isAirbus.value) {
    return productAdditionalData.value?.interchangeables || [];
  }
  return productRelationsStore.interchangeablesBySku(props.sku);
});

const productAdditionalData = computed(() =>
  productAdditionalDataService(props.id, props.owningSystem),
);

const hasRelatedProducts = computed(() => products.value.length > 0);
const enableRecommendations = computed(() => hasFeature(FEATURES.RECOMMENDATIONS));

const hasInterchangeables = computed(() => {
  if (isAirbus.value) {
    return productAdditionalData.value?.interchangeables?.length > 0;
  }
  return productRelationsStore.hasInterchangeables(props.sku);
});

const loadMore = () => {
  if (products.value.length === 0) return;
  results.value =
    results.value === products.value.length ? startResults.value : products.value.length;
};
</script>
