import { UmbracoApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import Vue from 'vue';

const RouteAuthenticationGate = async () => {
  const authenticationStore = useAuthenticationStore();
  const modalStore = useModalStore();
  const { isAuthenticated, isFirstTime, hasAcceptedTou, hasAcceptedReleaseNotes } =
    authenticationStore;

  if (
    isAuthenticated &&
    (isFirstTime || hasAcceptedTou === false || hasAcceptedReleaseNotes === false)
  ) {
    Vue.nextTick(async () => {
      if (isFirstTime) {
        modalStore.showModal({
          modalComponent: 'ModalPermission',
          first: true,
          locked: true,
        });

        modalStore.showModal({
          modalComponent: 'ModalFirstTimeUser',
          first: true,
          locked: true,
        });
      }

      if (hasAcceptedTou === false) {
        modalStore.showModal({
          modalComponent: 'ModalAcceptTermsOfUse',
          params: { isChange: !isFirstTime },
          first: true,
          locked: true,
        });
      }

      if (hasAcceptedReleaseNotes === false) {
        const { IsSuccess, Data } = await Req({
          url: UmbracoApi.getReleaseNotes,
        });

        if (!IsSuccess || !Data) {
          return;
        }

        modalStore.showModal({
          modalComponent: 'ModalReleaseNotes',
          params: { isChange: !isFirstTime },
          first: true,
          locked: true,
        });
      }
    });
  }
};

export default RouteAuthenticationGate;
