const FinanceModals = {
  ModalAccountStatementFilter: () =>
    import(
      '@/src/finance/components/modals/modal-account-statement-filter/modal-account-statement-filter.vue'
    ),
  ModalAccountStatementDownload: () =>
    import(
      '@/src/finance/components/modals/modal-account-statement-download/modal-account-statement-download.vue'
    ),
  ModalDocumentsFilter: () =>
    import('@/src/finance/components/modals/modal-documents-filter/modal-documents-filter.vue'),
  ModalMultilineQuery: () =>
    import('@/src/finance/components/modals/modal-multiline-query/modal-multiline-query.vue'),
};

export default FinanceModals;
