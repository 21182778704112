import { AdminApi, UserApi } from '@/src/core/api';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { sanitizeUser } from '@/src/core/services/user';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Pagination, User, UserSearchPerson } from '@/src/core/types/api';
import { IAdmin, IUser, TempUser } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAdminStore = defineStore('admin', () => {
  const authenticationStore = useAuthenticationStore();

  const users = ref<UserSearchPerson[]>([]);
  const tempUsers = ref<TempUser[]>([]);
  const currentUser = ref<User>({});
  const pagination = ref<Pagination>({});
  const isBusy = ref(false);
  const userSearchQuery = ref('');
  const page = ref(0);
  const totalPages = ref(0);
  const count = ref(0);

  const usersUpdated = (payload: { data: IAdmin; loadMore?: boolean }) => {
    if (!payload.loadMore) {
      users.value = [];
    }

    const { Count, Page, Users, TotalPages } = payload.data;

    count.value = Count;
    if (count.value > 0) {
      users.value.push(...Users);
    }

    page.value = Page;
    totalPages.value = TotalPages;
  };

  const deleteTempUser = (payload: { email: string }) => {
    const userIndex = tempUsers.value?.findIndex((user) => user.Email === payload.email);

    if (userIndex > -1) {
      tempUsers.value.splice(userIndex, 1);
    }

    if (!tempUsers.value) {
      tempUsers.value = [];
    }
  };

  const resetCurrentUserDetails = () => {
    currentUser.value = {} as User;
  };

  const reset = () => {
    users.value = [];
    tempUsers.value = [];
    currentUser.value = {} as User;
    pagination.value = {} as Pagination;
    isBusy.value = false;
    userSearchQuery.value = '';
    page.value = 0;
    totalPages.value = 0;
    count.value = 0;
  };

  const fetchUsers = async () => {
    const enableFinanceSection = hasFeature(FEATURES.FINANCE_SECTION);
    isBusy.value = true;

    const { IsSuccess, Data } = await Req(
      {
        url: AdminApi.GetUsers,
        params: {
          q: userSearchQuery.value.length > 0 ? userSearchQuery.value : '',
          enableFinanceSection,
        },
      },
      new ReqQueue(ReqQueueTypes.Default, 'adminFetchUsers'),
    );

    if (IsSuccess) {
      usersUpdated({ data: Data });
    }

    isBusy.value = false;
  };

  const loadMoreUsers = async () => {
    const enableFinanceSection = hasFeature(FEATURES.FINANCE_SECTION);
    const orderQuery = userSearchQuery.value;
    const pageNumber = page.value + 1;

    if (pageNumber === totalPages.value) {
      return;
    }
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      url: AdminApi.GetUsers,
      params: { q: orderQuery, p: pageNumber, enableFinanceSection },
    });

    if (IsSuccess) {
      usersUpdated({ data: Data, loadMore: true });
    }
    isBusy.value = false;
  };

  const updateSearchQuery = (payload: { searchQuery: string }) => {
    userSearchQuery.value = payload.searchQuery;
  };

  const clearFilters = () => {
    userSearchQuery.value = '';
  };

  const fetchCurrentUserDetails = async (payload: { userId: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      url: UserApi.GetUserDetails(payload.userId),
    });

    if (IsSuccess) {
      currentUser.value = Object.assign({}, sanitizeUser(Data));
    }
    isBusy.value = false;
  };

  const addUser = async (payload: { user: TempUser }) => {
    isBusy.value = true;
    const { IsSuccess, Data } = await Req({
      url: AdminApi.AddUser,
      params: payload.user,
    });

    if (IsSuccess) {
      await fetchUsers();
    }

    isBusy.value = false;
    return { IsSuccess, Data };
  };

  const addUsers = async (payload: { users: TempUser[] }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      method: 'POST',
      url: AdminApi.AddUsers,
      data: payload.users,
    });

    if (IsSuccess) {
      const users = Data as TempUser[];
      tempUsers.value = users;
    }

    isBusy.value = false;
    return { IsSuccess, Data };
  };

  const addTempUser = async (payload: { user: TempUser }): Promise<boolean> => {
    return await new Promise((resolve) => {
      tempUsers.value.push(payload.user);
      resolve(true);
    });
  };

  const resetTemptUsers = () => {
    tempUsers.value = [];
  };

  const updateUser = async (payload: { user: IUser }) => {
    if (payload.user.GlobalId) {
      isBusy.value = true;

      const { IsSuccess } = await Req({
        method: 'POST',
        url: UserApi.UpdateUserDetails(payload.user.GlobalId, true),
        data: payload.user,
      });

      if (IsSuccess) {
        await fetchUsers();
      }
      isBusy.value = false;
    }
  };

  const deleteUser = async (payload: { userId: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      url: UserApi.DeleteUser(payload.userId),
    });

    if (IsSuccess) {
      await fetchUsers();
    }
    isBusy.value = false;
    return { IsSuccess, Data };
  };

  const deleteCurrentUser = async (payload: { userId: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req({
      url: UserApi.DeleteUser(payload.userId),
    });

    if (IsSuccess) {
      authenticationStore.doLogout();
    }

    isBusy.value = false;

    return { IsSuccess, Data };
  };

  return {
    users,
    tempUsers,
    currentUser,
    pagination,
    isBusy,
    userSearchQuery,
    page,
    totalPages,
    count,
    deleteTempUser,
    resetCurrentUserDetails,
    reset,
    fetchUsers,
    loadMoreUsers,
    updateSearchQuery,
    clearFilters,
    fetchCurrentUserDetails,
    addUser,
    addUsers,
    addTempUser,
    resetTemptUsers,
    updateUser,
    deleteUser,
    deleteCurrentUser,
  };
});
