// tslint:disable
import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class OrderApi {
  public static AllOrders = `${baseUrl}/api/order/show`;
  public static RecentOrders = `${baseUrl}/api/order/recent`;
  public static OrderInfo = (orderCode: string) => `${baseUrl}/api/order/${orderCode}`;
  public static PlaceOrder = `${baseUrl}/api/cart/order`;
  public static AddOrderToCart = (orderCode: string, cartCode: string) =>
    `${baseUrl}/api/order/addtocart/${orderCode}/${cartCode}`;
  public static DownloadAllDocuments = (orderCode: string) =>
    `${baseUrl}/api/order/${orderCode}/downloadall`;
  public static GetOrderLog = (orderCode: string) => `${baseUrl}/api/order/${orderCode}/log`;
  public static DownloadEUSDocument = (orderCode: string) =>
    `${baseUrl}/api/order/${orderCode}/eusdownload`;
  public static DownloadAirbusOrderInvoice = `${baseUrl}/api/order/invoices/airbusinvoicedocuments`;
  public static DownloadAirbusOrderCertificate = (boxNumber: string) =>
    `${baseUrl}/api/order/certificate/${boxNumber}/download`;
  public static DownloadCustomsInvoice = (orderCode: string) =>
    `${baseUrl}/api/customsinvoices/airbuscustomsinvoicedocument/order/${orderCode}`;
  public static RepairExchangeOrders = `${baseUrl}/api/airbus/service-orders/repair-exchange`;
  public static LeaseOrders = `${baseUrl}/api/airbus/service-orders/lease`;
  public static RepairExchangeOrderDetails = (purchaseOrderNumber: string) =>
    `${baseUrl}/api/airbus/service-orders/repair-exchange/${purchaseOrderNumber}`;
  public static LeaseOrderDetails = (purchaseOrderNumber: string) =>
    `${baseUrl}/api/airbus/service-orders/lease/${purchaseOrderNumber}`;
  public static ExportLeaseOrders = `${baseUrl}/api/airbus/service-orders/lease/export`;
  public static ExportRepairExchangeOrders = `${baseUrl}/api/airbus/service-orders/repair-exchange/export`;
}
