import { getCurrentInstance } from 'vue';

export function useRoute() {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error('useRoute() must be called within a setup function');
  }

  return vm.$route;
}

export function useRouter() {
  const vm = getCurrentInstance()?.proxy;

  if (!vm) {
    throw new Error('useRouter() must be called within a setup function');
  }

  return vm.$router;
}
