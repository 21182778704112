<template>
  <div class="planning-splash">
    <PlanningLogo ref="logo" class="planning-splash__logo" />
    <h4 class="planning-splash__text" ref="text">Welcome to Planning</h4>
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from '@/src/core/stores/ui';
import PlanningLogo from '@/src/planning/components/ui/planning-logo/planning-logo.vue';
import { Power1, gsap } from 'gsap';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const uiStore = useUIStore();
const emit = defineEmits(['isFinished']);
const timeline = gsap.timeline({ ease: Power1.easeOut, repeat: -1 });
const logo = ref<InstanceType<typeof PlanningLogo> | null>(null);
const text = ref<HTMLElement | null>(null);

const createTimeline = () => {
  const firstRect = logo.value?.$refs.firstRect;
  const secondRect = logo.value?.$refs.secondRect;
  const thirdRect = logo.value?.$refs.thirdRect;
  const fourthRect = logo.value?.$refs.fourthRect;

  const accentColor = '#2eeca5';
  const baseColor = '#00bab3';
  const duration = 0.3;
  const delay = 0.3;

  gsap.set([text.value], { opacity: 0, y: 15 });
  gsap.set([secondRect], { x: -68, y: 68 });
  gsap.set([thirdRect], { x: -136, y: 136 });
  gsap.set([firstRect, secondRect, thirdRect], { fill: accentColor });
  gsap.set([fourthRect], { fill: 'transparent' });

  timeline
    // animate up
    .to([secondRect], { x: 0, y: 0, duration }, `+=${delay}`)
    .to([thirdRect], { x: -68, y: 68, duration }, '<')
    .to([firstRect], { fill: baseColor, duration }, '<')
    .to([text.value], { y: 0, opacity: 1, duration: 0.6 }, '<')
    .to([thirdRect, fourthRect], { x: 0, y: 0, duration }, `+=${delay}`)
    .to(
      [secondRect],
      {
        fill: baseColor,
        duration,
        onComplete: () => {
          if (!show.value) {
            timeline.pause();
            setTimeout(() => {
              emit('isFinished', true);
            }, 1000);
          }
        },
      },
      '<',
    )
    // animate down
    .to([fourthRect], { duration: 0, fill: accentColor })
    .to([thirdRect], { duration: 0, fill: 'transparent' })
    .to([fourthRect, thirdRect], { x: -68, y: 68, duration, delay: 0.4 }, 'loopIn')
    .to([secondRect], { x: -68, y: 68, duration }, `+=${delay}`)
    .to([fourthRect, thirdRect], { x: -136, y: 136, duration }, '<');
};

const show = computed(() => {
  return uiStore.showSplashScreen;
});

watch(
  () => show.value,
  () => {
    emit('isFinished', false);
  },
  { immediate: true },
);

onMounted(() => {
  createTimeline();
});

onBeforeUnmount(() => {
  timeline.kill();
});
</script>

<style src="./planning-splash.scss" lang="scss" scoped />
