<template>
  <ContentFullWidthMediaModule :class="{ 'youtube-module-wide': displayAsBig === '1' }">
    <div
      class="full-width-element-module full-width-element-module--video content-theme--dark-grey"
    >
      <div class="full-width-element-module__inner">
        <div class="content-media content-media-video" v-if="embedCode" ref="videoRef">
          <div v-html="embedCode"></div>
        </div>
        <div v-else-if="fallbackImage">
          <MediaHandler
            class="box-cta-module__media__item"
            :placeholder="[{ url: fallbackImage, size: 800 }]"
          >
            <div class="no-js-background"></div>
          </MediaHandler>
        </div>
      </div>
    </div>
  </ContentFullWidthMediaModule>
</template>

<script setup lang="ts">
import ContentFullWidthMediaModule from '@/src/content/components/content-modules/full-width-element-module/full-width-element.vue';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import GlueFrame from 'glueframe';
import { computed, onMounted, ref } from 'vue';

interface Props {
  embedCode: string;
  displayAsBig: string;
  fallbackImage: string;
}
const props = defineProps<Props>();
const embedCode = computed(() => props.embedCode);
const displayAsBig = computed(() => props.displayAsBig);
const fallbackImage = computed(() => props.fallbackImage);
const videoName = ref('');
const videoDuration = ref<number>();
const videoCurrentTime = ref<number>();
const videoRef = ref<HTMLDivElement | null>(null);
const video = ref<any>(null);
const videoStarted = ref(false);

const trackVideoStarted = () => {
  if (!videoStarted.value) {
    videoStarted.value = true;

    video.value?.get('video_title', function (value: string) {
      videoName.value = value;

      AdobeLaunchTracking.trackVideo('videoStarted', {
        videoName: videoName.value,
        videoPercentage: '0%',
      });
    });
  }
};

const trackVideoPaused = () => {
  video.value?.get('currentTime', function (value: number) {
    videoCurrentTime.value = value;

    video.value?.get('video_duration', function (value: number) {
      videoDuration.value = value;

      if (videoCurrentTime.value) {
        const percentage = (videoCurrentTime.value * 100) / videoDuration.value;
        if (percentage > 99) {
          return;
        }

        AdobeLaunchTracking.trackVideo('videoPaused', {
          videoName: videoName.value,
          videoPercentage: `${percentage.toFixed(2)}%`,
        });
      }
    });
  });
};

const trackVideoFinished = () => {
  AdobeLaunchTracking.trackVideo('videoFinished', {
    videoName: videoName.value,
    videoPercentage: '100%',
  });
};

onMounted(() => {
  video.value = new GlueFrame(videoRef.value?.querySelector('iframe'), 'Player');

  if (video.value) {
    video.value?.bind('player:video:play', trackVideoStarted);
    video.value?.bind('player:video:pause', trackVideoPaused);
    video.value?.bind('player:video:ended', trackVideoFinished);
  }
});
</script>

<style src="./twenty-three-video.scss" lang="scss"></style>
