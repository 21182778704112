/**
 * Object Values
 * The Object.values provides the correct type, so we just have this function for consistency for the `keys` and `entries` functions.
 * @see keys
 * @see entries
 * @typed
 */
export const oValues = Object.values as <T extends object>(o: T) => Array<T[keyof T]>;

/**
 * Object Keys
 * The Object.keys method doesn't provide the correct type for the key, hence the creation of this function.
 * @typed
 */
export const oKeys = Object.keys as <T extends object>(o: T) => Array<keyof T>;

/**
 * Object Entries
 * The Object.entries method doesn't provide the correct type for the key, hence the creation of this function.
 * @typed
 */
export const oEntries = Object.entries as <T extends object>(o: T) => Array<[keyof T, T[keyof T]]>;
