<template>
  <InViewWatcher class="pull-up-wide">
    <div class="full-width-element-module-wrapper">
      <slot></slot>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./full-width-element.scss"></style>

<script lang="ts">
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    InViewWatcher,
  },
})
export default class ContentFullWidthMediaModule extends Vue {}
</script>
