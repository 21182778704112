import { Req } from '@/src/core/services/requester';
import { AxiosRequestConfig } from 'axios';

// axiosRequestConfig.params is used to pass through DocType for tracking
// this way we handle tracking for all documents from one place and can pass the category from the component we call download
const DownloadFile = async (axiosRequestConfig: AxiosRequestConfig) => {
  const { IsSuccess, Data } = await Req(axiosRequestConfig);
  if (IsSuccess) {
    const byteArray = new Uint8Array(Data.FileContents);
    const blob = new Blob([byteArray], { type: Data.ContentType });
    const fileName = Data.FileDownloadName || Data.FilenameFileDownloadName || 'file';
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  return { IsSuccess, Data };
};

export default DownloadFile;
