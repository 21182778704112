<template>
  <div
    class="image-box-module-wrapper"
    :class="{ hovered: hasLink && hover, 'has-link': hasLink }"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
  >
    <slot :hovered="hover" />
  </div>
</template>

<style lang="scss" src="./image-box-module.scss"></style>

<script lang="ts">
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ArrowIcon,
  },
})
export default class ContentImageBoxModule extends Vue {
  @Prop({ default: false }) public hasLink: boolean;
  public hover: boolean = false;

  public mouseOver() {
    this.hover = true;
    this.$emit('hovered', true);
  }
  public mouseOut() {
    this.hover = false;
    this.$emit('hovered', false);
  }
}
</script>
