import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { pinia } from '@/src/core/utils/pinia-utils';
import { Route } from 'vue-router';

const InitialProvisioningLayout = () =>
  import('@/src/planning/layouts/initial-provisioning/initial-provisioning-layout.vue');

const routeConfigStore = useRouteConfigStore();

const PlanningLayout = () => import('@/src/planning/layouts/planning-layout.vue');
const uiStore = useUIStore(pinia);

const planningRoutes = [
  {
    path: routeConfigStore.getMenuConfigPath('planning', '/planning'),
    name: 'planning',
    redirect: { name: 'initial-provisioning' },
    component: PlanningLayout,
    meta: {
      loginRestricted: true,
    },
    beforeEnter: (to: Route, from: Route, next: () => void) => {
      uiStore.setSplashScreen({ splashScreen: true });
      uiStore.setCurrentSite({ site: Site.Planning });
      next();
    },
    children: [
      {
        path: routeConfigStore.getMenuConfigPath(
          'intial-provisioning',
          '/planning/initial-provisioning',
        ),
        name: 'initial-provisioning',
        components: {
          planningRoute: InitialProvisioningLayout,
        },
        meta: {
          title: 'Initial provisioning',
          type: 'Initial provisioning page',
          loginRestricted: true,
        },
      },
    ],
  },
];

export default planningRoutes;
