<template>
  <ProductWrapper
    v-if="product"
    :id="product.Id"
    :key="product.Id"
    :fetch-additional-data="shouldFetchAdditionalData"
  >
    <template #default="{ product, inView }">
      <SearchItem
        :product="product"
        :id="product?.Id"
        :type="uiVariant.Secondary"
        :is-interchangeable-item="isInterchangeableItem"
        :is-leader-part="isAirbus && item.leaderPart"
        :is-two-way="isTwoWay"
        :show-part-number-interchangeable="false"
        :is-in-view="inView"
        :is-from-p-d-p="true"
      />
    </template>
  </ProductWrapper>
</template>

<script lang="ts" setup>
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { AirbusProductInterchangeableItem, Product } from '@/src/core/types/api';
import { IDahSuggestionItem } from '@/src/core/types/interfaces';
import ProductWrapper from '@/src/market/components/product/wrappers/product-wrapper/product-wrapper.vue';
import SearchItem from '@/src/market/components/search/search-item/search-item.vue';
import {
  isAirbusInterchangeable,
  isSatairInterchangeable,
} from '@/src/market/services/interchangeables';
import { useProductStore } from '@/src/market/stores/product';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import { computed, onMounted } from 'vue';

interface Props {
  sku: string;
  item: IDahSuggestionItem | AirbusProductInterchangeableItem;
  isAirbus?: boolean;
  isInterchangeableItem: boolean;
  isLeaderPart?: boolean;
  isTwoWay?: boolean;
  parentOwningSystem?: string;
}

const props = defineProps<Props>();

const productStore = useProductStore();
const productRelationsStore = useProductRelationsStore();
const uiVariant = UiVariant;

const product = computed(() => {
  if (isAirbusInterchangeable(props.item)) {
    return productStore.productById(props.item.id);
  }
  if (isSatairInterchangeable(props.item)) {
    return productStore.productBySku(props.item.Sku, props.parentOwningSystem) as Product;
  }
  return undefined;
});

const shouldFetchAdditionalData = computed(() => !props.isAirbus);

onMounted(() => {
  if (props.isInterchangeableItem) {
    productRelationsStore.fetchInterchangeables({
      sku: props.sku,
      ...(isSatairInterchangeable(props.item) && { relationSku: props.item.Sku }),
      ...(isAirbusInterchangeable(props.item) && { relationId: props.item.id }),
    });
  } else {
    productRelationsStore.fetchRecommendations({
      sku: props.sku,
      relationSku: (props.item as IDahSuggestionItem).Sku && (props.item as IDahSuggestionItem).Sku,
    });
  }
});
</script>
