<template>
  <div ref="module">
    <TransitionExpand>
      <InViewWatcher v-if="!isAuthenticated">
        <slot />
      </InViewWatcher>
    </TransitionExpand>
  </div>
</template>

<style lang="scss" src="./login-module.scss"></style>

<script lang="ts">
import Login from '@/src/core/components/login/login.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    InViewWatcher,
    Login,
    TransitionExpand,
  },
})
export default class LoginModule extends Vue {
  public authenticationStore = useAuthenticationStore();
  public $refs: {
    module: HTMLElement;
  };

  public get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }
}
</script>
