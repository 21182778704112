import { useModalStore } from '@/src/core/stores/modal';
import { IAdobeTargetEvent } from '@/src/core/types/adobe';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AdobeTargetEventHandler extends Vue {
  public modalStore = useModalStore();

  public handleTargetAction($event: CustomEvent) {
    const targetEvent: IAdobeTargetEvent = $event.detail;

    if (targetEvent.targetActionType === 'modal') {
      this.modalStore.showModal({
        modalComponent: 'ModalLoadContent',
        params: {
          url: targetEvent.umbracoUrl,
          label: targetEvent.label,
          targetOptions: targetEvent.options,
          track: true,
        },
      });
    }
  }

  private get modalIsOpen() {
    return this.modalStore.isVisible;
  }

  public mounted() {
    window.addEventListener('TargetAction', this.handleTargetAction);
  }

  public destroyed() {
    window.removeEventListener('TargetAction', this.handleTargetAction);
  }
}
