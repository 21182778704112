<template>
  <div class="product-details" v-if="product && offer">
    <product-details-header-section
      :product="product"
      :offer="offer"
    ></product-details-header-section>

    <product-details-information-section
      :product="product"
      :offer="offer"
      :seller="seller"
    ></product-details-information-section>

    <product-details-offer-sold-by
      :product="product"
      :offer="offer"
      :seller-info="seller"
    ></product-details-offer-sold-by>

    <product-details-relations-tab-group
      v-if="isAuthenticated"
      :id="product.Id"
      :owning-system="product.OwningSystem"
      :sku="product.Sku"
      :is-marketplace="product.IsMarketplace"
    ></product-details-relations-tab-group>

    <product-details-body-section :product="product" :offer="offer"></product-details-body-section>

    <product-details-service-references />

    <product-details-footer-section
      :product="product"
      :seller="seller"
    ></product-details-footer-section>
  </div>
</template>
<script setup lang="ts">
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useRoute } from '@/src/core/utils/router';
import { ScrollEvent, ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import ProductDetailsOfferSoldBy from '@/src/market/components/product/product-details/product-details-offer-sold-by/product-details-offer-sold-by.vue';
import ProductDetailsRelationsTabGroup from '@/src/market/components/product/product-details/product-details-relations-tab-group/product-details-relations-tab-group.vue';
import {
  ProductDetailsBodySection,
  ProductDetailsFooterSection,
  ProductDetailsHeaderSection,
  ProductDetailsInformationSection,
} from '@/src/market/components/product/product-details/product-details-sections';
import ProductDetailsServiceReferences from '@/src/market/components/product/product-details/product-details-service-references/product-details-service-references.vue';
import { useMasterShopStore } from '@/src/market/stores/mastershops';
import { useProductStore } from '@/src/market/stores/product';
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const masterShopStore = useMasterShopStore();
const productStore = useProductStore();
const authenticationStore = useAuthenticationStore();
const route = useRoute();
let scrollCounter = 0;

const offer = computed(() => {
  return productStore.productOfferById(props.id);
});

const product = computed(() => {
  return productStore.productById(props.id);
});

const masterShopId = computed(() => {
  return offer.value?.ShopId;
});

const seller = computed(() => {
  if (!masterShopId.value) {
    return null;
  }
  return masterShopStore.masterShopById(masterShopId.value);
});

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const fetchMarketPlaceSeller = async () => {
  const id = masterShopId.value;
  const isMarketPlace = product.value?.IsMarketplace;

  if (isMarketPlace && id) {
    await masterShopStore.fetchMasterShopById(id);
  }
};

const scrollHandler = (event: ScrollEvent) => {
  const { scrollTop, offsetHeight, scrollHeight } = event.originalEvent.target as HTMLElement;
  if (scrollCounter < 1 && scrollTop + offsetHeight >= scrollHeight) {
    AdobeLaunchTracking.scrollTracking(route);
    scrollCounter += 1;
  }
};

watch(
  () => masterShopId.value,
  () => {
    fetchMarketPlaceSeller();
  },
  { immediate: true },
);

onMounted(() => {
  ScrollEventBus.$on('scroll', scrollHandler);
});

onBeforeUnmount(() => {
  ScrollEventBus.$off('scroll', scrollHandler);
});
</script>
