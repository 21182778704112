import { isDevelopment } from '@/src/core/services/environment';
import Vue from 'vue';

const assistantTools: object = {
  UploadHandler: () =>
    import('@/src/core/components/assistant/tools/upload/upload-handler/upload-handler.vue'),
  UploadOptions: () =>
    import('@/src/core/components/assistant/tools/upload/upload-options/upload-options.vue'),
};

if (isDevelopment()) {
  /**
   * ! This should only be executed in development mode
   * It is fixing a bug where dynamically loaded modals
   * Are sometimes not loaded.
   */

  Object.entries(assistantTools).forEach(([name, comp]) => {
    Vue.component(name, comp);
  });
}

export default assistantTools;
