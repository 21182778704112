<template>
  <InViewWatcher>
    <div class="box-spot-module-wrapper">
      <slot />
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./box-spot-module.scss"></style>

<script lang="ts">
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    InViewWatcher,
  },
})
export default class ContentBoxSpotModule extends Vue {}
</script>
