<template>
  <InViewWatcher>
    <slot> </slot>
  </InViewWatcher>
</template>

<style lang="scss" src="./contact-form-module.scss"></style>

<script lang="ts">
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    VTextField,
    InViewWatcher,
    VSelect,
  },
})
export default class ContactFormModule extends Vue {}
</script>
