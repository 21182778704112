import { TextKey } from '@/src/core/services/text-key';
import { AirbusPart } from '@/src/core/types/interfaces';
import { MaterialStatusBlocked } from '@/src/core/types/material-status';

export const hasNonSellableMaterialStatus = (productPart: AirbusPart) => {
  const statusCode = productPart.productData?.airbusCrossPlantMaterialStatus?.code;
  const statusCodes = Object.values(MaterialStatusBlocked);

  return statusCodes.some((code) => code === statusCode);
};

export const materialStatusText = (status?: string) => {
  const statusCode = status ? status.toUpperCase() : '';

  return TextKey(`UI_PRODUCT_${statusCode}_LONG_DESCRIPTION`) || 'No description available';
};

export const hasNoPriceAvailable = (productPart: AirbusPart) => {
  const statusCode = productPart.productData?.airbusCrossPlantMaterialStatus
    ?.code as MaterialStatusBlocked;

  const statuses = [
    MaterialStatusBlocked.STATUS_S2,
    MaterialStatusBlocked.STATUS_S4,
    MaterialStatusBlocked.STATUS_Z2,
  ];

  return statuses.includes(statusCode);
};

export const isMaterialStatusQuotable = (productPart: AirbusPart) => {
  const statusCode = productPart.productData?.airbusCrossPlantMaterialStatus
    ?.code as MaterialStatusBlocked;

  const statuses = [MaterialStatusBlocked.STATUS_2, MaterialStatusBlocked.STATUS_Z7];

  return statuses.includes(statusCode);
};
