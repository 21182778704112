import { FinanceApi } from '@/src/core/api';
import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { BillingDocuments } from '@/src/core/types/api';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface IDocumentQuery {
  unitId?: string;
  fromDate?: string;
  toDate?: string;
  documentNumbers?: string[] | string;
  emailAddresses?: string[] | string;
  orderNumbers?: string[] | string;
  materialNumberExternals?: string[];
  poNumbers?: string[] | string;
  excludeInvoices?: boolean | string;
  excludeCredits?: boolean | string;
}

export const initialFilter: IDocumentQuery = {
  fromDate: dayjs().startOf('month').format(DisplayDateFormat),
  toDate: dayjs().format(DisplayDateFormat),
};

export const useDocumentsStore = defineStore('documents', () => {
  const userStore = useUserStore();
  const documents = ref<BillingDocuments[]>([]);
  const isBusy = ref<boolean>(true);
  const selected = ref<boolean[]>([]);
  const filters = ref<IDocumentQuery>(Object.assign({}, initialFilter));

  const documentsUpdated = (payload: { documents: BillingDocuments[] }) => {
    documents.value = [];
    documents.value.push(...payload.documents);
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const reset = () => {
    filters.value = Object.assign({}, initialFilter);
    documents.value = [];
    isBusy.value = false;
  };

  const setFilters = (payload: IDocumentQuery) => {
    filters.value = Object.assign({}, filters.value, payload);
  };

  const resetFilters = () => {
    CancelReqCategory('documents');
    filters.value = Object.assign(
      {},
      { fromDate: filters.value.fromDate, toDate: filters.value.toDate },
    );
  };

  const fetchDocuments = async () => {
    const unitId = userStore.currentUser?.DefaultUnitUid;
    if (!unitId) {
      return;
    }
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: FinanceApi.GetDocuments,
        data: Object.assign({}, filters.value, { unitId }),
        method: 'POST',
      },
      new ReqQueue(ReqQueueTypes.Default, 'documents'),
    );

    if (IsSuccess) {
      documentsUpdated({ documents: Data.BillingDocumentInfos });
    }
    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  return {
    documents,
    isBusy,
    selected,
    filters,
    documentsUpdated,
    isBusyUpdated,
    reset,
    setFilters,
    resetFilters,
    fetchDocuments,
  };
});
