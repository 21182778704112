import { CartApi, OrderApi, ProductApi, QuoteApi, UserApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import {
  Carrier,
  CartEntry,
  CustomForwarderInformation,
  OrderReceipt,
  PriorityInfo,
  QuoteToOrder,
} from '@/src/core/types/api';
import {
  ICheckoutValidation,
  ISellerGroup,
  ISellerGroupPO,
  OrderType,
} from '@/src/core/types/interfaces';
import { DateFormat, DisplayDateFormat } from '@/src/core/utils/dates';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useCarriersStore } from '../stores/carriers';
import { useCartStore } from '../stores/cart';
import { useDeliveryAddressStore } from './delivery-addresses';
import { useOrdersStore } from './orders';
import { useQuotesStore } from './quotes';

export const useCheckoutStore = defineStore('checkout', () => {
  const carriersStore = useCarriersStore();
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const deliveryAddressesStore = useDeliveryAddressStore();
  const ordersStore = useOrdersStore();
  const quotesStore = useQuotesStore();
  const quoteCode = ref<string>('');
  const isBusy = ref<boolean>(false);
  const sellerGroup = ref<ISellerGroup[]>([] as ISellerGroup[]);
  const customerCodes = ref<string[]>([]);
  const orderTypes = ref<OrderType[]>([]);

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const quoteCodeUpdated = (payload: { quoteCode: string }) => {
    quoteCode.value = payload.quoteCode;
  };

  const priorityUpdate = (payload: { sellerId: string; priority: string }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);
    sellerGroup.value[sellerIndex].PriorityInfo = Object.assign(
      {},
      sellerGroup.value[sellerIndex].PriorityInfo,
      { Priority: { Code: payload.priority } },
    );
  };

  const remarkUpdate = (payload: { sellerId: string; entryId: string; remark: string }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);
    const entry = sellerGroup.value[sellerIndex].Entries.find(
      (entry: CartEntry) => entry.Id === payload.entryId,
    );
    if (entry) {
      entry.AirbusRemarks = payload.remark;
    }

    sellerGroup.value = [...sellerGroup.value];
  };

  const deliveryDateUpdate = (payload: { sellerId: string; date: string }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);
    sellerGroup.value[sellerIndex].NamedDeliveryDate = payload.date;
  };

  const acRegUpdated = (payload: { sellerId: string; acReg: string }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);
    sellerGroup.value[sellerIndex].PriorityInfo = Object.assign(
      {},
      sellerGroup.value[sellerIndex].PriorityInfo,
      { AcReg: payload.acReg },
    );
  };

  const selectedForwarderUpdated = (payload: {
    sellerId: string;
    CarrierCode: string;
    AccountNo?: string;
    CarrierName?: string;
    additionalInstructions?: string;
    serviceLevelAgreements?: string;
    serviceLevelRequired?: boolean;
  }) => {
    const selectedCarrier: Carrier = carriersStore.carrier(payload.CarrierCode);
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);

    Object.assign(selectedCarrier, {
      Code: payload.CarrierCode,
      Name: payload.CarrierName,
      ServiceLevelRequired: payload.serviceLevelRequired,
    });
    sellerGroup.value[sellerIndex].Carrier = Object.assign({}, selectedCarrier);
    sellerGroup.value[sellerIndex].CarrierInfo = payload.AccountNo || '';
    sellerGroup.value[sellerIndex].CarrierInstructions = payload.additionalInstructions || '';
    sellerGroup.value[sellerIndex].ServiceLevelAgreements = payload.serviceLevelAgreements || '';
  };

  const setSelectedDeliveryAddress = (payload: { sellerId: string; addressId: string }) => {
    const newAddress = deliveryAddressesStore.getDeliveryAddressById(payload.addressId);
    if (!newAddress) {
      return;
    }

    sellerGroup.value = sellerGroup.value.map((seller) => {
      if (seller.Key !== payload.sellerId) {
        return seller;
      }

      return {
        ...seller,
        DeliveryAddress: newAddress,
      };
    });
  };

  const poNumberUpdated = (payload: { sellerId: string; poNumber: string }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);

    sellerGroup.value[sellerIndex].PONumber = payload.poNumber;
  };

  const termsUpdated = (payload: { sellerId: string; accept: boolean }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);
    sellerGroup.value[sellerIndex].TermsAccepted = payload.accept;
  };

  const sellerGroupUpdated = (payload: { seller: ISellerGroup }) => {
    if (!payload.seller.Key) {
      return;
    }
    const updatedSeller = {} as ISellerGroup;
    const reqDate =
      payload.seller?.NamedDeliveryDate &&
      !dayjs(new Date(payload.seller.NamedDeliveryDate)).isSame(dayjs(new Date()), 'd')
        ? DateFormat(payload.seller.NamedDeliveryDate)
        : '';

    updatedSeller.NamedDeliveryDate = reqDate;
    updatedSeller.Key = payload.seller.Key;
    updatedSeller.Name = payload.seller.Name;
    updatedSeller.PONumber = payload.seller.PONumber || '';
    updatedSeller.TermsAccepted = false;
    updatedSeller.Carrier = payload.seller.Carrier;
    updatedSeller.CarrierInfo = payload.seller.CarrierInfo;
    updatedSeller.ServiceLevelAgreements = payload.seller.ServiceLevelAgreements;
    updatedSeller.CarrierInstructions = payload.seller.CarrierInstructions;
    updatedSeller.DeliveryAddress = payload.seller.DeliveryAddress;
    updatedSeller.PriorityInfo =
      payload.seller.PriorityInfo || ({ Priority: { Code: 'RTN' } } as PriorityInfo);
    updatedSeller.Entries = payload.seller.Entries.filter(
      (entry) => entry.Quantity && entry.Quantity > 0,
    );
    updatedSeller.Url = payload.seller.Url || '';
    updatedSeller.Logo = payload.seller.Logo || '';
    if (payload.seller.Key === 'airbus') {
      updatedSeller.CustomerCode =
        customerCodes.value.length > 1 ? '' : payload.seller.CustomerCode;
      updatedSeller.OrderType = (payload.seller?.OrderType as OrderType)?.Code || 'ZRP';
    }
    sellerGroup.value.push(updatedSeller);
  };

  const reset = () => {
    quoteCode.value = '';
    sellerGroup.value = [] as ISellerGroup[];
    isBusy.value = false;
  };

  const resetSellers = () => {
    sellerGroup.value = [];
  };

  const addCustomerCodes = (payload: { customerCodes: string[] }) => {
    customerCodes.value = payload.customerCodes;
  };

  const addOrderTypes = (payload: { orderTypes: OrderType[] }) => {
    orderTypes.value = payload.orderTypes;
  };

  const customerCodeSelected = (payload: { sellerId: string; customerCode: string }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);

    sellerGroup.value[sellerIndex].CustomerCode = payload.customerCode;
  };

  const orderTypeSelected = (payload: { sellerId: string; orderType: OrderType }) => {
    const sellerIndex = sellerGroup.value.findIndex((seller) => seller.Key === payload.sellerId);

    sellerGroup.value[sellerIndex].OrderType = payload.orderType.Code;
  };

  const updateCarrier = (payload: {
    sellerId: string;
    cartCode?: string;
    carrierCode: string;
    carrierName: string;
    accountNo?: string;
    carrierInstructions?: string;
    serviceLevelAgreements?: string;
    serviceLevelRequired?: boolean;
  }) => {
    isBusyUpdated({ IsBusy: true });
    const setAddressToCart = !quoteCode.value;

    // TODO: Note: This is not a checkoutData Specific Req
    if (setAddressToCart) {
      cartStore.updateCarrier(payload).then(({ IsSuccess }) => {
        if (IsSuccess) {
          // tslint:disable-next-line:max-line-length
          selectedForwarderUpdated({
            sellerId: payload.sellerId,
            CarrierCode: payload.carrierCode,
            AccountNo: payload.accountNo,
            CarrierName: payload.carrierName,
            additionalInstructions: payload.carrierInstructions,
            serviceLevelAgreements: payload.serviceLevelAgreements,
            serviceLevelRequired: payload.serviceLevelRequired,
          });
        }
      });
    } else {
      // tslint:disable-next-line:max-line-length
      selectedForwarderUpdated({
        sellerId: payload.sellerId,
        CarrierCode: payload.carrierCode,
        AccountNo: payload.accountNo,
        CarrierName: payload.carrierName,
        additionalInstructions: payload.carrierInstructions,
        serviceLevelAgreements: payload.serviceLevelAgreements,
        serviceLevelRequired: payload.serviceLevelRequired,
      });
    }

    isBusyUpdated({ IsBusy: false });
  };

  const setPriority = async (payload: { sellerId: string; priority: string }) => {
    priorityUpdate({ sellerId: payload.sellerId, priority: payload.priority });
  };

  const updateRemark = async (payload: { sellerId: string; entryId: string; remark: string }) => {
    remarkUpdate({
      sellerId: payload.sellerId,
      entryId: payload.entryId,
      remark: payload.remark,
    });
  };

  const addSellerGroup = async (payload: { seller: ISellerGroup }) => {
    isBusyUpdated({ IsBusy: true });
    sellerGroupUpdated(payload);
    isBusyUpdated({ IsBusy: false });
  };

  const updateDeliveryAddress = async (payload: {
    cartCode?: string;
    sellerId: string;
    addressId: string;
  }) => {
    isBusyUpdated({ IsBusy: true });
    const setAddressToCart = !quoteCode.value;

    if (setAddressToCart) {
      await cartStore.updateDeliveryAddress(payload).then(({ IsSuccess }) => {
        if (IsSuccess) {
          setSelectedDeliveryAddress({
            sellerId: payload.sellerId,
            addressId: payload.addressId,
          });
        }
      });
    } else {
      setSelectedDeliveryAddress({ sellerId: payload.sellerId, addressId: payload.addressId });
    }

    isBusyUpdated({ IsBusy: false });
  };

  const placeOrder = async (sellerKey = 'satair') => {
    isBusyUpdated({ IsBusy: true });
    const response = quoteCode.value ? await placeQuoteOrder(sellerKey) : await placeCartOrder();
    isBusyUpdated({ IsBusy: false });

    return { ...response };
  };

  const placeCartOrder = async () => {
    const sellerPoList: ISellerGroupPO[] = [] as ISellerGroupPO[];
    sellerGroup.value?.forEach((seller) => {
      sellerPoList.push({ shopId: seller.Key, poNumber: seller.PONumber });
    });

    const { IsSuccess, Data } = await Req({
      method: 'POST',
      url: OrderApi.PlaceOrder,
      data: { cartcode: cartStore.currentCart.Code, ponumbers: sellerPoList },
    });

    ordersStore.orderPlaced({ receipt: Data });

    return { IsSuccess, Data };
  };

  const placeQuoteOrder = async (sellerKey: string) => {
    const sellerData = seller(sellerKey) || ({} as ISellerGroup);
    const orderData = sellerData?.NamedDeliveryDate
      ? dayjs(sellerData.NamedDeliveryDate).format(DisplayDateFormat)
      : dayjs(new Date()).format(DisplayDateFormat);
    const quoteResponseId = quotesStore.quoteResponseId ?? '';
    const quoteToOrder: QuoteToOrder | ISellerGroup = {
      ForwarderId: sellerData?.Carrier?.Code,
      CustomForwarderInfo: {
        ForwarderName: sellerData.Carrier.Name,
        ForwarderAccountNumber: sellerData.CarrierInfo,
        AdditionalInfo: sellerData.CarrierInstructions,
      } as CustomForwarderInformation,
      ForwarderInfo: sellerData?.CarrierInstructions,
      ForwarderAccountNumber: sellerData?.CarrierInfo,
      ServiceLevelAgreements: sellerData?.ServiceLevelAgreements,
      ProjectId: '',
      NamedDeliveryDate: orderData as string,
      PurchaseOrderNumber: decodeURIComponent(sellerData?.PONumber),
      ShippingAddressId: sellerData?.DeliveryAddress?.Id,
      PriorityInfo: sellerData?.PriorityInfo,
      QuoteEntries: checkoutCart.value?.Entries?.filter(
        (entry) => entry.Quantity && entry.Quantity > 0,
      ).map((entry) => {
        return {
          EntryNumber: entry.EntryNumber,
          Quantity: entry.Quantity || 1,
        };
      }),
      QuoteResponseId: quoteResponseId,
    };

    const { IsSuccess, Data } = await Req({
      method: 'POST',
      url: QuoteApi.OrderQuote(quoteCode.value),
      data: { quoteToOrder },
    });

    const manualDataHandlingQuoteReceipt: OrderReceipt = {
      OrderResults: [
        {
          MasterShopId: sellerKey,
          Order: Object.assign({}, Data, {}),
        },
      ],
    };

    ordersStore.orderPlaced({ receipt: manualDataHandlingQuoteReceipt });

    return { IsSuccess, Data };
  };

  const fetchCustomerCodes = async (payload: { userGlobalId: string }) => {
    return new Promise(async (resolve) => {
      const { IsSuccess, Data } = await Req({
        method: 'GET',
        url: UserApi.FetchCustomerCodes(payload.userGlobalId),
      });
      if (IsSuccess) {
        addCustomerCodes({ customerCodes: Data.CustomerCodes });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const setCustomerCode = async (payload: {
    customerCode: string;
    cartId: string;
    masterShopId: string;
  }) => {
    if (payload.masterShopId !== 'airbus') {
      return;
    }
    const { IsSuccess } = await Req({
      method: 'PUT',
      url: CartApi.SetCustomerCode(payload.customerCode, payload.cartId, payload.masterShopId),
    });
    if (IsSuccess) {
      customerCodeSelected({
        sellerId: payload.masterShopId,
        customerCode: payload.customerCode,
      });
    }
  };

  const setOrderType = async (payload: {
    orderType: OrderType;
    cartId: string;
    masterShopId: string;
  }) => {
    if (payload.masterShopId !== 'airbus') {
      return;
    }

    const { IsSuccess } = await Req({
      method: 'PUT',
      url: CartApi.UpdateOrderType(payload.orderType.Code, payload.cartId, payload.masterShopId),
    });
    if (IsSuccess) {
      orderTypeSelected({
        sellerId: payload.masterShopId,
        orderType: payload.orderType,
      });
    }
  };

  const fetchOrderTypes = async () => {
    return new Promise(async (resolve) => {
      const { IsSuccess, Data } = await Req({
        method: 'GET',
        url: ProductApi.ProductCheckoutoptions,
      });
      if (IsSuccess) {
        addOrderTypes({ orderTypes: Data.OrderTypes });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const setIsBusy = (payload: { isBusy: boolean }) => {
    isBusyUpdated({ IsBusy: payload.isBusy });
  };

  const updatePONumber = async (payload: { sellerId: string; poNumber: string }) => {
    poNumberUpdated({ sellerId: payload.sellerId, poNumber: payload.poNumber });
  };

  const updateAcReg = async (payload: { sellerId: string; acReg: string }) => {
    acRegUpdated({ sellerId: payload.sellerId, acReg: payload.acReg });
  };

  const setDeliveryDate = async (payload: { sellerId: string; date: string }) => {
    deliveryDateUpdate({ sellerId: payload.sellerId, date: payload.date });
  };

  const setTermsAcccept = async (payload: { sellerId: string; accept: boolean }) => {
    termsUpdated({ sellerId: payload.sellerId, accept: payload.accept });
  };

  const setQuoteCode = async (payload: { quoteCode: string }) => {
    quoteCodeUpdated({ quoteCode: payload.quoteCode });
  };

  const selectCustomerCode = async (payload: {
    masterShopId: string;
    cartId: string;
    customerCode: string;
  }) => {
    setCustomerCode(payload);
  };

  const setSellerCustomerCode = async (payload: { sellerId: string; customerCode: string }) => {
    customerCodeSelected(payload);
  };

  const selectOrderType = async (payload: {
    masterShopId: string;
    cartId: string;
    orderType: OrderType;
  }) => {
    setOrderType(payload);
  };

  const sellerCheckoutValidation = computed(() => {
    const sellerValidationList: ICheckoutValidation[] = [];
    sellerGroup.value?.forEach((seller) => {
      if (seller) {
        if (seller.Key === 'airbus') {
          // check if there are mandatory remarks
          const mandatoryRemarksFilledOut = () => {
            let remarksFilledOut = true;
            for (const entry of seller.Entries) {
              if (entry.Product.IsKit && (!entry.AirbusRemarks || entry.AirbusRemarks === '')) {
                remarksFilledOut = false;
              }
            }
            return remarksFilledOut;
          };

          sellerValidationList.push({
            SellerId: seller.Key,
            PONumber: decodeURIComponent(seller.PONumber) || '',
            Carrier: 'default',
            AcReg: seller.PriorityInfo?.AcReg || '',
            Priority: seller.PriorityInfo?.Priority.Code || '',
            TermsAccepted: seller.TermsAccepted || false,
            CustomerCode: seller.CustomerCode || '',
            OrderType: (seller?.OrderType as OrderType)?.Code || 'ZRP', // Always the default order type,
            Remarks: mandatoryRemarksFilledOut() ? true : undefined,
          });
        } else {
          sellerValidationList.push({
            SellerId: seller.Key,
            PONumber: decodeURIComponent(seller.PONumber) || '',
            Carrier:
              seller.Carrier?.Name ||
              (seller.Key === 'satair' && !forwarderInformationRequired.value)
                ? 'default'
                : '',
            AcReg: seller.PriorityInfo?.AcReg || '',
            Priority: seller.PriorityInfo?.Priority.Code || '',
            TermsAccepted: seller.TermsAccepted || false,
          });
        }
      }
    });

    return sellerValidationList;
  });

  const allTermsAccepted = computed(() => {
    return sellerGroup.value?.every((seller) => seller.TermsAccepted);
  });

  const termsAccepted = (sellerKey: string) => {
    return seller(sellerKey)?.TermsAccepted;
  };

  const checkoutSellers = computed(() => {
    return sellerGroup.value;
  });

  const sellersIds = computed(() => {
    return (
      sellerGroup.value?.map?.((seller) => {
        return seller.Key;
      }) || []
    );
  });

  const sellers = computed(() => {
    const sellerSource = quoteCode.value ? quotesStore.sellers : cartStore.sellers;

    if (sellerGroup.value.length < 1) {
      sellerGroup.value = cartStore.currentCart.SellerGroup;
    }

    if (!sellerGroup.value) {
      return [];
    }

    return (
      sellerGroup.value.map((seller) => {
        if (quoteCode.value) {
          seller.TermsAndPolicies = quotesStore.checkoutQuote.SellerGroup.TermsAndPolicies;
        }

        const originalSeller = sellerSource.find(({ Key }) => Key === seller.Key);

        return {
          ...originalSeller,
          ...seller,
        } as ISellerGroup;
      }) || []
    );
  });

  const seller = (sellerKey: string) => {
    return sellers.value.find((seller) => seller.Key === sellerKey);
  };

  const priority = (sellerKey: string) => {
    return seller(sellerKey)?.PriorityInfo?.Priority?.Code || '';
  };

  const acReg = (sellerKey: string) => {
    return seller(sellerKey)?.PriorityInfo?.AcReg || '';
  };

  const isSatair = (sellerKey: string) => {
    return seller(sellerKey)?.Key === 'satair';
  };

  const checkoutCart = computed(() => {
    return quoteCode.value ? quotesStore.checkoutQuote : cartStore.currentCart;
  });

  const totalPriceWithShipping = computed(() => {
    return quoteCode.value ? quotesStore.checkoutQuote.TotalPriceWithShipping : null;
  });

  const forwarderInformationRequired = computed(() => {
    return userStore.redB2BUnit?.ForwarderInformationRequired;
  });

  return {
    checkoutSellers,
    sellersIds,
    sellers,
    sellerGroup,
    isBusy,
    sellerCheckoutValidation,
    allTermsAccepted,
    quoteCode,
    checkoutCart,
    forwarderInformationRequired,
    customerCodes,
    orderTypes,
    totalPriceWithShipping,
    updateCarrier,
    setPriority,
    updateRemark,
    addSellerGroup,
    updateDeliveryAddress,
    placeOrder,
    placeCartOrder,
    placeQuoteOrder,
    fetchCustomerCodes,
    setCustomerCode,
    setOrderType,
    fetchOrderTypes,
    setIsBusy,
    updatePONumber,
    updateAcReg,
    setDeliveryDate,
    setTermsAcccept,
    setQuoteCode,
    selectCustomerCode,
    setSellerCustomerCode,
    selectOrderType,
    termsAccepted,
    seller,
    priority,
    acReg,
    isSatair,
    reset,
    resetSellers,
  };
});
