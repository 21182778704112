<template>
  <div class="login-modal">
    <StandardDialogModal :size="uiSize.Medium">
      <template #header>
        <h3>{{ textK('UI_SIGNUP_GET_ACCESS') }}</h3>
      </template>
      <template #body>
        <div class="inner-body">
          <p>{{ textK('UI_SIGNUP_GET_ACCESS_DESCRIPTION') }}</p>

          <ListNavigation class="list-navigation">
            <ListNavigationItem @click.native="requestAccess('company')">
              <template #icon>
                <svgicon name="ui-new-account"></svgicon>
              </template>
              <template #title>{{ textK('UI_SIGNUP_NEW_BUSINESS_ACCOUNT') }}</template>
              <template #description>Request a new business account</template>
            </ListNavigationItem>
            <ListNavigationItem @click.native="requestAccess('user')">
              <template #icon>
                <svgicon name="ui-user-administration"></svgicon>
              </template>
              <template #title>{{ textK('UI_SIGNUP_NEW_USER_ACCOUNT') }}</template>
              <template #description>{{
                textK('UI_SIGNUP_NEW_USER_ACCOUNT_DESCRIPTION')
              }}</template>
            </ListNavigationItem>
          </ListNavigation>
        </div>
      </template>
      <template #actions>
        <a @click="showLoginModal()" class="back-button">{{ textK('UI_LOGIN_BACK_BUTTON') }}</a>
      </template>
    </StandardDialogModal>
  </div>
</template>

<style lang="scss" src="./modal-get-access.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import ListNavigationItem from '@/src/core/components/ui/list-navigation-links/list-navigation-item/list-navigation-item.vue';
import ListNavigation from '@/src/core/components/ui/list-navigation-links/list-navigation.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;

const showLoginModal = () => {
  modalStore.closeModal();
  modalStore.showModal({ modalComponent: 'ModalLogin', single: true });
};

const requestAccess = (type: 'company' | 'user') => {
  const backToModal = 'ModalGetAccess';
  modalStore.closeModal();
  modalStore.showModal({
    modalComponent: 'ModalRequestAccess',
    params: { type, backToModal },
  });
};
</script>
