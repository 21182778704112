<template>
  <div class="product-details-actions">
    <div class="product-details-actions__restricted" v-if="isUserRestricted && isAuthenticated">
      <div class="product-details-actions__restricted-price">
        {{ textK('UI_PRODUCT_OUTOFPRODUCTION') }}
      </div>
      <div class="product-details-actions__restricted-button">
        <VButton
          v-ui-test="'pdp-user-restricted'"
          :type="uiVariant.Primary"
          :disabled="true"
          :size="uiSize.Auto"
          class="product-button-login"
          >{{ textK('UI_PRODUCT_RESTRICTED_BUTTON') }}</VButton
        >
      </div>
    </div>
    <template v-else>
      <div class="product-details-actions">
        <div class="product-details-actions__primary" v-ui-test="'pdp-price-area'">
          <ProductPriceQuantity
            :id="id"
            actionview="product detail page"
            :can-bid-or-quote="isQuotableProduct"
            :is-ztel="isZtel"
            :owning-system="owningSystem"
            force-fetch
          />
        </div>
        <div
          class="load-more"
          v-if="product && !product.IsMarketplace && ruleStatusOK && isQuotableProduct"
        >
          <template>
            <VButton
              @click="openQuoteModal"
              v-ui-test="'create quote'"
              :type="uiVariant.Secondary"
              :size="uiSize.Auto"
              class="product-button-login"
            >
              {{ textK('UI_QUOTES_REQUEST_CTA') }}
            </VButton>
          </template>
        </div>
        <template v-if="product && productAdditionalData && isAuthenticated">
          <div
            class="product-details-actions__secondary"
            v-ui-test="'product-details-actions-secondary'"
          >
            <VButton
              :type="uiVariant.Secondary"
              :size="uiSize.Auto"
              v-if="
                !product.PriceHidden &&
                !isRestricted &&
                product.AllowQuoteRequests &&
                isQuotableProduct &&
                !readOnlyAccount
              "
              @click="
                openModal({
                  modalComponent: 'ModalRequestQuote',
                  params: {
                    id: id,
                    sellerId: sellerId,
                    isMarketplace: product.IsMarketplace,
                  },
                })
              "
            >
              {{ textK('UI_QUOTES_REQUEST_EXISTING_PRICE_TITLE') }}</VButton
            >
          </div>
          <div class="product-details-actions__tertiary">
            <AskSellerButton
              v-if="
                (ruleStatusOK || (hasNoPrice && isQuotableProduct)) &&
                product.IsMarketplace &&
                !readOnlyAccount
              "
              :id="id"
            />
            <VButton
              v-if="isButtonVisible"
              :type="uiVariant.Tertiary"
              @click="
                openModal({
                  modalComponent: 'ModalAddToList',
                  params: {
                    Code: id,
                    actionView: 'product detail page',
                  },
                })
              "
            >
              <template #iconPrepend>
                <svgicon name="ui-my-lists"></svgicon>
              </template>
              {{ textK('UI_PRODUCT_ADDTOLIST') }}
            </VButton>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./product-details-actions.scss" scoped />

<script lang="ts" setup>
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { AirbusPart, IInitialWindow } from '@/src/core/types/interfaces';
import { RuleStatus } from '@/src/core/types/rule-status';
import { useRoute } from '@/src/core/utils/router';
import ProductPriceQuantity from '@/src/market/components/product/product-price-quantity/product-price-quantity.vue';
import AskSellerButton from '@/src/market/components/seller/seller-conversation/ask-seller-button/ask-seller-button.vue';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import {
  isSatair,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import {
  hasNoPrice as hasNoPriceService,
  isProductZtel,
  isQuotable,
  isRestricted as isRestrictedService,
  isSellable,
} from '@/src/market/services/product-rules';
import { isAirbusProduct } from '@/src/market/services/productservice';
import { isBlueCustomer, isRedCustomer } from '@/src/market/services/user-service';
import { useProductStore } from '@/src/market/stores/product';
import { computed } from 'vue';

interface Props {
  id: string;
  sellerId: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const openModal = useModal();
const productStore = useProductStore();
const authenticationStore = useAuthenticationStore();
const route = useRoute();
const uiVariant = UiVariant;
const uiSize = UiSize;

const readOnlyAccount = computed(() => authenticationStore.readOnlyAccount);

const enableSatairQuotes = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return (window as unknown as IInitialWindow).features.enableSatairQuotes;
  }
  return false;
});

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const product = computed(() => productStore.productById(props.id));

const productAdditionalData = computed(() =>
  productAdditionalDataService(props.id, props.owningSystem),
);

const isQuotableProduct = computed(() => {
  if (isAirbus.value) {
    return (
      isQuotable(productAdditionalData.value) &&
      procurement.value?.procurementType === ProcurementType.New
    );
  }

  if (product.value?.OwningSystem === OwningSystem.MIRAKL) {
    return isQuotable(productAdditionalData.value);
  }

  if (isSatair(productAdditionalData.value)) {
    return isQuotable(productAdditionalData.value) && enableSatairQuotes.value;
  }
  return isQuotable(productAdditionalData.value, procurement.value);
});

const ruleStatusOK = computed(() => isSellable(productAdditionalData.value, procurement.value));

const isRestricted = computed(() =>
  isRestrictedService(productAdditionalData.value, procurement.value),
);

const hasNoPrice = computed(() =>
  hasNoPriceService(productAdditionalData.value, procurement.value),
);

const isUserRestricted = computed(() => {
  if (props.owningSystem === OwningSystem.BLUE) {
    if (isBlueCustomer()) {
      const hasProcurements = Boolean((productAdditionalData.value as AirbusPart)?.procurements);
      return !hasProcurements;
    }
  } else {
    if (isRedCustomer()) {
      return false;
    }
  }
  return true;
});

const isButtonVisible = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return (
      productAdditionalData.value.RuleStatus === RuleStatus.OK ||
      productAdditionalData.value.RuleStatus === RuleStatus.NO_PRICE
    );
  }
  return withSellableProcurement.value;
});

const procurement = computed(() => {
  const productData = (productAdditionalData.value as AirbusPart)?.procurements;

  if (!productData) {
    return undefined;
  }

  return getProcurementByRouteParams(route.params, productData);
});

const withSellableProcurement = computed(() => {
  if (!procurement.value) {
    return (productAdditionalData.value as AirbusPart).productRule.sellable === RuleStatus.YES;
  }
  const unsellableProcurements = [
    ProcurementType.Used,
    ProcurementType.Exchange,
    ProcurementType.ExchangeUsed,
    ProcurementType.ExchangeNew,
    ProcurementType.Lease,
    ProcurementType.Repair,
  ] as string[];
  if (
    unsellableProcurements.includes(procurement.value.procurementType) ||
    procurement.value.productRule.sellable !== RuleStatus.YES
  ) {
    return false;
  }
  return true;
});

const isAirbus = computed(() => {
  return isAirbusProduct(props.owningSystem);
});

const isZtel = computed(() => {
  return isProductZtel(product.value) && ruleStatusOK.value;
});

const openQuoteModal = () => {
  if (isAirbus.value) {
    openModal({
      modalComponent: 'ModalCreateAirbusQuote',
      params: { id: product.value?.Id },
    });
    return;
  }

  openModal({
    modalComponent: 'ModalCreateSingleLineQuote',
    params: { id: product.value?.Id },
  });
};
</script>
