import { OwningSystem, Product } from '@/src/core/types/api';
import { AirbusPart, IProductAdditionalData } from '@/src/core/types/interfaces';
import { useAirbusPartStore } from '../stores/airbus-part';
import { useProductAdditionalInfoStore } from '../stores/product-additional-info';

// TODO: Rename to isRedOwningSystem
export const isSatair = (
  productData?: IProductAdditionalData | AirbusPart | Product | null,
): productData is IProductAdditionalData => {
  return (productData as IProductAdditionalData)?.Id !== undefined;
};

export const isAirbus = (
  productData?: IProductAdditionalData | AirbusPart | null,
): productData is AirbusPart => {
  return (productData as AirbusPart)?.productId !== undefined;
};

export const productAdditionalData = (id: string, owningSystem: string) => {
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const airbusPartStore = useAirbusPartStore();

  if (!id && !owningSystem) {
    return null;
  }

  if (owningSystem === OwningSystem.BLUE) {
    return airbusPartStore.airbusPartByOfferId(id);
  } else {
    return productAdditionalInfoStore.additionalDataByOfferId(id);
  }
};

export const isAirbusProduct = (owningSystem?: string): boolean => {
  return owningSystem === OwningSystem.BLUE;
};
