import { pinia } from '@/src/core/utils/pinia-utils';
import { useUserStore } from '@/src/profile/stores/user';
import { watch } from 'vue';

const userStore = useUserStore(pinia);

export const userUserFetched = () => {
  const onIsUserFetched = (callback: () => void) => {
    watch(
      () => userStore.profile,
      (user) => {
        if (user) {
          callback();
        }
      },
      { deep: true, immediate: true },
    );
  };

  return {
    onIsUserFetched,
  };
};
