<template>
  <p
    v-if="seller === 'Airbus' && isAuthenticated"
    class="product-details-list-item-value"
    v-ui-test="'productDetailsSupplier'"
  >
    {{
      accountGroup === 'US'
        ? textK('UI_COMMON_LEGAL_NAME_AIRBUS_3000')
        : textK('UI_COMMON_LEGAL_NAME_AIRBUS_1000')
    }}
  </p>
  <p v-else class="product-details-list-item-value" v-ui-test="'productDetailsSupplier'">
    {{ seller }}
  </p>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

interface Props {
  seller: string;
}

defineProps<Props>();

const textK = useText();
const authenticationStore = useAuthenticationStore();
const userStore = useUserStore();

const accountGroup = computed(() => userStore.accountGroup);
const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
</script>
