/*
 * SIMPLE ATTRIBUTE SETTER FOR TEST PURPOSE
 */

import { VueConstructor } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const UiTests = (Vue: VueConstructor, UiTestOptions: IUiTestOptions) => {
  Vue.directive('ui-test', {
    bind(el: HTMLElement, binding: DirectiveBinding) {
      setTag(el, binding);
    },
    update(el: HTMLElement, binding: DirectiveBinding) {
      setTag(el, binding);
    },
  });

  const setTag = (el: HTMLElement, binding: DirectiveBinding) => {
    if (UiTestOptions.showTestAttr) {
      el.setAttribute('data-' + binding.name, binding.value);
    }
  };
};

export default UiTests;

export interface IUiTestOptions {
  showTestAttr: boolean;
}
