<template>
  <div class="solution-menu">
    <div class="solution-cloud">
      <div class="solution-section">
        <nav class="solution-section__inner">
          <div class="app-group">
            <span class="app-group__title">
              {{ textK('UI_SOLUTIONS_SATAIR_APPS_TITLE') }}
            </span>
            <div class="app-group__inner">
              <router-link
                :to="{ name: 'search' }"
                class="solution-app"
                v-ui-test="'solution-menu-link-market'"
              >
                <SolutionItem
                  @click.native="closeDropdown()"
                  @mouseenter.native="marketIcon?.onEnter()"
                  @mouseleave.native="marketIcon?.onLeave()"
                >
                  <template #image>
                    <MarketSolutionIcon
                      ref="marketIcon"
                      class="cloud-item__image"
                      alt="Satair Market"
                    />
                  </template>
                  <template #title>
                    {{ textK('UI_SOLUTIONS_MARKET_TITLE') }}
                  </template>
                  <template #text>
                    {{ textK('UI_SOLUTIONS_MARKET_DESCRIPTION') }}
                  </template>
                </SolutionItem>
              </router-link>

              <router-link
                v-if="showFinance"
                :to="{ name: 'finance' }"
                class="solution-app"
                v-ui-test="'solution-menu-link-finance'"
              >
                <SolutionItem @click.native="closeDropdown()">
                  <template #image>
                    <FinanceSolutionIcon
                      ref="financeicon"
                      class="cloud-item__image"
                      alt="Satair Finance"
                    />
                  </template>
                  <template #title>
                    {{ textK('UI_SOLUTIONS_FINANCE_TITLE') }}
                  </template>
                  <template #text>
                    {{ textK('UI_SOLUTIONS_FINANCE_DESCRIPTION') }}
                  </template>
                </SolutionItem>
              </router-link>

              <router-link
                v-if="showPlanning"
                :to="{ name: 'planning' }"
                class="solution-app"
                v-ui-test="'solution-menu-link-planning'"
              >
                <SolutionItem
                  @click.native="closeDropdown()"
                  @mouseenter.native="isPlanningHovered = true"
                  @mouseleave.native="isPlanningHovered = false"
                >
                  <template #image>
                    <PlanningSolutionIcon
                      ref="planningicon"
                      class="cloud-item__image"
                      alt="Satair Planning"
                      :is-hovered="isPlanningHovered"
                    />
                  </template>
                  <template #title>
                    {{ textK('UI_SOLUTIONS_PLANNING_TITLE') }}
                  </template>
                  <template #text>
                    {{ textK('UI_SOLUTIONS_PLANNING_DESCRIPTION') }}
                  </template>
                </SolutionItem>
              </router-link>
            </div>
          </div>
          <div class="app-group">
            <span class="app-group__title">
              {{ textK('UI_SOLUTIONS_AIRBUS_APPS_TITLE') }}
            </span>
            <div class="app-group__inner">
              <a
                href="https://spares.airbus.com/"
                target="_blank"
                class="solution-app"
                v-ui-test="'solution-menu-link-airbus-spares'"
              >
                <SolutionItem>
                  <template #image>
                    <img class="cloud-item__image" :src="airbusSparesLogoUrl" alt="Airbus Spares" />
                  </template>
                  <template #title>
                    {{ textK('UI_SOLUTIONS_AIRBUS_TITLE') }}
                  </template>
                  <template #text>
                    {{ textK('UI_SOLUTIONS_AIRBUS_DESCRIPTION') }}
                  </template>
                </SolutionItem>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="solution-link-hub">
      <div class="solution-section">
        <div class="solution-section__inner">
          <div class="link-group-container">
            <nav class="link-group" v-for="linkGroup in quickLinks" :key="linkGroup.Header">
              <span class="link-group__title">{{ linkGroup.Header }}</span>
              <a
                v-for="(linkItem, i) of linkGroup.Items"
                :href="linkItem.Link"
                :to="!linkItem.LinkExternal ? linkItem.Link : null"
                :is="!linkItem.LinkExternal ? 'router-link' : 'a'"
                :target="linkItem.LinkOpenNew ? '_blank' : undefined"
                class="link-item"
                @click="!linkItem.LinkExternal ? closeDropdown($event) : null"
                :key="i"
                v-ui-test="'solution-menu-group-link'"
              >
                <div class="link-item__icon">
                  <img :src="linkItem.Icon" :alt="linkItem.Headline" />
                </div>
                <div class="link-item__body">
                  <span class="link-item__title">{{ linkItem.Headline }}</span>
                  <span class="link-item__description" v-html="linkItem.Description"></span>
                </div>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import airbusSparesLogoUrl from '@/src/core/assets/gfx/apps/airbus-spares.svg?url';
import FinanceSolutionIcon from '@/src/core/components/navigation/navigation-tools/solution-menu/solution-icons/finance-solution-icons/finance-solution-icon.vue';
import MarketSolutionIcon from '@/src/core/components/navigation/navigation-tools/solution-menu/solution-icons/market-solution-icon/market-solution-icon.vue';
import PlanningSolutionIcon from '@/src/core/components/navigation/navigation-tools/solution-menu/solution-icons/planning-solution-icon/planning-solution-icon.vue';
import SolutionItem from '@/src/core/components/navigation/navigation-tools/solution-menu/solution-item/solution-item.vue';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { useSolutionMenuStore } from '@/src/core/stores/solution-menu';
import { useUIStore } from '@/src/core/stores/ui';
import {
  blueB2BUnit,
  isBlueCustomer,
  isMixedCustomer,
  redB2BUnit,
} from '@/src/market/services/user-service';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const routeConfigStore = useRouteConfigStore();
const solutionMenuStore = useSolutionMenuStore();
const authenticationStore = useAuthenticationStore();
const uiStore = useUIStore();

const marketIcon = ref<InstanceType<typeof MarketSolutionIcon> | null>(null);
const isPlanningHovered = ref(false);

const closeDropdown = ($event?: Event) => {
  if ($event) {
    $event.preventDefault();
  }
  uiStore.setNavigationDropdownId({ id: null });
};

const quickLinks = computed(() => {
  return solutionMenuStore.quickLinks;
});

const fetchQuickLinks = () => {
  if (!(quickLinks.value && quickLinks.value.length > 0)) {
    solutionMenuStore.fetchQuickLinks();
  }
};

const isFinanceUser = computed(() => {
  return authenticationStore.isFinance;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const isAirbusFinanceEnabled = computed(() => {
  return hasFeature(FEATURES.FINANCE_AIRBUS);
});

const isPlanningEnabled = computed(() => {
  return hasFeature(FEATURES.PLANNING);
});

const isFinancePublic = computed(() => {
  return (
    (!routeConfigStore.getMenuConfigBeta('finance') ||
      (routeConfigStore.getMenuConfigBeta('finance') && authenticationStore.betaCustomer)) &&
    hasFeature(FEATURES.FINANCE_SECTION)
  );
});

const isRedB2BUnit = computed(() => {
  return redB2BUnit();
});

const isBlueB2BUnit = computed(() => {
  return blueB2BUnit();
});

const showFinance = computed(() => {
  if (isAuthenticated.value) {
    if (!isRedB2BUnit.value && isBlueB2BUnit.value) {
      return isFinanceUser.value && isFinancePublic.value && isAirbusFinanceEnabled.value;
    }
    return isFinanceUser.value && isFinancePublic.value;
  }
  return false;
});

const showPlanning = computed(() => {
  return (isBlueCustomer() || isMixedCustomer()) && isPlanningEnabled.value;
});

onMounted(() => {
  fetchQuickLinks();
  uiStore.setShowHelper({ show: false });
});
</script>

<style lang="scss" src="./solution-menu.scss" scoped />
