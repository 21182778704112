<template>
  <div>
    <div
      class="select-container"
      :class="{
        isActive: value,
        error: showErrorMessage,
        // 'isFocused' : isFocused,
        loading: loading,
        small: size === uiSize.Small,
        medium: size === uiSize.Medium,
        large: size === uiSize.Large,
        'shop-site': context === uiContext.ShopSite,
        'content-site': context === uiContext.ContentSite,
        default: type === uiVariant.Default || type === uiVariant.Primary,
        secondary: type === uiVariant.Secondary,
        transparent: type === uiVariant.Transparent,
        'has-icon-after': $slots.append,
        'has-icon-before': $slots.prepend,
        'is-required': required,
        'is-toolbar-dropdown': toolbarButton,
        disabled: disabled,
      }"
      v-click-outside="closeDropdown"
    >
      <KeyboardListInteraction
        :active="open"
        :list-length="formattedOptions.length"
        @searchable="searchDropdown($event)"
        @escape="closeDropdown()"
        @backspace="resetSearch()"
        @arrowNavigation="hoveredIndex = scrollToIndex = hoverOption((hoveredIndex += $event))"
        @enter="
          formattedOptions[hoveredIndex] && !formattedOptions[hoveredIndex].disabled
            ? select(hoveredIndex)
            : null
        "
      >
        <Dropdown
          :dropdown-style="true"
          :mobile-bottom-position="true"
          :is-assistant="isAssistant"
          v-if="open"
          @dropDownOpen="!disabled ? (open = $event) : open"
          :scroll-to-element-index="scrollToIndex"
        >
          <template v-if="!optionsSkeleton">
            <DropdownOption
              v-for="(option, i) of formattedOptions"
              :context="context"
              @mouseover.native="hoveredIndex = i"
              @click="select(i)"
              :key="i"
              :selected="i === value"
              :active="hoveredIndex === i"
              :disabled="option.disabled"
              :ui-test-name="uiTestNameDropdown"
            >
              <template v-if="hoveredIndex === i">
                <span v-html="highlight(option[optionLabel], searchString)"></span>
              </template>
              <template v-else>
                {{ option[optionLabel] }}
              </template>
            </DropdownOption>
          </template>
          <template v-if="optionsSkeleton">
            <DropdownOption v-for="(index, i) in 8" :key="i" :disabled="true">
              <SkeletonLoader />
            </DropdownOption>
          </template>
        </Dropdown>
      </KeyboardListInteraction>

      <ToolbarButton
        class="select-icon-button"
        v-if="toolbarButton && icon"
        :icon="icon"
        :on-click="toggleDropdown"
        >{{ label || name }}</ToolbarButton
      >
      <transition name="select-expand">
        <div v-if="!toolbarButton || (value !== 0 && value !== null)" class="select-input-wrapper">
          <div :class="!disabled ? 'select-click-container' : ''" @click="toggleDropdown"></div>
          <input
            :disabled="!!disabled"
            ref="secretinput"
            class="secretinput"
            type="text"
            @keyup="keyUp"
            @keydown="keyDown"
            inputmode="none"
          />
          <VTextField
            ref="input"
            class="select-input"
            :value="selectedInputLabelValue(value, optionLabel)"
            :name="name"
            :label="label"
            :placeholder="placeholder"
            :id="id"
            :size="size"
            :type="type"
            :context="context"
            :skeleton="skeleton"
            :silent-disabled="true"
            :as-span="size === uiSize.Auto"
          >
            <template #append>
              <div class="select-arrow" :class="{ 'select-arrow--active': open }">
                <svgicon name="ui-arrow" @click="toggleDropdown">sdfsdfsd</svgicon>
              </div>
            </template>
          </VTextField>
        </div>
      </transition>
    </div>
    <TransitionExpand transform>
      <InputFeedback :type="notificationTypes.Error" v-if="showErrorMessage">
        <transition name="fade" mode="out-in">
          <span key="1">{{ textK('UI_INPUTERROR_EMPTY') }}</span>
        </transition>
      </InputFeedback>
    </TransitionExpand>
  </div>
</template>
<style lang="scss" src="./select.scss" scoped></style>

<script lang="ts">
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import DropdownOption from '@/src/core/components/ui/dropdown/dropdown-option/dropdown-option.vue';
import Dropdown from '@/src/core/components/ui/dropdown/dropdown.vue';
import InputFeedback from '@/src/core/components/ui/form/input-feedback/input-feedback.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import ToolbarButton from '@/src/core/components/ui/toolbar/toolbar-button/toolbar-button.vue';
import { UiContext, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import KeyboardListInteraction from '@/src/core/components/util/keyboard/list-interaction/keyboard-list-interaction.vue';
import useHighlight from '@/src/core/hooks/useHighlight';
import useText from '@/src/core/hooks/useText';
import { NotificationTypes } from '@/src/core/types/api';
import { IeKeys } from '@/src/core/types/keys';
import { IVSelect } from '@/src/core/types/ui';
import { Key } from 'ts-key-enum';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    VTextField,
    ToolbarButton,
    Dropdown,
    KeyboardListInteraction,
    DropdownOption,
    SkeletonLoader,
    TransitionExpand,
    InputFeedback,
  },
})
export default class VSelect extends Vue {
  public textK = useText();
  public highlight = useHighlight;
  @Prop() public options: IVSelect[];
  @Prop({ default: -1 }) public value: number;
  @Prop({ default: 'text' }) private inputType: string;
  @Prop() public name: string;
  @Prop() public label: string;
  @Prop() public placeholder: string;
  @Prop() public id: string;
  @Prop({ default: 'label' }) public optionLabel: string;
  @Prop({ default: UiSize.Medium }) public size: UiSize;
  @Prop({ default: UiVariant.Default }) public type: UiVariant;
  @Prop({ default: UiContext.ShopSite }) public context: UiContext;
  @Prop({ default: false }) public loading: boolean;
  @Prop({ default: false }) public skeleton: boolean;
  @Prop({ default: false }) public optionsSkeleton: boolean;
  @Prop({ default: false }) public required: boolean;
  @Prop({ default: false }) public disabled: boolean;
  @Prop({ default: false }) public searchable: boolean;
  @Prop({ default: false }) public toolbarButton: boolean;
  @Prop({ default: false }) private isInputField: boolean;
  @Prop() public icon: string;
  @Prop({ default: false }) public isAssistant: boolean;
  @Prop() public uiTestNameDropdown: string;

  public $refs: {
    container: HTMLElement;
    input: VTextField;
    secretinput: HTMLInputElement;
  };

  public uiSize = UiSize;
  public uiVariant = UiVariant;
  public uiContext = UiContext;
  public open: boolean = false;
  public formattedOptions: IVSelect[] = [];
  private currentValue: number = 0;
  public notificationTypes = NotificationTypes;
  private dirty = false;

  public hoveredIndex: number = -1;
  private selectedIndex: number = -1;
  public scrollToIndex: number = -1;

  public searchString: string = '';

  public keyUp($event: KeyboardEvent) {
    const key: string = $event.key;

    if ($event.metaKey || $event.ctrlKey || this.open) {
      return;
    }
    switch (key) {
      case Key.Tab:
      case Key.Backspace:
      case Key.Delete:
        break;
      case ' ':
      case IeKeys.ArrowDown:
      case IeKeys.ArrowUp:
      case Key.ArrowDown:
      case Key.ArrowUp:
      case IeKeys.Space:
        $event.preventDefault();
        this.toggleDropdown();
    }
  }

  public keyDown($event: KeyboardEvent) {
    const key: string = $event.key;
    switch (key) {
      case Key.Tab:
        if (this.open) {
          this.closeDropdown();
        }
        break;
    }
  }

  public hoverOption(index?: number): number {
    index = index ? parseInt(index.toString(), 10) : 0;
    this.resetSearch();

    if (index === null || index === undefined) {
      index = 0;
    }

    if (index >= this.formattedOptions.length - 1) {
      index = this.formattedOptions.length - 1;
    } else if (index <= 0) {
      index = 0;
    }
    return index;
  }

  private searchTimer: number = 0;
  private isSearching: boolean = false;

  public searchDropdown(key: KeyboardEvent) {
    const keyToString = key.toString().toLowerCase() || '';

    // Begin "search" when one char is typed
    if (this.searchString.length <= 1) {
      this.isSearching = true;
    }

    // Clear and begin timing to reset search
    window.clearTimeout(this.searchTimer);
    this.searchTimer = window.setTimeout(() => {
      this.isSearching = false;
    }, 1500);

    // If search has reset (from timeout), clear search and timer
    if (!this.isSearching) {
      this.resetSearch();
    }

    // Set the search
    this.searchString += keyToString;

    this.hoveredIndex = this.formattedOptions.findIndex((option) => {
      return option[this.optionLabel].toLowerCase().startsWith(this.searchString);
    });
    this.scrollToIndex = this.hoveredIndex;
  }

  public resetSearch() {
    this.searchString = '';
    window.clearTimeout(this.searchTimer);
  }

  public select(index: number) {
    if (this.value !== index) {
      this.$emit('input', index);
      if (this.required && this.value <= 0) {
        this.emitIsValid(true);
      }
    }
    this.closeDropdown();
    this.resetSearch();
  }

  private createOptions(options: string[]): IVSelect[] {
    const newOptions: IVSelect[] = [];
    for (const option of options) {
      newOptions.push({ [this.optionLabel]: option, value: option });
    }
    return newOptions;
  }

  public get showErrorMessage() {
    return this.required && this.value < 0 && this.dirty;
  }

  @Watch('options') private optionsChange(options: IVSelect[] | string[]) {
    this.optionsCheck(options);
  }

  @Watch('open') private makeFieldDirty(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue && newValue === false) {
      this.dirty = true;
    }
  }

  @Watch('value') private onValueChanged() {
    this.hoveredIndex = this.value;
    this.scrollToIndex = this.value;
    this.$emit('inputvalue', this.formattedOptions[this.currentValue].value); // SIMPLYFLY form LEGACY CODE (should be fixed)
  }

  private optionsCheck(options: string[] | IVSelect[]) {
    if (typeof options[0] === 'string') {
      this.createOptions(options as string[]);
      this.formattedOptions = this.createOptions(options as string[]);
    } else if (typeof options[0] === 'object') {
      this.formattedOptions = options as IVSelect[];
    }
  }

  public toggleDropdown(): void {
    if (!this.open) {
      this.$emit('select-opened');
    }

    if (!this.disabled || !this.loading || !this.skeleton) {
      this.open = !this.open;
      this.$emit('open', this.open);
      if (this.open) {
        this.$refs.secretinput?.focus();
      }
    }
  }

  public closeDropdown(): void {
    this.hoveredIndex = this.selectedIndex >= 0 ? this.selectedIndex : -1;
    this.scrollToIndex = this.hoveredIndex;
    this.open = false;
    this.resetSearch();
  }

  private emitIsValid(valid: boolean) {
    this.$emit('valid', valid);
  }

  public selectedInputLabelValue(value: number, defaultOptionLabel: string) {
    if (this.formattedOptions[value]) {
      const hideValueWhenSelected = this.formattedOptions[value]?.hideValueWhenSelected ?? false;
      if (!hideValueWhenSelected) {
        return this.formattedOptions[value][defaultOptionLabel];
      }
    }

    return '';
  }

  private mounted() {
    if (this.options.length > 0) {
      this.optionsCheck(this.options);
    }
    if (!this.required) {
      this.emitIsValid(true);
    }
    if (this.required && this.value <= 0) {
      this.emitIsValid(false);
    }
  }
}
</script>
