<template>
  <div class="l-content-layout">
    <Header>
      <template #brandLogo>
        <HeaderLogo class="content-header-logo" route-name="main" />
      </template>
      <template #navigationToggle>
        <ContentNavigationToggle />
      </template>
      <template #navigation>
        <ContentNavigation />
      </template>
    </Header>

    <transition name="content-transition">
      <div id="content-main-content" class="l-content-main-content l-app-container">
        <div class="l-content-main-content-inner">
          <transition
            name="custom-classes-transition"
            leave-active-class="page-transition-animated fadeOutTranslateUp"
            mode="out-in"
          >
            <router-view name="contentRoute" class="view router-view"></router-view>
          </transition>
        </div>
      </div>
    </transition>
    <Footer darkmode>
      <template #legal>
        <span v-html="textK('UI_COMMON_FOOTER')"></span>
      </template>
    </Footer>
  </div>
</template>

<style src="./content-layout.scss" lang="scss"></style>

<script lang="ts">
import ContentNavigationToggle from '@/src/content/components/content-navigation/content-navigation-toggle/content-navigation-toggle.vue';
import ContentNavigation from '@/src/content/components/content-navigation/content-navigation.vue';
import Footer from '@/src/core/components/footer/footer.vue';
import Header from '@/src/core/components/header/header.vue';
import HeaderLogo from '@/src/core/components/header/satair-header-logo/header-logo.vue';
import useText from '@/src/core/hooks/useText';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    Header,
    ContentNavigationToggle,
    HeaderLogo,
    ContentNavigation,
    Footer,
  },
})
export default class ContentLayout extends Vue {
  public textK = useText();
}
</script>
