<template v-if="productAdditionalData">
  <li class="product-details-list-item" v-if="hasMaterialStatus">
    <div class="product-details-list-item-content">
      <p class="product-details-list-item-title product-details-title-label">
        {{ textK('UI_PRODUCT_MATERIAL_STATUS_INFO') }}
      </p>
      <p class="product-details-list-item-value">
        <a
          class="material-status-link"
          @click="
            openModal({
              modalComponent: 'ModalMaterialStatus',
              params: { airbusCrossPlantMaterialStatus },
            })
          "
        >
          {{ textK('UI_PRODUCT_MATERIAL_STATUS_INFO_LINK_TEXT') }}
        </a>
      </p>
    </div>
  </li>
</template>

<script lang="ts" setup>
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { hasNonSellableMaterialStatus } from '@/src/market/services/material-status';
import {
  isAirbus,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import { computed } from 'vue';

interface Props {
  productId: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const openModal = useModal();

const productAdditionalData = computed(() =>
  productAdditionalDataService(props.productId, props.owningSystem),
);

const hasMaterialStatus = computed(() => {
  if (!props.productId) return false;
  if (isAirbus(productAdditionalData.value)) {
    return hasNonSellableMaterialStatus(productAdditionalData.value);
  }
  return false;
});

const airbusCrossPlantMaterialStatus = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return productAdditionalData.value.productData?.airbusCrossPlantMaterialStatus?.code || '';
  }
  return '';
});
</script>
