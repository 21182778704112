import {
  AirbusPart,
  IProductAdditionalData,
  IProductPriceEntry,
  Procurement,
  ProductAirbusPart,
} from '@/src/core/types/interfaces';
import { formatBasePrice, formatPriceUnit } from '@/src/market/services/airbus-parts';
import { isAirbus, isSatair } from '@/src/market/services/product-parts';

export const discountEndDateAvailable = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement,
) => {
  if (isSatair(productData)) {
    return productData.DiscountEndDateAvailable;
  }

  if (isAirbus(productData)) {
    return Boolean(procurement?.discountEndDate);
  }

  return false;
};

export const discountEndDate = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement,
) => {
  if (isSatair(productData)) {
    return productData.DiscountEndDate;
  }

  if (isAirbus(productData)) {
    return procurement?.discountEndDate ?? '';
  }

  return '';
};

export const formatDiscountPercentage = (discountPercentage: string) =>
  `${parseFloat(discountPercentage)}%`;

export const originalPrice = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement,
) => {
  if (isSatair(productData)) {
    return productData.OriginalPrice.FormattedValue;
  }

  if (isAirbus(productData)) {
    return procurement?.beforeDiscountPrice?.formattedValue ?? '';
  }

  return '';
};

export const discountPercentageFormatted = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement,
) => {
  if (isSatair(productData)) {
    return productData.DiscountPercentage ? `${productData.DiscountPercentage}%` : '';
  }

  if (isAirbus(productData)) {
    return procurement?.discountPercentage
      ? formatDiscountPercentage(procurement.discountPercentage)
      : '';
  }

  return '';
};

/**
 * Handles the price unit for a product based on the given additional product information and procurement.
 *
 * @module priceUnitHandler
 * @exports priceUnitHandler
 * @param {ProductAirbusPart|IProductAdditionalData|null|undefined} additionalProductInfo - The additional product information.
 * @param {Procurement} [procurement] - The procurement information, which is optional.
 *
 * @returns {string} The SAP code of the price unit if the additionalProductInfo is either of type 'ProductAirbusPart' or 'IProductAdditionalData'.
 *
 * @throws Will throw an error if the additionalProductInfo is of invalid type.
 */
export const priceUnitHandler = (
  additionalProductInfo: ProductAirbusPart | IProductAdditionalData | null | undefined,
  procurement?: Procurement,
  productPriceData?: IProductPriceEntry,
) => {
  if (isSatair(additionalProductInfo)) {
    return (
      additionalProductInfo.PriceUnit?.Code ?? productPriceData?.ProductPriceInfo?.PriceUnit?.Code
    );
  }

  if (isAirbus(additionalProductInfo)) {
    const priceUnit = formatPriceUnit(procurement?.priceUnit);
    return priceUnit?.SapCode;
  }

  return '';
};

/**
 * Handles the base price for a product based on the given additional product information and procurement.
 *
 * @module basePriceHandler
 * @exports basePriceHandler
 * @param {ProductAirbusPart|IProductAdditionalData|null|undefined} additionalProductInfo - The additional product information.
 * @param {Procurement} [procurement] - The procurement information, which is optional.
 *
 * @returns {number|string|null} The base price of the product.
 *
 * @throws Will throw an error if the additionalProductInfo is of invalid type.
 */
export const basePriceHandler = (
  additionalProductInfo: ProductAirbusPart | IProductAdditionalData | null | undefined,
  procurement?: Procurement,
  productPriceData?: IProductPriceEntry,
) => {
  if (isSatair(additionalProductInfo)) {
    return productPriceData?.ProductPriceInfo?.BasePrice;
  }

  if (isAirbus(additionalProductInfo) && procurement?.basePrice) {
    return formatBasePrice(procurement?.basePrice);
  }

  return null;
};
