const targetComponents: object = {
  TargetAlternateGetAccessBanner: () =>
    import(
      '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-alternate-get-access-banner/target-alternate-get-access-banner.vue'
    ),
  TargetSmallImageCampaign: () =>
    import(
      '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-small-image-campaign/target-small-image-campaign.vue'
    ),
  TargetProductLists: () =>
    import(
      '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-product-lists/target-product-lists.vue'
    ),
  TargetUmbracoComponent: () =>
    import(
      '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-umbraco-component/target-umbraco-component.vue'
    ),
};

export default targetComponents;
