import { useUserStore } from '@/src/profile/stores/user';

export const isBlueCustomer = (): boolean => {
  return useUserStore().blueCustomer;
};

export const isRedCustomer = (): boolean => {
  return useUserStore().redCustomer;
};

export const isMixedCustomer = (): boolean => {
  return useUserStore().mixedCustomer;
};

export const redB2BUnit = () => {
  return useUserStore().redB2BUnit;
};

export const blueB2BUnit = () => {
  return useUserStore().blueB2BUnit;
};
