<template>
  <div class="faq">
    <FaqHeader>
      <FaqSearch></FaqSearch>
      <FaqCategories></FaqCategories>
    </FaqHeader>
    <div class="faq-content">
      <FaqLoader></FaqLoader>
    </div>
  </div>
</template>

<style src="./faq-layout.scss" lang="scss"></style>

<script lang="ts">
import FaqCategories from '@/src/content/components/faq/faq-categories/faq-categories.vue';
import FaqHeader from '@/src/content/components/faq/faq-header/faq-header.vue';
import FaqLoader from '@/src/content/components/faq/faq-loader/faq-loader.vue';
import FaqSearch from '@/src/content/components/faq/faq-search/faq-search.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    FaqCategories,
    FaqHeader,
    FaqLoader,
    FaqSearch,
  },
})
export default class FaqLayout extends Vue {}
</script>
