<template>
  <span class="tooltip-outer-wrapper">
    <span v-if="!disabled" class="tooltip" @mouseleave="onOut()">
      <span
        class="tooltip__trigger"
        ref="trigger"
        @mouseenter="onOver()"
        @focus="onOver()"
        @blur="onOut()"
        @click.stop="onClick()"
        @mouseleave="onClickOutside()"
      >
        <slot />
      </span>
      <portal to="main-root">
        <span
          v-if="isShown"
          @click.stop="hide()"
          @touchstart.stop
          class="tooltip__content"
          :class="{
            'tooltip__content--show': isVisShown,
            'tooltip__content--white': whiteTooltipPopup,
          }"
          data-show
          ref="content"
          role="tooltip"
          v-click-outside="onClickOutside"
        >
          <slot name="tooltip-content" />
        </span>
      </portal>
    </span>
    <template v-else>
      <slot />
    </template>
  </span>
</template>

<style lang="scss" src="./tooltip.scss" scoped />
<script lang="ts" setup>
import { TooltipDefaultPosition, TooltipInteraction } from '@/src/core/types/interfaces';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';
import { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core/lib/popper';
import { onBeforeUnmount, onMounted, ref } from 'vue';

interface Props {
  position?: TooltipDefaultPosition;
  interaction?: TooltipInteraction;
  disabled?: boolean;
  subtle?: boolean;
  whiteTooltipPopup?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  position: TooltipDefaultPosition.Bottom,
  interaction: TooltipInteraction.Hover,
  disabled: false,
  subtle: false,
  whiteTooltipPopup: false,
});

const trigger = ref<HTMLElement | null>(null);
const content = ref<HTMLElement | null>(null);
const arrow = ref<HTMLElement | null>(null);
const mutation = ref<MutationObserver>();
const popperInstance = ref<Instance>();
const isShown = ref(false);
const isVisShown = ref(false);
const subtleTimer = ref<number>();

const onOver = () => {
  clearTimeout(subtleTimer.value);

  subtleTimer.value = window.setTimeout(
    () => {
      if (props.interaction === TooltipInteraction.Hover && WhatInputHandler.hasMouse()) {
        show();
      }
    },
    props.subtle ? 300 : 0,
  );
};

const onOut = () => {
  if (props.interaction === TooltipInteraction.Hover && WhatInputHandler.hasMouse()) {
    hide();
  }
};

const onClick = () => {
  if (props.interaction === TooltipInteraction.Click || WhatInputHandler.hasTouch()) {
    show();
  }
};

const onClickOutside = () => {
  if (
    props.interaction !== TooltipInteraction.Click ||
    !WhatInputHandler.hasTouch() ||
    !WhatInputHandler.hasMouse()
  ) {
    hide();
  }
};

const show = () => {
  if (!props.disabled) {
    isShown.value = true;
    setTimeout(() => {
      createPopperInstance();
      isVisShown.value = true;
    }, 300);
  }
};

const hide = () => {
  clearTimeout(subtleTimer.value);
  isShown.value = false;
  isVisShown.value = false;
  mutation.value?.disconnect();

  if (popperInstance.value) {
    popperInstance.value.destroy();
    popperInstance.value = undefined;
  }
};

const createPopperInstance = () => {
  if (trigger.value && content.value) {
    popperInstance.value = createPopper(trigger.value, content.value, {
      placement: props.position,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 15,
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrow.value,
            padding: 0,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ],
    });

    mutation.value?.observe(content.value, {
      attributes: false,
      childList: false,
      characterData: true,
      subtree: true,
    });
  }
};

onMounted(() => {
  mutation.value = new MutationObserver(() => {
    popperInstance.value?.update();
  });
});

onBeforeUnmount(() => {
  hide();
  mutation.value?.disconnect();
});
</script>
