// tslint:disable
import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class SellerApi {
  public static SellerDetails = (sellerId: string) => `${baseUrl}/market/seller/${sellerId}`;
  public static FetchDeliveryAddresses = (sellerId: string) =>
    `${baseUrl}/api/seller/${sellerId}/shippingaddresses`;
  public static FetchMastershopById = (sellerId: string) => `${baseUrl}/api/mastershop/${sellerId}`;
  public static FetchShopById = (shopId: string) => `${baseUrl}/api/seller/${shopId}/shop`;
}
