import { isDevelopment } from '@/src/core/services/environment';
import { microcopiesFromDisk, microcopiesFromUmraco } from '@/src/core/services/text-key';
import { ITextItem } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UmbracoApi } from '../api';
import { Req } from '../services/requester';

export const useTextsStore = defineStore('texts', () => {
  const status = ref<string>('');
  const texts = ref<ITextItem>({});
  const isBusy = ref<boolean>(false);
  const workAreas = ref<string[]>([]);
  const timeZones = ref<string[]>([]);
  const countries = ref<string[]>([]);

  const workAreasUpdated = (payload: { WorkAreas: string[] }) => {
    workAreas.value = [];
    workAreas.value = payload.WorkAreas;
    Object.freeze(workAreas.value);
  };

  const timeZonesUpdated = (payload: { TimeZones: string[] }) => {
    timeZones.value = [];
    timeZones.value = payload.TimeZones;
    Object.freeze(timeZones.value);
  };

  const countriesUpdated = (payload: { Countries: string[] }) => {
    countries.value = [];
    countries.value = payload.Countries;
    Object.freeze(countries.value);
  };

  const updateTexts = (payload: { texts: ITextItem }) => {
    texts.value = payload.texts;
    Object.freeze(texts.value);
  };

  const statusUpdated = (payload: { status: string }) => {
    status.value = payload.status;
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const fetchCountries = async () => {
    const { Data, IsSuccess } = await Req({
      url: UmbracoApi.getCountries,
    });

    if (IsSuccess) {
      countriesUpdated({ Countries: Data });
    }
    return { IsSuccess };
  };

  const fetchTimeZones = async () => {
    const { Data, IsSuccess } = await Req({
      url: UmbracoApi.getTimeZones,
    });

    if (IsSuccess) {
      timeZonesUpdated({ TimeZones: Data });
    }

    return { IsSuccess };
  };

  const fetchWorkAreas = async () => {
    const { Data, IsSuccess } = await Req({
      url: UmbracoApi.getWorkAreas,
    });

    if (IsSuccess) {
      workAreasUpdated({ WorkAreas: Data });
    }

    return { IsSuccess };
  };

  const fetchTexts = async () => {
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess, Data } = await (isDevelopment()
      ? microcopiesFromDisk()
      : microcopiesFromUmraco());

    if (IsSuccess) {
      updateTexts({ texts: Data });
      statusUpdated({ status: 'COMPLETE' });
    } else {
      statusUpdated({ status: 'FAILED' });
    }
    isBusyUpdated({ IsBusy: false });
  };

  const setTexts = (payload: { texts: ITextItem }) => {
    updateTexts({ texts: payload.texts });
    statusUpdated({ status: 'COMPLETE' });
  };

  return {
    texts,
    workAreas,
    timeZones,
    countries,
    isBusy,
    status,
    fetchCountries,
    fetchTexts,
    fetchTimeZones,
    fetchWorkAreas,
    setTexts,
  };
});
