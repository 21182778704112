<template>
  <Component :is="component" />
</template>

<script lang="ts">
import ContentCtaBoxModule from '@/src/content/components/content-modules/cta-box-module/cta-box-module.vue';
import ContentLinkBoxesModule from '@/src/content/components/content-modules/link-boxes-module/link-boxes.vue';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import BlogInformation from '@/src/core/components/ui/blog-information/blog-information.vue';
import BlogTag from '@/src/core/components/ui/blog-information/blog-tag/blog-tag.vue';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ContentLinkBoxesModule,
    ContentCtaBoxModule,
    MediaHandler,
    ArrowIcon,
    InViewWatcher,
  },
})
export default class BlogPostItem extends Vue {
  @Prop() private blogPostContent: HTMLDivElement;
  @Prop() private keyName: number;

  public component: string | null = null;

  private get content(): string {
    return this.blogPostContent.outerHTML.toString();
  }

  private createComponent() {
    const name: string = 'blogItem' + this.keyName;
    Vue.component(name, {
      template: this.content,
      components: {
        ContentLinkBoxesModule,
        ContentCtaBoxModule,
        MediaHandler,
        ArrowIcon,
        BlogTag,
        BlogInformation,
      },
    });

    this.component = name;
    this.$forceUpdate();
  }

  public async mounted() {
    await this.createComponent();
  }
}
</script>
