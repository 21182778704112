import { useUIStore } from '@/src/core/stores/ui';
import { Route } from 'vue-router';

export default (to: Route, from: Route, next: () => void) => {
  const uiStore = useUIStore();

  if (from.name) {
    if (from.fullPath.includes('market')) {
      uiStore.setlastMarketRoute({ lastRoute: from.path });
    } else {
      uiStore.setlastMarketRoute({ lastRoute: '' });
    }
  }
  next();
};
