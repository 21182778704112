<template>
  <div class="product__detail">
    <template v-if="isAuthenticated">
      <transition name="fade" mode="out-in">
        <div class="price-wrapper" v-if="hasPriceData">
          <p v-if="ruleStatusOkAndBasePriceGreaterThanZero" class="product__detail__label">
            <span v-if="showLeasePrice">From</span>
            <span v-ui-test="'price-specification'">{{ priceUnitLabel }}</span>
            <Tooltip v-if="showLeasePrice" :position="TooltipDefaultPosition.Top">
              <span class="banner">
                <svgicon name="ui-info" />
              </span>
              <template #tooltip-content>
                {{ minimumLeasePeriodText }}
              </template>
            </Tooltip>
            <ProductUnitConversion :product="product" />
          </p>
          <div v-if="isExhangePart" class="price-section price-details">
            <a @click.stop="openExchangePartPriceDetails($event)">{{
              textK('UI_PRODUCT_PRICE_DETAILS')
            }}</a>
          </div>
          <div v-else-if="isRepairPart" class="price-section price-details">
            <a @click.stop="openRepairPartPriceDetails($event)">{{
              textK('UI_PRODUCT_PRICE_DETAILS')
            }}</a>
          </div>
          <div v-else-if="!ruleStatusOkAndBasePriceGreaterThanZero" class="price-section">
            <span v-ui-test="'price-specification'" v-html="productStatusMessage"></span>
          </div>
          <template v-else>
            <!-- Base Price -->
            <template v-if="!discountPriceAvailable">
              <div v-html="price(offerPrice || '')" v-ui-test="'unit-price-label'"></div>
              <a
                v-if="showLeasePrice"
                @click.stop="openLeasePriceDetails($event)"
                class="link see-price-details"
                >{{ textK('UI_PRODUCT_PRICE_DETAILS') }}</a
              >
            </template>

            <!-- End Base Price -->

            <div v-else class="sale-price product-price">
              <span class="sale-price__discounted">
                <div v-html="price(formattedPriceValue)" v-ui-test="'unit-price-label'"></div>
              </span>
              <span class="sale-price__original">
                <div v-html="price(originalPriceComputed)" v-ui-test="'unit-price-label'"></div>
              </span>
            </div>

            <div v-if="addToBasketQty > 1 || packageQuantity > 1" class="order-qty-wrapper">
              <Tooltip v-if="addToBasketQty > 1" class="order-qty-wrapper__minmax-qty">
                MOQ: <span v-ui-test="'moq-amount'">{{ addToBasketQty }}</span>
                <template #tooltip-content>
                  {{ textK('UI_COMMON_MIN_QUANTITY') }}
                </template>
              </Tooltip>
              <Tooltip v-if="packageQuantity > 1" class="order-qty-wrapper__minmax-qty">
                SPQ: <span v-ui-test="'spq-amount'">{{ packageQuantity }}</span>
                <template #tooltip-content>
                  {{ textK('UI_COMMON_SPQ_QUANTITY') }}
                </template>
              </Tooltip>
            </div>

            <div v-if="discountPriceAvailable" class="sale-price-wrapper">
              <div class="sale-price-banner">
                <Tooltip :class="{ tooltip: true, 'no-tooltip': !hasDiscountEndDate }">
                  <span class="sale-price"
                    >{{ textK('UI_COMMON_SAVE') }} {{ discountPercentage }}
                    <span class="tooltip__icon"> <svgicon name="ui-info" /> </span
                  ></span>
                  <template #tooltip-content>
                    {{ textK('UI_PRODUCT_SALE_END_DATE') }} {{ date(discountEndDateComputed) }}
                  </template>
                </Tooltip>
              </div>
            </div>
          </template>
          <ProductVolumeDiscounts
            :id="product.Id"
            :is-product-card="true"
            :owning-system="product.OwningSystem"
          />
          <template v-if="ruleStatusOKAndHasNoBasePrice && isBlueOwningSystem">
            <div v-if="addToBasketQty > 1 || packageQuantity > 1" class="order-qty-wrapper">
              <Tooltip v-if="addToBasketQty > 1" class="order-qty-wrapper__minmax-qty">
                MOQ: {{ addToBasketQty }}
                <template #tooltip-content>
                  {{ textK('UI_COMMON_MIN_QUANTITY') }}
                </template>
              </Tooltip>
              <Tooltip v-if="packageQuantity > 1" class="order-qty-wrapper__minmax-qty">
                SPQ: {{ packageQuantity }}
                <template #tooltip-content>
                  {{ textK('UI_COMMON_SPQ_QUANTITY') }}
                </template>
              </Tooltip>
            </div>
          </template>
          <ProductInfoBanner v-if="isZtel" :show-tooltip="true" :type="uiVariant.Primary">
            <template #banner-text>
              <span class="product__ztl">{{ textK('UI_PRODUCT_ZTEL') }}</span>
            </template>
            <template #tooltip-text>
              <span v-ui-test="'ztal-tooltip'">{{ textK('UI_PRODUCT_ZTEL_DESCRIPTION') }}</span>
            </template>
          </ProductInfoBanner>
        </div>
        <SkeletonLoader v-else :size="UiSize.Auto" />
      </transition>
    </template>
  </div>
</template>

<style lang="scss" src="./product-price.scss" scoped></style>

<script lang="ts" setup>
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import usePrice from '@/src/core/hooks/usePrice';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { CartEntry, OwningSystem, PriceBreakInfo, Product } from '@/src/core/types/api';
import {
  IProductPriceEntry,
  Procurement,
  ProductAirbusPart,
  TooltipDefaultPosition,
} from '@/src/core/types/interfaces';
import { MaterialStatus } from '@/src/core/types/material-status';
import { DateFormat } from '@/src/core/utils/dates';
import { formatPrice } from '@/src/core/utils/math';
import ProductInfoBanner from '@/src/market/components/product/product-info-banner/product-info-banner.vue';
import ProductUnitConversion from '@/src/market/components/product/product-unit-conversion/product-unit-conversion.vue';
import ProductVolumeDiscounts from '@/src/market/components/product/product-volume-discounts/product-volume-discounts.vue';
import {
  formatPriceBreakInfo,
  hasAirbusCrossPlantMaterialStatus,
  hasBasePrice,
} from '@/src/market/services/airbus-parts';
import { getCurrentPriceBreakIndex } from '@/src/market/services/price-service';
import { isExchangeType } from '@/src/market/services/procurements';
import {
  isAirbus,
  isSatair,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import {
  basePriceHandler,
  discountEndDate,
  discountEndDateAvailable,
  discountPercentageFormatted,
  originalPrice,
  priceUnitHandler,
} from '@/src/market/services/product-parts-prices';
import {
  crossPlantMaterialOutOfStock,
  hasNoPrice,
  isBlueOwningSystem as isBlueOwningSystemService,
  isPriceVisible,
  isProductZtel,
  isRestricted,
  limitedByCrossPlantMaterialStatus,
  materialStatus,
} from '@/src/market/services/product-rules';
import { useCartStore } from '@/src/market/stores/cart';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { computed } from 'vue';

interface Props {
  product: Product;
  procurementType?: ProcurementType;
  procurement?: Procurement;
  activeTabLabel?: ProcurementType;
}

const props = defineProps<Props>();

const textK = useText();
const openModal = useModal();
const date = DateFormat;
const price = usePrice;
const authenticationStore = useAuthenticationStore();
const cartStore = useCartStore();
const productPriceInfoStore = useProductPriceInfoStore();
const uiVariant = UiVariant;

const productAdditionalData = computed(() =>
  productAdditionalDataService(props.product.Id, props.product.OwningSystem),
);

const productPriceData = computed<IProductPriceEntry>(() =>
  productPriceInfoStore.priceDataByOfferId(props.product?.Id || ''),
);

const hasPriceData = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return Boolean(productPriceData.value && productAdditionalData.value);
  }
  return Boolean(productAdditionalData.value);
});

const basePrice = computed(() =>
  basePriceHandler(productAdditionalData.value, props.procurement, productPriceData.value),
);

const originalPriceComputed = computed(() =>
  originalPrice(productAdditionalData.value, props.procurement),
);

const formattedPriceValue = computed(() => basePrice.value?.FormattedValue ?? '');

const discountEndDateComputed = computed(() =>
  discountEndDate(productAdditionalData.value, props.procurement),
);

const addToBasketQty = computed(() => props.product?.AddToBasketQty ?? 0);

const packageQuantity = computed(() => props.product?.PackageQuantity ?? 0);

const discountPriceAvailable = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productPriceData.value?.DiscountPriceAvailable;
  }

  if (isAirbus(productAdditionalData.value)) {
    const hasBeforeDiscountPrice = Boolean(props.procurement?.beforeDiscountPrice?.value);
    return hasBeforeDiscountPrice;
  }

  return false;
});

const discountPercentage = computed(() =>
  discountPercentageFormatted(productAdditionalData.value, props.procurement),
);

const ruleStatusOK = computed(() => isPriceVisible(productAdditionalData.value, props.procurement));

const ruleStatusNoPrice = computed(() => {
  if (isBlueOwningSystem.value && !hasBasePrice(props.procurement)) {
    return true;
  }
  return hasNoPrice(productAdditionalData.value, props.procurement);
});

const ruleStatusCPMS = computed(() =>
  limitedByCrossPlantMaterialStatus(productAdditionalData.value, props.procurement),
);

const ruleStatusCPMS30 = computed(() =>
  crossPlantMaterialOutOfStock(productAdditionalData.value, props.procurement),
);

const ruleStatusRestricted = computed(() =>
  isRestricted(productAdditionalData.value, props.procurement),
);

const ruleStatusOkAndBasePriceGreaterThanZero = computed(() => {
  if (isLeaseProcurement.value) {
    return ruleStatusOK.value && props.procurement?.leaseInfo?.minimumLease?.subtotal;
  }
  return ruleStatusOK.value && basePrice.value?.Value && +basePrice.value?.Value > 0;
});

const ruleStatusOKAndHasNoBasePrice = computed(() => {
  const basePriceValue = basePrice.value?.Value ?? 0;
  return +basePriceValue <= 0 && ruleStatusOK.value;
});

const productStatusMessage = computed(() => {
  /**
   * The `productStatusMessage` getter function generates a status message for a product based on a set of predefined rules.
   * Each rule in the `rules` object has a condition and a corresponding message. The condition checks specific properties of the product.
   * Add Your condition and the text message you want to display to the user.
   */
  const rules = {
    RULE_STATUS_ZERO: {
      condition: materialStatusZero.value,
      text: textK('UI_PRODUCT_AIRBUS_MATERIAL_STATUS_ZERO'),
    },
    RULE_STATUS_NO_PRICE: {
      condition: ruleStatusNoPrice.value && !isBlueOwningSystem.value,
      text: props.product.IsMarketplace
        ? textK('UI_PRODUCT_NOPRICE_MARKETPLACE')
        : materialStatusE.value
        ? textK('UI_PRODUCT_AIRBUS_MATERIAL_STATUS_E')
        : textK('UI_PRODUCT_NOPRICE'),
    },
    RULE_STATUS_NO_PRICE_AIRBUS: {
      condition: ruleStatusNoPrice.value && isBlueOwningSystem.value,
      text: materialStatusE.value
        ? textK('UI_PRODUCT_AIRBUS_MATERIAL_STATUS_E')
        : textK('UI_COMMON_AIRBUS_NO_PRICE'),
    },
    RULE_STATUS_CPMS30: {
      condition: ruleStatusCPMS30.value,
      text: textK('UI_PRODUCT_ITEMONHOLD'),
    },
    RULE_STATUS_RESTRICTED: {
      condition: ruleStatusRestricted.value,
      text: textK('UI_PRODUCT_RESTRICTED_TEXT'),
    },
    RULE_STATUS_CMPS: {
      condition: ruleStatusCPMS.value && !hasAirbusCrossPlantMaterialStatusCode.value,
      text: textK('UI_PRODUCT_OUTOFPRODUCTION'),
    },
  };
  for (const rule in rules) {
    if (rules[rule].condition) {
      return rules[rule].text;
    }
  }

  return null;
});

const isZtel = computed(() => isProductZtel(props.product));

const hasPriceBreaks = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productPriceData.value.ProductPriceInfo?.PriceBreakInfos.length > 1;
  }

  if (isAirbus(productAdditionalData.value)) {
    return props.procurement?.priceBreakInfos && props.procurement.priceBreakInfos.length > 0;
  }

  return false;
});

const cartEntry = computed<CartEntry>(() => cartStore.getCartEntry(props.product.Id));

const cartQty = computed(() => cartEntry.value?.Quantity || 0);

const offerPrice = computed(() => {
  if (showLeasePrice.value) {
    return formatPrice(props.procurement?.leaseInfo?.minimumLease?.subtotal || '0');
  }

  const basePriceFormattedValue = basePrice.value?.FormattedValue;
  if (hasPriceBreaks.value && cartQty.value !== 0) {
    return currentVolumeDiscount.value?.BasePrice.FormattedValue ?? basePriceFormattedValue;
  }

  return basePriceFormattedValue;
});

const volumePrices = computed<PriceBreakInfo[]>(() => {
  if (isSatair(productAdditionalData.value)) {
    return productPriceData.value?.ProductPriceInfo?.PriceBreakInfos || [];
  }

  if (isAirbus(productAdditionalData.value)) {
    return formatPriceBreakInfo(props.procurement);
  }

  return [];
});

const currentVolumeDiscountIndex = computed(() =>
  getCurrentPriceBreakIndex(volumePrices.value, cartQty.value),
);

const currentVolumeDiscount = computed(
  () => volumePrices.value[currentVolumeDiscountIndex.value] || undefined,
);

const currentISO = computed(() => basePrice.value?.CurrencyIso);

const priceUnit = computed(() =>
  priceUnitHandler(productAdditionalData.value, props.procurement, productPriceData.value),
);

const priceUnitLabel = computed(() => {
  if (isLeaseProcurement.value && props.procurement?.leaseInfo?.currency) {
    return `${props.procurement.leaseInfo?.currency}/${priceUnit.value}`;
  }
  return `${currentISO.value}/${priceUnit.value}`;
});

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const isBlueOwningSystem = computed(() =>
  isBlueOwningSystemService(props.product.OwningSystem as OwningSystem),
);

const materialStatusE = computed(() =>
  materialStatus(MaterialStatus.STATUS_E, productAdditionalData.value),
);

const materialStatusZero = computed(() =>
  materialStatus(MaterialStatus.STATUS_ZERO, productAdditionalData.value),
);

const hasAirbusCrossPlantMaterialStatusCode = computed(() => {
  if (isBlueOwningSystem.value) {
    return hasAirbusCrossPlantMaterialStatus(productAdditionalData.value as ProductAirbusPart);
  }
  return false;
});

const hasDiscountEndDate = computed(() =>
  discountEndDateAvailable(productAdditionalData.value, props.procurement),
);

const isTool = computed(() => props.product.IsTool);

const isLeaseProcurement = computed(() => props.activeTabLabel === ProcurementType.Lease);

const showLeasePrice = computed(
  () => isLeaseProcurement.value && (isTool.value || isAirbusPropriety.value),
);

const isAirbusPropriety = computed(() => props.product.AirbusProprietary);

const isExhangePart = computed(() => {
  if (!props.procurement?.procurementType) {
    return false;
  }

  return isExchangeType(props.procurement?.procurementType) && isAirbusPropriety.value;
});

const isRepairPart = computed(() => {
  if (!props.procurement?.procurementType) {
    return false;
  }

  return props.procurement.procurementType === ProcurementType.Repair && isAirbusPropriety.value;
});

const openLeasePriceDetails = ($event: Event) => {
  $event.stopPropagation();
  openModal({
    modalComponent: 'ModalLeasePrice',
    params: {
      procurement: props.procurement,
      product: props.product,
    },
  });
};

const minimumLeasePeriodText = computed(() => {
  if (props.product?.AirbusProprietary) {
    return TextKey('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_PARTS');
  }
  return TextKey('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_TOOL').replace(
    /\[\[minimum-lease-charge-percent\]\]/gi,
    props.procurement?.leaseInfo?.minimumLease.percentagePerDay as string,
  );
});

const openExchangePartPriceDetails = ($event: Event) => {
  $event.stopPropagation();
  openModal({
    modalComponent: 'ModalAirbusProprietaryPart',
    params: {
      key: 'AirbusProprietaryExchangePartsPriceDetails_ModalUrl',
      title: 'AirbusProprietaryExchangePartsPriceDetails_Name',
    },
  });
};

const openRepairPartPriceDetails = ($event: Event) => {
  $event.stopPropagation();
  openModal({
    modalComponent: 'ModalAirbusProprietaryPart',
    params: {
      key: 'AirbusProprietaryRepairPartsPriceDetails_ModalUrl',
      title: 'AirbusProprietaryRepairPartsPriceDetails_Name',
    },
  });
};
</script>
