<template>
  <div class="content-wrapper">
    <transition
      name="custom-classes-transition"
      @enter="resetScrollPos"
      @after-enter="checkForAnchor"
      leave-active-class="page-transition-animated fadeOutTranslateUp"
      mode="out-in"
    >
      <Component :is="component"></Component>
    </transition>
    <Spinner :dark-theme="true" :has-overlay="true" v-if="isBusy"></Spinner>
  </div>
</template>

<style src="./content-loader.scss" lang="scss"></style>

<script lang="ts" setup>
import ContentAccordionModule from '@/src/content/components/content-modules/accordion-module/accordion-module.vue';
import AlphabeticalLinkList from '@/src/content/components/content-modules/alphabetical-link-list/alphabetical-link-list.vue';
import ContentArticleModule from '@/src/content/components/content-modules/article-module/article-module.vue';
import BlogContentWrapper from '@/src/content/components/content-modules/blog-content-wrapper/blog-content-wrapper.vue';
import BlogPostModule from '@/src/content/components/content-modules/blog-post-module/blog-post-module.vue';
import BlogFilters from '@/src/content/components/content-modules/blog-post-overview/blog-filters/blog-filters.vue';
import BlogPostOverview from '@/src/content/components/content-modules/blog-post-overview/blog-post-overview.vue';
import ContentBoxCtaModule from '@/src/content/components/content-modules/box-cta-module/box-cta-module.vue';
import ContentBoxListModule from '@/src/content/components/content-modules/box-list-module/box-list-module.vue';
import ContentBoxSpotModule from '@/src/content/components/content-modules/box-spot-module/box-spot-module.vue';
import ContentComparisonBoxesModule from '@/src/content/components/content-modules/comparison-boxes-module/comparison-boxes.vue';
import ContactFormModule from '@/src/content/components/content-modules/contact-form-module/contact-form-module.vue';
import ContactInfoModule from '@/src/content/components/content-modules/contact-info-module/contact-info-module.vue';
import ConvertCalculatorModule from '@/src/content/components/content-modules/convert-calculator-module/convert-calculator-module.vue';
import ContentCtaBoxModule from '@/src/content/components/content-modules/cta-box-module/cta-box-module.vue';
import DownloadListModule from '@/src/content/components/content-modules/download-list-module/download-list-module.vue';
import EventListModule from '@/src/content/components/content-modules/event-list-module/event-list-module.vue';
import ContentFullWidthMediaModule from '@/src/content/components/content-modules/full-width-element-module/full-width-element.vue';
import ContentHeroModule from '@/src/content/components/content-modules/hero-module/hero-module.vue';
import ContentImageBoxModule from '@/src/content/components/content-modules/image-box-module/image-box-module.vue';
import ContentLinkBoxesModule from '@/src/content/components/content-modules/link-boxes-module/link-boxes.vue';
import ContentLinkPortfolioModule from '@/src/content/components/content-modules/link-portfolio-module/link-portfolio.vue';
import LoginModule from '@/src/content/components/content-modules/login-module/login-module.vue';
import ContentNewsletterModule from '@/src/content/components/content-modules/newsletter-module/newsletter-module.vue';
import NotFoundModule from '@/src/content/components/content-modules/not-found-module/not-found-module.vue';
import ContentQuoteModule from '@/src/content/components/content-modules/quote-module/quote-module.vue';
import ContentTableModule from '@/src/content/components/content-modules/table-module/table-module.vue';
import ContentTextBoxesModule from '@/src/content/components/content-modules/textboxes-module/text-boxes.vue';
import TwentyThreeVideo from '@/src/content/components/content-modules/twenty-three-video/twenty-three-video.vue';
import ContentTypeformModule from '@/src/content/components/content-modules/typeform-module/typeform-module.vue';
import ContentModuleSection from '@/src/content/layouts/module-section/content-module-section.vue';
import { useContentStore } from '@/src/content/stores/content';
import AdobeTargetWrapper from '@/src/core/components/adobe-target-wrapper/adobe-target-wrapper.vue';
import Login from '@/src/core/components/login/login.vue';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import AccordionItem from '@/src/core/components/ui/accordion/accordion-item/accordion-item.vue';
import Accordion from '@/src/core/components/ui/accordion/accordion.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import ArrowLinkItem from '@/src/core/components/ui/arrow-link-wrapper/arrow-link-item/arrow-link-item.vue';
import ArrowLinkWrapper from '@/src/core/components/ui/arrow-link-wrapper/arrow-link-wrapper.vue';
import BackButton from '@/src/core/components/ui/back-button/back-button.vue';
import BlogInformation from '@/src/core/components/ui/blog-information/blog-information.vue';
import BlogTag from '@/src/core/components/ui/blog-information/blog-tag/blog-tag.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ContentButton from '@/src/core/components/ui/content-button/content-button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import Spinner from '@/src/core/components/ui/spinner/spinner.vue';
import FormHandler from '@/src/core/components/util/form-handler/form-handler.vue';
import { isProduction } from '@/src/core/services/environment';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { IWindowServerRendered } from '@/src/core/types/ui';
import Vue, {
  computed,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';

const contentStore = useContentStore();
const authenticationStore = useAuthenticationStore();
const uiStore = useUIStore();
const vm = getCurrentInstance()?.proxy;
const route = vm?.$route;

const component = ref<string | null>(null);
const site = Site;

const content = computed(() => {
  return contentStore.umbracoContent;
});

const isBusy = computed(() => {
  return contentStore.isBusy;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const resetScrollPos = () => {
  if (!route?.hash) {
    window.scrollTo(0, 0);
  }
};

const checkForAnchor = () => {
  nextTick(() => {
    if (route?.hash) {
      setTimeout(() => {
        window.scrollTo(0, document.querySelector(route.hash)?.getBoundingClientRect().top || 0);
      }, 50);
    }
  });
};

const createComponent = () => {
  const name = isProduction() ? 'ContentPage' : 'ContentPage' + ~~(Math.random() * 100000); // The Math.random() prevents caching locally

  Vue.component(name, {
    template: content.value,
    components: {
      Accordion,
      AccordionItem,
      AdobeTargetWrapper,
      ArrowIcon,
      ArrowLinkWrapper,
      ArrowLinkItem,
      ContentModuleSection,
      BackButton,
      BlogPostModule,
      BlogContentWrapper,
      BlogPostOverview,
      ContentComparisonBoxesModule,
      ContentBoxSpotModule,
      ContentLinkBoxesModule,
      ContentLinkPortfolioModule,
      TwentyThreeVideo,
      ContentArticleModule,
      ContentHeroModule,
      ContentCtaBoxModule,
      ContentBoxCtaModule,
      ContentImageBoxModule,
      ContentTextBoxesModule,
      ContentFullWidthMediaModule,
      ContentTableModule,
      ContentAccordionModule,
      ContentBoxListModule,
      ContentQuoteModule,
      ContentNewsletterModule,
      ContactFormModule,
      DownloadListModule,
      ContactInfoModule,
      EventListModule,
      FormHandler,
      LoginModule,
      MediaHandler,
      NotFoundModule,
      VButton, // SHOULD NOT BE USED ON CONTENT SITE?
      BlogInformation,
      BlogTag,
      ContentButton,
      VTextField,
      VTextarea,
      VSelect,
      Login,
      Checkbox,
      ContentTypeformModule,
      ConvertCalculatorModule,
      BlogFilters,
      AlphabeticalLinkList,
    },
  });

  component.value = name;
};

const createContent = () => {
  if (content.value) {
    createComponent();
  }

  const srContent: HTMLElement | undefined = (window as unknown as IWindowServerRendered).srContent;

  if (srContent) {
    contentStore.setUmbracoContent({
      umbracoContent: srContent.outerHTML,
      contentURL: srContent.getAttribute('sr-content') || '',
    });

    (window as unknown as IWindowServerRendered).srContent = undefined;
  } else {
    contentStore.fetchUmbracoContent({ contentURL: route?.fullPath || '' });
  }
};

watch(
  // TODO: Replace with useRoute.route after the Vue 3 update
  () => vm?.$route.fullPath,
  (to: string, from: string) => {
    if (uiStore.site === site.Content && to !== from) {
      contentStore.fetchUmbracoContent({ contentURL: vm?.$route.fullPath || '' });
    }
  },
  { deep: true, immediate: true },
);

watch(content, () => {
  if (!content.value) {
    return;
  }

  createComponent();
});

watch(isAuthenticated, () => {
  contentStore.fetchUmbracoContent({ contentURL: route?.fullPath || '', force: true });
});

onMounted(() => {
  createContent();
});

onBeforeUnmount(() => {
  contentStore.reset();
});
</script>
