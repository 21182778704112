<template>
  <div
    class="item-availability-wrapper"
    v-if="isAuthenticated"
    :class="{ 'item-availability-wrapper--loading': !productData || productData.IsBusy }"
  >
    <template v-if="isRestricted">
      <ProductAvailibiltyIndication :in-stock="false" :loading="false" :failed="true">
      </ProductAvailibiltyIndication>
      <span class="restricted-text">{{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}</span>
    </template>
    <template v-else>
      <ProductAvailibiltyIndication
        :in-stock="isInStock"
        :loading="!productData || productData.IsBusy"
        :with-lead-time="hasLeadTime"
      >
      </ProductAvailibiltyIndication>
      <transition name="fade">
        <span v-if="productData" class="item-availability-info">
          <span
            v-if="
              (hasUnknownDeliveryDate && !isBlueOwningSystemValue) ||
              (hasUnknownDeliveryDate && isBlueOwningSystemValue && !isInStock)
            "
          >
            {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
            <Tooltip class="unknown-delivery-tooltip">
              <span>
                <svgicon name="ui-info" />
              </span>
              <template #tooltip-content>
                {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
              </template>
            </Tooltip>
          </span>
          <span v-else>
            <span
              v-if="offerQuantity && offerQuantity > 0 && isInStock"
              class="item-availability-info__quantity"
              >{{ offerQuantity }}</span
            >
            <span>{{ stockIndication }}</span>
          </span>
        </span>
      </transition>
    </template>
  </div>
</template>

<style lang="scss" src="./product-availability.scss" scoped></style>

<script setup lang="ts">
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import { DateFormat } from '@/src/core/utils/dates';
import { useRoute } from '@/src/core/utils/router';
import ProductAvailibiltyIndication from '@/src/market/components/product/product-availibility-indication/product-availibility-indication.vue';
import { getAirbusPartAvailableStock } from '@/src/market/services/airbus-parts';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isAirbus, isSatair, productAdditionalData } from '@/src/market/services/product-parts';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { isBlueCustomer, isRedCustomer } from '@/src/market/services/user-service';
import { computed } from 'vue';

interface Props {
  id: string;
  owningSystem: string;
  forceFetch?: boolean;
  offerQuantity?: number;
  procurement?: Procurement;
  addToBasketQuantity?: number;
}

const props = withDefaults(defineProps<Props>(), {
  offerQuantity: 0,
});
const textK = useText();
const authenticationStore = useAuthenticationStore();

const productData = computed(() => {
  return productAdditionalData(props.id, props.owningSystem);
});

const procurementData = computed(() => {
  if (isAirbus(productData.value)) {
    if (props.procurement) {
      // search-item
      return props.procurement;
    } else {
      // PDP
      const { params } = useRoute();
      return getProcurementByRouteParams(params, productData.value.procurements);
    }
  }

  return null;
});

const isInStock = computed(() => {
  if (isSatair(productData.value)) {
    return productData.value.IsInStock;
  }

  if (isAirbus(productData.value) && procurementData.value) {
    const availableStock = getAirbusPartAvailableStock(procurementData.value);

    if (availableStock === 0 && procurementData.value.availabilityDate) {
      return false;
    }
    return availableStock > 0;
  }

  return false;
});

const stockIndication = computed(() => {
  if (isSatair(productData.value)) {
    return props.offerQuantity && props.offerQuantity > 0
      ? productData.value.StockIndication.toLowerCase()
      : productData.value.StockIndication;
  }

  if (isAirbus(productData.value)) {
    if (
      procurementData.value &&
      (procurementData.value.availableStock === 0 ||
        typeof procurementData.value.availableStock === 'undefined') &&
      // SAT-11137 missing availableStock should be handle the same as availabilityStock = 0
      procurementData.value.availabilityDate
    ) {
      return DateFormat(procurementData.value.availabilityDate);
    }

    return isInStock.value ? 'In stock' : 'N/A';
  }

  return 'N/A';
});

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const hasUnknownDeliveryDate = computed(() => {
  if (isSatair(productData.value)) {
    return productData.value.UnknownDeliveryDate;
  }

  if (isAirbus(productData.value)) {
    return Boolean(procurementData.value?.unknownDeliveryDate);
  }

  return false;
});

const hasLeadTime = computed(() => {
  if (isSatair(productData.value)) {
    return productData.value.StockIndication !== 'N/A';
  }

  if (isAirbus(productData.value)) {
    return (
      procurementData.value?.unknownDeliveryDate || Boolean(procurementData.value?.availabilityDate)
    );
  }

  return false;
});

const isBlueOwningSystemValue = computed<boolean>(() =>
  isBlueOwningSystem(props.owningSystem as OwningSystem),
);

const isRestricted = computed(() => {
  if (isBlueOwningSystemValue.value) {
    if (isBlueCustomer()) {
      return false;
    }
  } else {
    if (isRedCustomer()) {
      return false;
    }
  }
  return true;
});
</script>
