<template>
  <span
    class="search-item-specs__value-additional"
    v-if="isAuthenticated"
    :class="{ 'item-location-wrapper--loading': !productAdditionalData }"
  >
    <template v-if="showChangeWarehouse">
      <span class="search-item-specs__link" v-ui-test="'show-change-warehouse-modal'">
        {{ warehouse }}
      </span>
    </template>
    <template v-else>
      {{ warehouse }}
    </template>
  </span>
</template>

<style lang="scss" src="./product-location.scss" scoped></style>

<script setup lang="ts">
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useRoute } from '@/src/core/utils/router';
import { getAirbusPartLocation } from '@/src/market/services/airbus-parts';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isAirbus, isSatair, productAdditionalData } from '@/src/market/services/product-parts';
import { computed } from 'vue';

interface Props {
  id: string;
  addToBasketQuantity?: number;
  owningSystem: string;
  forceFetch?: boolean;
  showChangeWarehouse?: boolean;
}

const props = defineProps<Props>();
const route = useRoute();
const authenticationStore = useAuthenticationStore();

const productAdditionalDataValue = computed(() =>
  productAdditionalData(props.id, props.owningSystem),
);
const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
const procurement = computed(() => {
  if (isAirbus(productAdditionalDataValue.value)) {
    return getProcurementByRouteParams(route.params, productAdditionalDataValue.value.procurements);
  }
  return undefined;
});

const warehouse = computed(() => {
  if (isSatair(productAdditionalDataValue.value)) {
    return productAdditionalDataValue.value?.Warehouse?.Name || '-';
  }

  if (isAirbus(productAdditionalDataValue.value)) {
    return getAirbusPartLocation(procurement.value);
  }

  return '-';
});
</script>
