<template>
  <InViewWatcher>
    <div class="blog-content-wrapper"><slot></slot></div>
  </InViewWatcher>
</template>

<style lang="scss" src="./blog-content-wrapper.scss"></style>

<script lang="ts">
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { InViewWatcher },
})
export default class BlogPostModule extends Vue {}
</script>
