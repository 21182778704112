// tslint:disable
import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class ProductApi {
  public static ProductDetails = (manufacturer: string, manufacturerAid: string) =>
    `${baseUrl}/market/product/${manufacturer}/${manufacturerAid}`;
  public static OfferDetails = (manufacturer: string, manufacturerAid: string, offerId: string) =>
    `${baseUrl}/market/product/${manufacturer}/${manufacturerAid}/${offerId}`;
  public static Product = (id: string) => `${baseUrl}/api/product/${id}`;
  public static ProductOffer = (id: string) => `${baseUrl}/api/product/offer/${id}`;
  public static ProductSku = () => `${baseUrl}/api/product/sku`;
  public static ProductInfo = (id: string) => `${baseUrl}/api/product/info/${id}`;
  public static ProductInterchangeable = () => `${baseUrl}/api/product/interchangeable`;
  public static ProductInterchangeableIndicator = (pno: string, manu: string) =>
    `${baseUrl}/api/product/interchangeable/indicator/${pno}/${manu}`;
  public static ProductAvailability = (code: string, quantity: number = 1) =>
    `${baseUrl}/api/product/availability/${code}/${quantity}`;
  public static ProductAvailabilityBundle: string = `${baseUrl}/api/product/availability/`;
  public static ProductRecommendations = () => `${baseUrl}/api/product/recommendations`;
  public static ProductAdditionalInfo = `${baseUrl}/api/product/additionalinfo/`;
  public static AirbusPartRequest = `${baseUrl}/api/product/airbuspartrequest/`;
  public static ProductPlantAvailability = (id: string) => `${baseUrl}/api/product/plants/${id}`;
  public static ProductPriceInfo = `${baseUrl}/api/product/priceinfo/`;
  public static ProductDownloadFile = `${baseUrl}/api/product/airbuspartdocument`;
  public static ProductCheckoutoptions = `${baseUrl}/api/product/checkoutoptions`;
  public static ProductByRoute = (cageCode: string, productNumber: string, owningSystem: string) =>
    `${baseUrl}/api/product/lookup/${cageCode}/${productNumber}/${owningSystem}`;
  public static ServiceReference = () => `${baseUrl}/api/product/servicereference`;
}
