<template>
  <span v-if="isAuthenticated && id !== '' && allowWarehouseChangeButton">
    <a
      @click="
        openModal({
          modalComponent: 'ModalSelectWarehouse',
          params: { offerId: id },
        })
      "
      v-ui-test="'show-chane-warehouse-modal'"
    >
      <template v-if="!$slots.default">{{ textK('UI_CART_WAREHOUSE_CUSTOMIZE') }}</template>
      <template v-else>
        <slot />
      </template>
    </a>
  </span>
</template>

<style lang="scss" src="./product-details-warehouse-button.scss" scoped></style>

<script lang="ts" setup>
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { ProductPlantsList } from '@/src/core/types/api';
import { isAirbus, isSatair, productAdditionalData } from '@/src/market/services/product-parts';
import { useProductPlantAvailabilityStore } from '@/src/market/stores/product-plant-availability';
import { computed } from 'vue';

interface Props {
  id: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const openModal = useModal();
const productPlantAvailabilityStore = useProductPlantAvailabilityStore();
const authenticationStore = useAuthenticationStore();

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const productData = computed(() => productAdditionalData(props.id, props.owningSystem));

const plantAvailability = computed(() => {
  if (isSatair(productData.value)) {
    return productPlantAvailabilityStore.plantAvailabilityByProductOfferId(props.id);
  }
  return {} as ProductPlantsList;
});

const allowWarehouseChangeButton = computed(() => {
  if (isSatair(productData.value)) {
    return (
      productData.value &&
      productData.value.EnableCustomizeWarehouse &&
      plantAvailability.value &&
      plantAvailability.value.HasPlants
    );
  }
  return !isAirbus(productData.value); // Combined conditions for Airbus case
});
</script>
