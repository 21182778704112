<template>
  <form @submit.prevent="setNewPassword">
    <StandardDialogModal
      :has-cancel-button="$route.path !== '/market/welcome'"
      :locked-modal="$route.path === '/market/welcome'"
      :has-close-button="$route.path !== '/market/welcome'"
      :size="uiSize.Medium"
    >
      <template #header>
        <h3 v-if="$route.path === '/market/welcome'">Welcome and let's get started</h3>
        <h3 v-else>Reset your password</h3>
        <p>
          Select the new password that you will use to login to Satair.com
          {{ email ? 'with ' + email : '' }}
        </p>
      </template>

      <template #body>
        <div class="inner-body typeset">
          <div class="input-group">
            <div class="input-container">
              <VTextField
                v-ui-test="'changepassword-newpassword'"
                :type="uiVariant.Secondary"
                label="New password"
                input-type="password"
                name="newPassword"
                v-model="newPassword"
                disable-type-validation
              ></VTextField>
            </div>
            <div class="input-container">
              <VTextField
                v-ui-test="'changepassword-newpassword-retyped'"
                :type="uiVariant.Secondary"
                label="Repeat new password"
                input-type="password"
                name="repeatNewPassword"
                v-model="repeatNewPassword"
                disable-type-validation
              ></VTextField>
            </div>
          </div>
          <PasswordValidationBox
            :value="newPassword"
            :repeated-value="repeatNewPassword"
          ></PasswordValidationBox>
        </div>
      </template>

      <template #actions>
        <VButton
          :disabled="disableSubmit"
          :type="uiVariant.Default"
          v-ui-test="'set-new-password-button'"
          :loading="isBusy"
          >Save password & login</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import { UserApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import PasswordValidationBox from '@/src/core/components/ui/form/password-validation-box/password-validation-box.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { passwordRegEx } from '@/src/core/utils/regex-library';
import { useRouter } from '@/src/core/utils/router';
import { computed, ref, watch } from 'vue';

interface Params {
  email: string;
  token: string;
}

const modalParams = computed(() => {
  return modalStore.params as Params;
});

const modalStore = useModalStore();
const router = useRouter();
const uiVariant = UiVariant;
const uiSize = UiSize;

const email = ref<string | undefined>('');
const token = ref<string | undefined>('');

const newPassword = ref('');
const repeatNewPassword = ref('');
const isBusy = ref(false);

const disableSubmit = computed(() => {
  return (
    !passwordRegEx.test(newPassword.value) ||
    newPassword.value.length === 0 ||
    newPassword.value !== repeatNewPassword.value
  );
});

const setNewPassword = async () => {
  isBusy.value = true;

  const { IsSuccess } = await Req({
    url: UserApi.ResetUserPasswordSet(),
    method: 'POST',
    data: {
      email: email.value,
      token: token.value,
      password: repeatNewPassword.value,
    },
  });

  if (IsSuccess) {
    router.push({ name: 'market' });
    modalStore.closeModal();
  }
  isBusy.value = false;
};

watch(
  () => modalParams.value,
  (newParams) => {
    if (newParams?.email && newParams?.token) {
      const emailValue = newParams.email as string;
      const tokenValue = newParams.token as string;
      email.value = emailValue;
      token.value = tokenValue.replace(/ /g, '+');
    }
  },
  { immediate: true, deep: true },
);
</script>
