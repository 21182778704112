// tslint:disable:no-any
import { DirectiveBinding } from 'vue/types/options';
import { VNode } from 'vue/types/vnode';
import Vue from 'vue';

export default Vue.directive('click-outside', {
  bind: (el: any, binding: DirectiveBinding, vnode: VNode) => {
    el.clickOutsideEvent = (event: any) => {
      if (
        !(el === event.target || el.contains(event.target)) &&
        vnode?.context &&
        binding?.expression
      ) {
        if (vnode.context[binding.expression]) {
          vnode.context[binding.expression](event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: (el: any) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
