import { ProductApi } from '@/src/core/api';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { IProductAdditionalData, IProductQueueItem } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useProductAdditionalInfoStore = defineStore('product-additional-info', () => {
  let queueTimer: number;
  const loadThreshold: number = 1500;
  const products = ref<IProductAdditionalData[]>([]);
  const queue = ref<IProductQueueItem[]>([]);

  const productQueued = (payload: { OfferId: string; Quantity: number }) => {
    queue.value.push(payload);
  };

  const queueCleared = () => {
    queue.value = [];
  };

  const productBundleUpdated = (payload: {
    priceDataBundle: IProductAdditionalData[];
    failed?: boolean;
  }) => {
    payload.priceDataBundle.map((additionalData: IProductAdditionalData) => {
      Object.freeze(additionalData);

      const hasFailed: object = payload.failed ? { HasFailed: true } : {};
      const productPriceData = Object.assign(
        {},
        additionalData,
        { IsBusy: false },
        hasFailed,
      ) as IProductAdditionalData;

      if (products.value.length <= 0) {
        products.value.push(productPriceData);
      } else {
        const productExistOnIndex = products.value.findIndex(
          (product) => product.Id === productPriceData.Id,
        );

        if (productExistOnIndex > -1) {
          products.value.splice(productExistOnIndex, 1, productPriceData);
        } else {
          products.value.push(productPriceData);
        }
      }
    });
  };

  const reset = () => {
    products.value = [];
    queue.value = [];
  };

  const queueAdditionalProductData = async (payload: {
    OfferId: string;
    WarehouseCode?: string;
    Quantity: number;
  }) => {
    clearTimeout(queueTimer);

    if (isInAdditionalDataProductCue(payload)) {
      // delete item so it can be added again with correct quantity
      deleteProductFromQueue(payload);
    }

    const productIndex = products.value.findIndex((product) => product.Id === payload.OfferId);

    if (productIndex > -1) {
      products.value[productIndex].IsBusy = true;
    }
    productQueued(payload);

    // if we have 5 in cue send the request - otherwise start the timer
    if (queue.value.length >= 5) {
      fetchBundle();
      queueCleared();
    } else {
      // make sure we send the request if the user only have 3 products in view and stops scrolling
      queueTimer = window.setTimeout(() => {
        if (queue.value.length > 0) {
          fetchBundle();
          queueCleared();
        }
      }, loadThreshold);
    }
  };

  const fetchBundle = async () => {
    if (queue.value.length === 0) {
      return;
    }
    // make sure we only send requests bundled by 5
    const chunkBy = 5;
    const chunkedProducts = [];
    // Only include if quantity over 0.
    const allProducts = queue.value.filter((cueItem: IProductQueueItem) => cueItem.Quantity > 0);
    const chunks = Math.ceil(allProducts.length / chunkBy);

    for (let i = 0; i < chunks; i++) {
      chunkedProducts.push(allProducts.splice(0, chunkBy));
    }

    for (const productBundle of chunkedProducts) {
      const { IsSuccess, Data } = await Req({
        method: 'POST',
        url: ProductApi.ProductAdditionalInfo,
        data: { productPriceRequests: productBundle },
      });

      // Pass data every time — even if error
      // This gives the user fail-feedback
      const success = !!Data && IsSuccess;
      productBundleUpdated({
        priceDataBundle: success ? Data : productBundle,
        failed: !success,
      });
    }
  };

  const fetchProduct = async (payload: { OfferId: string; Quantity: number }) => {
    const { IsSuccess, Data } = await Req(
      {
        method: 'POST',
        url: ProductApi.ProductAdditionalInfo,
        data: { productPriceRequests: [payload] },
      },
      new ReqQueue(ReqQueueTypes.Default, 'priceSingle', payload.OfferId),
    );

    // Pass data every time — even if error
    // This gives the user fail-feedback
    const success = !!Data && IsSuccess;

    productBundleUpdated({
      priceDataBundle: success ? Data : [payload],
      failed: !success,
    });
  };

  const fetchProductBundled = (payload: IProductQueueItem[]) => {
    const chunkBy = 5; // maximum number of product per request
    const chunkedProducts = [];
    const allProducts = payload.filter((item) => item.Quantity > 0);
    const chunks = Math.ceil(allProducts.length / chunkBy);
    for (let i = 0; i < chunks; i++) {
      chunkedProducts.push(allProducts.splice(0, chunkBy));
    }

    return Promise.allSettled(
      chunkedProducts.map(async (product) => {
        const { IsSuccess, Data } = await Req({
          method: 'POST',
          url: ProductApi.ProductAdditionalInfo,
          data: { productPriceRequests: product },
        });

        const success = !!Data && IsSuccess;
        productBundleUpdated({
          priceDataBundle: success ? Data : product,
          failed: !success,
        });

        if (IsSuccess) {
          return Data;
        }
      }),
    );
  };

  const additionalDataByOfferId = (id: string) => {
    return products.value.filter((product) => product.Id === id)[0];
  };

  const isInAdditionalDataProductCue = (productItem: { OfferId: string; Quantity: number }) => {
    return queue.value.find((product) => product.OfferId === productItem.OfferId);
  };

  const deleteProductFromQueue = (productItem: { OfferId: string; Quantity: number }) => {
    const index = queue.value.findIndex((product) => product.OfferId === productItem.OfferId);
    if (index !== -1) {
      queue.value.splice(index, 1);
    }
  };

  return {
    products,
    queue,
    reset,
    queueAdditionalProductData,
    fetchBundle,
    fetchProduct,
    fetchProductBundled,
    additionalDataByOfferId,
    isInAdditionalDataProductCue,
    deleteProductFromQueue,
  };
});
