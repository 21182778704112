<template>
  <div class="product-item-cta" v-if="isAuthenticated">
    <ProductInfoBanner v-if="isZtel" :show-tooltip="true" :type="UiVariant.Secondary">
      <template #banner-text>
        <span>{{ textK('UI_PRODUCT_ZTEL') }}</span>
      </template>
      <template #tooltip-text>
        <span v-ui-test="'ztal-tooltip'">{{ textK('UI_PRODUCT_ZTEL_DESCRIPTION') }}</span>
      </template>
    </ProductInfoBanner>
    <div class="product-price" v-if="!hasPriceData">
      <div class="product-price-label-loader" v-ui-test="'price-label-loading'">
        <dotLoader v-if="isInView" :loading="true"></dotLoader>
      </div>
    </div>
    <template v-else>
      <div class="product-price-volume-discount">
        <ProductVolumeDiscounts
          v-if="productAdditionalData"
          :id="id"
          :owning-system="owningSystem"
          :is-product-card="isInSearchCard"
        />
      </div>
      <template v-if="productAdditionalData && productAdditionalData.IsBusy">
        <div class="product-price">
          <div class="product-price-label-loader" v-ui-test="'price-label-loading'">
            <dotLoader :loading="true"></dotLoader>
          </div>
        </div>
      </template>
      <template v-else-if="isPriceVisible">
        <ProductInfoBanner v-if="discountPercentage" :show-tooltip="discountEndDateAvailable">
          <template #banner-text>
            <span>{{ textK('UI_COMMON_SAVE') }} {{ discountPercentageFormatted }}</span>
          </template>
          <template #tooltip-text>
            <span v-ui-test="'product-discount-tooltip'"
              >{{ textK('UI_PRODUCT_SALE_END_DATE') }} {{ date(discountEndDate) }}</span
            >
          </template>
        </ProductInfoBanner>
        <div class="product-price">
          <div
            v-if="priceValue > 0"
            class="product-price-specification"
            v-ui-test="'price-specification'"
          >
            <span v-if="showLeasePrice">From</span>
            {{ currencyIso }}
            <template v-if="currencyIso && productPriceUnit"> / </template>
            <span v-if="productPriceUnit">{{ productPriceUnit }}</span>
            <Tooltip
              v-if="showLeasePrice"
              :position="TooltipDefaultPosition.Top"
              class="product-price__lease-tooltip"
            >
              <span class="banner">
                <svgicon name="ui-info" />
              </span>
              <template #tooltip-content>
                {{ minimumLeasePeriodText }}
              </template>
            </Tooltip>
            <ProductUnitConversion :product="product"></ProductUnitConversion>
          </div>
          <div>
            <div
              v-if="priceValue > 0"
              class="product-price-label"
              :class="{ 'product-price-label--discount': discountPercentage }"
              v-ui-test="'price-label'"
              v-html="price(formattedPriceValue)"
            ></div>
            <div
              v-else-if="isExhangePart"
              class="product-price product-price--link product-price--information product-price product-price--details"
            >
              <a @click.stop="openExchangePartPriceDetails($event)">
                {{ textK('UI_PRODUCT_PRICE_DETAILS') }}</a
              >
            </div>
            <div
              v-else-if="isRepairPart"
              class="product-price product-price--link product-price--information product-price product-price--details"
            >
              <a @click.stop="openRepairPartPriceDetails($event)">
                {{ textK('UI_PRODUCT_PRICE_DETAILS') }}</a
              >
            </div>
            <div
              v-else
              class="product-price--information"
              :class="{
                'product-price': !hasMOQOrSPQ,
              }"
              v-ui-test="'price-label'"
            >
              {{ isAsmHidden ? textK('UI_PRODUCT_HIDDEN_PRICE') : textK('UI_CART_NOPRICE') }}
            </div>
            <div
              v-if="discountPercentage !== 0"
              class="product-price-label product-price-label--old-price"
              v-ui-test="'price-label'"
              v-html="price(originalPrice)"
            ></div>
          </div>
          <!-- Show In big screen and PDP -->
          <ProductMoqSpq
            class="product-price-units-price"
            v-if="
              (product && product.AddToBasketQty > 1) || (product && product.PackageQuantity > 1)
            "
          >
            <ProductMoqSpqUnits
              v-if="product.AddToBasketQty > 1"
              v-ui-test="'moq-tag'"
              :title="titleTag(textK('UI_COMMON_MIN_QUANTITY'))"
            >
              {{ textK('UI_COMMON_MIN_QUANTITY_SHORTHAND') }}:
              <span v-ui-test="'moq-amount'">{{ product.AddToBasketQty }}</span>
            </ProductMoqSpqUnits>
            <ProductMoqSpqUnits
              v-if="product && product.PackageQuantity > 1"
              v-ui-test="'spq-tag'"
              :title="titleTag(textK('UI_COMMON_SPQ_QUANTITY'))"
            >
              {{ textK('UI_COMMON_PACKAGE_QUANTITY_SHORTHAND') }}:
              <span v-ui-test="'spq-amount'">{{ product.PackageQuantity }}</span>
            </ProductMoqSpqUnits>
          </ProductMoqSpq>
        </div>
      </template>
      <template v-else-if="productAdditionalData">
        <div class="product-price product-price--information" v-ui-test="'price-label'">
          <span class="product-price--restricted" v-if="materialStatusZero">{{
            textK('UI_PRODUCT_AIRBUS_MATERIAL_STATUS_ZERO')
          }}</span>
          <span
            class="product-price--restricted"
            v-else-if="ruleStatusCPMS && !materialStatusE"
            v-html="textK('UI_PRODUCT_OUTOFPRODUCTION')"
          ></span>
          <span
            class="product-price--restricted"
            v-else-if="ruleStatusCPMS30"
            v-html="textK('UI_PRODUCT_ITEMONHOLD')"
          ></span>
          <span
            class="product-price--restricted"
            v-else-if="ruleStatusRestricted"
            v-html="textK('UI_PRODUCT_RESTRICTED_TEXT')"
          ></span>
          <span
            class="product-price--restricted"
            :class="{
              'product-price--withMOQSPQ': hasMOQOrSPQ && isBlueOwningSystem,
            }"
            v-else-if="
              (ruleStatusNoPrice && !materialStatusE) ||
              (!hasBlueProductBasePrice && !materialStatusE)
            "
          >
            <template v-if="product?.IsMarketplace">{{
              textK('UI_PRODUCT_NOPRICE_MARKETPLACE')
            }}</template>
            <template v-else>
              {{
                isBlueOwningSystem
                  ? textK('UI_COMMON_AIRBUS_NO_PRICE')
                  : textK('UI_PRODUCT_NOPRICE')
              }}
            </template>
          </span>
          <span class="product-price--restricted" v-else-if="materialStatusE">
            {{ textK('UI_PRODUCT_AIRBUS_MATERIAL_STATUS_E') }}
          </span>

          <template v-else-if="productAdditionalData && isOutOfStock">
            <div class="product-price-specification" v-ui-test="'price-specification'">
              {{ currencyIso }}<span v-if="productPriceUnit"> / {{ productPriceUnit }}</span>
            </div>
            <div
              class="product-price-label"
              v-ui-test="'price-label'"
              v-html="price(formattedPriceValue)"
            ></div>
            <!-- Not sure WHAT this is -->
            <ProductMoqSpq
              class="product-price-units-price"
              v-if="
                (product && product.AddToBasketQty > 1) || (product && product.PackageQuantity > 1)
              "
            >
              <ProductMoqSpqUnits
                v-if="product.AddToBasketQty > 1"
                v-ui-test="'moq-tag'"
                :title="titleTag(textK('UI_COMMON_MIN_QUANTITY'))"
              >
                {{ textK('UI_COMMON_MIN_QUANTITY_SHORTHAND') }}:
                <span v-ui-test="'moq-amount'">{{ product.AddToBasketQty }}</span>
              </ProductMoqSpqUnits>
              <ProductMoqSpqUnits
                v-if="product && product.PackageQuantity > 1"
                v-ui-test="'spq-tag'"
                :title="titleTag(textK('UI_COMMON_SPQ_QUANTITY'))"
              >
                {{ textK('UI_COMMON_PACKAGE_QUANTITY_SHORTHAND') }}:
                <span v-ui-test="'spq-amount'">{{ product.PackageQuantity }}</span>
              </ProductMoqSpqUnits>
            </ProductMoqSpq>
          </template>
          <span v-else>
            <template v-if="product?.IsMarketplace">{{
              textK('UI_PRODUCT_NOPRICE_MARKETPLACE')
            }}</template>
            <template v-else>
              {{ textK('UI_PRODUCT_NOPRICE') }}
            </template>
          </span>
          <div class="product-price-moqspq">
            <ProductMoqSpq
              class="product-price-units-price"
              v-if="product && isBlueOwningSystem && isSellable && !hasBlueProductBasePrice"
            >
              <ProductMoqSpqUnits
                v-if="product.AddToBasketQty > 1"
                v-ui-test="'moq-tag'"
                :title="titleTag(textK('UI_COMMON_MIN_QUANTITY'))"
              >
                {{ textK('UI_COMMON_MIN_QUANTITY_SHORTHAND') }}:
                <span v-ui-test="'moq-amount'">{{ product.AddToBasketQty }}</span>
              </ProductMoqSpqUnits>
              <ProductMoqSpqUnits
                v-if="product && product.PackageQuantity > 1"
                v-ui-test="'spq-tag'"
                :title="titleTag(textK('UI_COMMON_SPQ_QUANTITY'))"
              >
                {{ textK('UI_COMMON_PACKAGE_QUANTITY_SHORTHAND') }}:
                <span v-ui-test="'spq-amount'">{{ product.PackageQuantity }}</span>
              </ProductMoqSpqUnits>
            </ProductMoqSpq>
          </div>
        </div>
      </template>
      <div class="product-button" v-if="product">
        <template v-if="productAdditionalData && isSellable">
          <QuantityController
            ref="quantityController"
            v-ui-test="'search-item-add-to-cart'"
            :product-id="product.Id"
            :has-add-to-cart="true"
            :bid-or-quote="canBidOrQuote"
            :is-marketplace="product.IsMarketplace"
            :min-value="product.AddToBasketQty"
            :step="product.CartStep"
            :unit="productSalesUnit"
          />
        </template>
        <template v-else-if="isLeaseToolAndQuotable">
          <RequestQuoteButton
            :text="textK('UI_TOOL_QUOTATION_REQUEST_LEASE_HEADER')"
            :ui-test-tag="'search-item-request-tool-lease '"
            :modal-params="{
              modalComponent: 'ModalRequestToolLease',
              params: {
                id: product?.Id,
              },
            }"
          />
        </template>
        <template v-else-if="isLeasePartAndQuotable">
          <RequestQuoteButton
            :text="textK('UI_TOOL_QUOTATION_REQUEST_LEASE_QUOTE_BUTTON_TEXT')"
            :ui-test-tag="'search-item-request-part-lease '"
            :modal-params="{
              modalComponent: 'ModalRequestPartLease',
              params: {
                id: product?.Id,
              },
            }"
          />
        </template>
        <div v-else-if="isUsedAndQuotable" class="btn-wrapper">
          <VButton
            v-ui-test="'search-item-request-used-procurement'"
            :type="UiVariant.Secondary"
            :size="UiSize.Auto"
            class="product-button-login requests"
            @click.stop="
              openModal({
                modalComponent: 'ModalRequestToolUsed',
                params: {
                  id: product?.Id,
                  serialNumber: procurement?.serialNumber,
                },
              })
            "
          >
            {{ textK('UI_PRODUCT_REQUESTQUOTE') }}
          </VButton>
        </div>
        <template
          v-else-if="
            productAdditionalData &&
            ruleStatusNoPrice &&
            !readOnlyAccount &&
            canBidOrQuote &&
            (enableSatairQuotes || product.IsMarketplace)
          "
        >
          <VButton
            v-ui-test="'search-item-request-price'"
            @click="openQuoteModal"
            :type="UiVariant.Secondary"
            :size="UiSize.Auto"
            class="product-button-login requests"
          >
            {{ textK('UI_QUOTES_REQUEST_PRICE') }}
          </VButton>
        </template>
        <template
          v-else-if="
            productAdditionalData &&
            !ruleStatusRestricted &&
            canBidOrQuote &&
            !readOnlyAccount &&
            (enableSatairQuotes || product.IsMarketplace)
          "
        >
          <VButton
            v-ui-test="'search-item-request-quote'"
            @click="openQuoteModal"
            :type="UiVariant.Primary"
            :size="UiSize.Auto"
            class="product-button-login requests"
          >
            {{ textK('UI_QUOTES_REQUEST_CTA') }}
          </VButton>
        </template>

        <template v-else-if="isRequestPartExchangeAvailable">
          <VButton
            v-ui-test="'search-item-request-quote'"
            @click="
              openModal({
                modalComponent: 'ModalRequestExchange',
                params: {
                  id,
                  procurementType: procurement?.procurementType,
                },
              })
            "
            :type="UiVariant.Primary"
            :size="UiSize.Auto"
            class="product-button-login requests"
          >
            {{ textK('UI_PROPRIETARY_PARTS_QUOTATION_REQUEST_EXCHANGE') }}
          </VButton>
        </template>

        <template v-else-if="isAirbusPartRepairQuotable">
          <VButton
            v-ui-test="'search-item-request-quote'"
            @click="
              openModal({
                modalComponent: 'ModalAirbusRepairRequestQuote',
                params: {
                  productId: id,
                  procurementType: procurementType,
                  serialNumberRequired: product?.SerialNumberRequired,
                },
              })
            "
            :type="UiVariant.Primary"
            :size="UiSize.Auto"
            class="product-button-login requests"
          >
            {{ textK('UI_REPAIR_QUOTATION_REQUEST_CTA') }}
          </VButton>
        </template>

        <template v-else>
          <VButton
            v-ui-test="'search-item-login'"
            :type="UiVariant.Secondary"
            :size="UiSize.Auto"
            class="product-button-login"
            disabled
          >
            <!-- Text for products that cannot be added to cart -->
            {{ ruleStatusBtnLabel }}
          </VButton>
        </template>
      </div>
    </template>
  </div>
  <div class="product-item-cta" v-else>
    <div class="product-price">
      <svgicon name="ui-login" class="product-price-login"></svgicon>
    </div>
    <div class="product-button">
      <VButton
        v-ui-test="'product-item-login'"
        :type="UiVariant.Primary"
        :size="UiSize.Auto"
        class="product-button-login"
        @click="openModal({ modalComponent: 'ModalLogin' })"
        >{{ textK('UI_PRODUCT_LOGINCTA') }}</VButton
      >
    </div>
  </div>
</template>

<style lang="scss" src="./product-price-quantity.scss" scoped></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import usePrice from '@/src/core/hooks/usePrice';
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { TextKey } from '@/src/core/services/text-key';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { TooltipDefaultPosition } from '@/src/core/types/interfaces';
import { MaterialStatus } from '@/src/core/types/material-status';
import { DateFormat } from '@/src/core/utils/dates';
import { formatPrice } from '@/src/core/utils/math';
import { useRoute } from '@/src/core/utils/router';
import ProductInfoBanner from '@/src/market/components/product/product-info-banner/product-info-banner.vue';
import ProductMoqSpqUnits from '@/src/market/components/product/product-moq-spq/product-moq-spq-units/product-moq-spq-units.vue';
import ProductMoqSpq from '@/src/market/components/product/product-moq-spq/product-moq-spq.vue';
import ProductUnitConversion from '@/src/market/components/product/product-unit-conversion/product-unit-conversion.vue';
import ProductVolumeDiscounts from '@/src/market/components/product/product-volume-discounts/product-volume-discounts.vue';
import RequestQuoteButton from '@/src/market/components/request-quote-button/request-quote-button.vue';
import QuantityController from '@/src/market/components/utils/quantity-controller/quantity-controller.vue';
import {
  formatPriceBreakInfo,
  formatPriceUnit,
  getAirbusPartAvailableStock,
  hasBasePrice,
} from '@/src/market/services/airbus-parts';
import {
  hasNoPriceAvailable,
  isMaterialStatusQuotable,
} from '@/src/market/services/material-status';
import { getCurrentPriceBreakIndex } from '@/src/market/services/price-service';
import {
  getProcurementByRouteParams,
  isExchangeType,
  isLeasePart,
  isLeaseTool,
  isUsedPart,
  isUsedTool,
} from '@/src/market/services/procurements';
import {
  productAdditionalData as getProductAdditionalData,
  isAirbus,
  isSatair,
} from '@/src/market/services/product-parts';
import {
  discountEndDate as getDiscountEndDate,
  discountEndDateAvailable as getDiscountEndDateAvailable,
  discountPercentageFormatted as getDiscountPercentageFormatted,
  originalPrice as getOriginalPrice,
} from '@/src/market/services/product-parts-prices';
import {
  airbusProductNotQuotableOrSellable as checkIfAirbusProductIsQuotableOrSellable,
  isBlueOwningSystem as checkIfIsBlueOwningSystem,
  isAirbusPartRepairQuotable as checkIfPartRepairQuotable,
  isPriceVisible as checkIfPriceIsVisible,
  isSellable as checkIfSellable,
  crossPlantMaterialOutOfStock,
  hasNoPrice,
  isQuotable,
  isRestricted,
  limitedByCrossPlantMaterialStatus,
  materialStatus,
} from '@/src/market/services/product-rules';
import { isBlueCustomer, isRedCustomer } from '@/src/market/services/user-service';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useCartStore } from '@/src/market/stores/cart';
import { useProductStore } from '@/src/market/stores/product';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { computed, ref, watch } from 'vue';

interface Props {
  id: string;
  owningSystem: string;
  actionview: string;
  forceFetch?: boolean;
  isInView?: boolean;
  canBidOrQuote: boolean;
  isZtel: boolean;
  isInSearchCard?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  actionview: 'unknown',
  forceFetch: false,
  isInView: false,
  isZtel: false,
  isInSearchCard: false,
});

const textK = useText();
const authenticationStore = useAuthenticationStore();
const openModal = useModal();
const route = useRoute();

const cartStore = useCartStore();
const productStore = useProductStore();
const productAdditionalInfoStore = useProductAdditionalInfoStore();
const productPriceInfoStore = useProductPriceInfoStore();
const airbusPartStore = useAirbusPartStore();

const titleTag = useTitleTag;
const price = usePrice;
const date = DateFormat;

const quantityController = ref<HTMLElement | null>(null);

const userOwningSystem = computed(() => {
  return isBlueCustomer() ? OwningSystem.BLUE : OwningSystem.RED;
});

const product = computed(() => {
  return productStore.productById(props.id);
});

const productAdditionalData = computed(() => {
  return getProductAdditionalData(props.id, props.owningSystem);
});

const productPriceData = computed(() => {
  return productPriceInfoStore.priceDataByOfferId(product.value?.Id || '');
});

const productPriceInfo = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productPriceData.value?.ProductPriceInfo;
  }

  return null;
});

const sellerId = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.Warehouse?.Code;
  }

  return '';
});

const procurement = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    const { params } = route;
    return getProcurementByRouteParams(params, productAdditionalData.value.procurements);
  }

  return undefined;
});

const procurementType = computed(() => {
  if (procurement.value) {
    return procurement.value.procurementType;
  }
  return '';
});

const currentQuantity = computed(() => {
  return (product.value && cartStore.getCartEntry(product.value.Id).Quantity) || 0;
});

const originalPrice = computed(() => {
  return getOriginalPrice(productAdditionalData.value, procurement.value);
});

const priceValue = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return parseFloat(productAdditionalData.value.Price.Value);
  }

  if (isAirbus(productAdditionalData.value)) {
    if (isLeaseProcurement.value) {
      return procurement.value?.leaseInfo?.minimumLease?.subtotal
        ? parseFloat(procurement.value?.leaseInfo?.minimumLease?.subtotal)
        : 0;
    }
    return procurement.value?.basePrice?.value ?? 0;
  }

  return 0;
});

const formattedPriceValue = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return currentVolumePrice.value && currentVolumePrice.value.VolumeDiscountPercentage > 0
      ? currentVolumePrice.value.BasePrice.FormattedValue
      : productAdditionalData.value?.BasePrice?.FormattedValue || '';
  }

  if (isAirbus(productAdditionalData.value)) {
    if (showLeasePrice.value) {
      return formatPrice(procurement.value?.leaseInfo?.minimumLease.subtotal || '0');
    }

    return procurement.value?.basePrice?.formattedValue ?? '';
  }

  return '';
});

const volumePrices = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productPriceData.value?.ProductPriceInfo?.PriceBreakInfos || [];
  }

  if (isAirbus(productAdditionalData.value)) {
    return formatPriceBreakInfo(procurement.value);
  }

  return [];
});

const currentVolumePrice = computed(() => {
  const index = getCurrentPriceBreakIndex(volumePrices.value, currentQuantity.value);
  return volumePrices.value[index];
});

const currencyIso = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.Price.CurrencyIso;
  }

  if (isAirbus(productAdditionalData.value)) {
    if (isLeaseProcurement.value && procurement.value?.leaseInfo?.currency) {
      return procurement.value?.leaseInfo?.currency;
    }
    return procurement.value?.basePrice?.currencyIso;
  }

  return '';
});

const productPriceUnit = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return currentVolumePrice.value &&
      currentVolumePrice.value.BasePrice.VolumeDiscountPercentage > 0
      ? currentVolumePrice.value.PriceUnit.Code
      : productPriceInfo.value?.PriceUnit?.Code || productAdditionalData.value?.PriceUnit?.Code;
  }

  if (isAirbus(productAdditionalData.value)) {
    const priceUnit = formatPriceUnit(procurement.value?.priceUnit);
    return priceUnit.SapCode;
  }

  return '';
});

const productSalesUnit = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productPriceInfo.value?.SalesUnit
      ? productPriceInfo.value.SalesUnit.Code
      : product.value?.Unit || '';
  }

  if (isAirbus(productAdditionalData.value)) {
    const priceUnit = formatPriceUnit(procurement.value?.salesUnit);
    return priceUnit.SapCode;
  }

  return '';
});

const discountPercentage = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.DiscountPercentage;
  }

  if (isAirbus(productAdditionalData.value)) {
    return procurement.value?.discountPercentage
      ? parseFloat(procurement.value?.discountPercentage)
      : 0;
  }

  return 0;
});

const discountEndDate = computed(() => {
  return getDiscountEndDate(productAdditionalData.value, procurement.value);
});

const discountEndDateAvailable = computed(() => {
  return getDiscountEndDateAvailable(productAdditionalData.value, procurement.value);
});

const discountPercentageFormatted = computed(() => {
  return getDiscountPercentageFormatted(productAdditionalData.value, procurement.value);
});

const ruleStatusNoPrice = computed(() => {
  return hasNoPrice(productAdditionalData.value, procurement.value);
});

const ruleStatusCPMS = computed(() => {
  return (
    limitedByCrossPlantMaterialStatus(productAdditionalData.value, procurement.value) &&
    !materialStatusNoPrice.value
  );
});

const ruleStatusCPMS30 = computed(() => {
  return crossPlantMaterialOutOfStock(productAdditionalData.value, procurement.value);
});

const ruleStatusRestricted = computed(() => {
  return isRestricted(productAdditionalData.value, procurement.value);
});

const materialStatusNoPrice = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return hasNoPriceAvailable(productAdditionalData.value);
  }

  return false;
});

const materialStatusQuotable = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return isMaterialStatusQuotable(productAdditionalData.value);
  }

  return false;
});

const materialStatusE = computed(() => {
  return materialStatus(MaterialStatus.STATUS_E, productAdditionalData.value);
});

const materialStatusZero = computed(() => {
  return materialStatus(MaterialStatus.STATUS_ZERO, productAdditionalData.value);
});

const airbusProductNotQuotableOrSellable = computed(() => {
  return (
    checkIfAirbusProductIsQuotableOrSellable(productAdditionalData.value, procurement.value) &&
    !materialStatusNoPrice.value
  );
});

const enableAirbusPropPartsServiceQuotation = computed(() => {
  return hasFeature(FEATURES.AIRBUS_PROP_PARTS_SERVICE_QUOTATION);
});

const isAirbusPartRepairQuotable = computed(() => {
  if (procurement.value) {
    return checkIfPartRepairQuotable(
      procurement.value,
      enableAirbusPropPartsServiceQuotation.value,
    );
  }
  return false;
});

const ruleStatusBtnLabel = computed(() => {
  if (materialStatusQuotable.value) {
    return TextKey('UI_PRODUCT_CONTACT_TO_QUOTE');
  } else if (
    ruleStatusRestricted.value ||
    ruleStatusCPMS.value ||
    ruleStatusCPMS30.value ||
    airbusProductNotQuotableOrSellable.value ||
    materialStatusE.value ||
    materialStatusZero.value
  ) {
    return TextKey('UI_PRODUCT_RESTRICTED_BUTTON');
  } else if (
    !isAirbusPartRepairQuotable.value &&
    procurementType.value === ProcurementType.Repair
  ) {
    return TextKey('UI_REPAIR_QUOTATION_DISABLED_CTA');
  } else {
    if (ruleStatusNoPrice.value || materialStatusNoPrice.value) {
      return TextKey('UI_PRODUCT_NOPRICE_BUTTON');
    }

    return TextKey('UI_PRODUCT_RESTRICTED_BUTTON');
  }
});

const minimumLeasePeriodText = computed(() => {
  if (product.value?.AirbusProprietary) {
    return TextKey('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_PARTS');
  }
  return TextKey('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_TOOL').replace(
    /\[\[minimum-lease-charge-percent\]\]/gi,
    procurement.value?.leaseInfo?.minimumLease.percentagePerDay as string,
  );
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const isAsmHidden = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.BasePrice.IsAsmHidden;
  }

  return false;
});

const readOnlyAccount = computed(() => {
  return authenticationStore.readOnlyAccount;
});

const isMarketplace = computed(() => {
  return product.value?.IsMarketplace;
});

const isAirbusMastershop = computed(() => {
  return Boolean(
    productAdditionalData.value &&
      'masterShopId' in productAdditionalData.value &&
      productAdditionalData.value?.masterShopId.toLowerCase() === 'airbus',
  );
});

const isBlueOwningSystem = computed(() => {
  return checkIfIsBlueOwningSystem(props.owningSystem as OwningSystem);
});

const isSellable = computed(() => {
  return checkIfSellable(productAdditionalData.value, procurement.value);
});

const isPriceVisible = computed(() => {
  return checkIfPriceIsVisible(productAdditionalData.value, procurement.value);
});

const hasPriceData = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return Boolean(productPriceData.value || productAdditionalData.value);
  }

  if (isAirbus(productAdditionalData.value)) {
    return Boolean(productAdditionalData.value);
  }

  return false;
});

const hasBlueProductBasePrice = computed(() => {
  return hasBasePrice(procurement.value);
});

const isQuotableProduct = computed(() => {
  if (isMarketplace.value) {
    return false;
  }

  if (isSatair(productAdditionalData.value)) {
    return isQuotable(productAdditionalData.value) && enableSatairQuotes.value;
  }

  return isQuotable(productAdditionalData.value, procurement.value);
});

const enableSatairQuotes = computed(() => {
  return hasFeature(FEATURES.SATAIR_QUOTES);
});

const enableAirbusQuotes = computed(() => {
  return hasFeature(FEATURES.AIRBUS_PROP_PARTS_SERVICE_QUOTATION);
});

const isLeasePartAndQuotable = computed(() => {
  return (
    isLeasePart(procurement.value?.procurementType, product.value) &&
    isQuotable(productAdditionalData.value, procurement.value)
  );
});

const isLeaseToolAndQuotable = computed(() => {
  return (
    isLeaseTool(procurement.value?.procurementType, product.value) &&
    isQuotable(productAdditionalData.value, procurement.value)
  );
});

const isRequestPartExchangeAvailable = computed(() => {
  return enableAirbusQuotes.value && isQuotableProduct.value && isExhangePart.value;
});

const isUsedAndQuotable = computed(() => {
  // used product can be quoted only if it's a tool or a part as of SAT-9128
  return (
    (isUsedPart(procurement.value?.procurementType, product.value) ||
      isUsedTool(procurement.value?.procurementType, product.value)) &&
    isQuotableProduct.value
  );
});

const isOutOfStock = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.RuleStatus === 'Out of stock';
  }

  if (isAirbus(productAdditionalData.value)) {
    const availableStock = getAirbusPartAvailableStock(procurement.value);
    return availableStock === 0;
  }

  return false;
});

const hasMOQOrSPQ = computed(() => {
  return (
    product.value &&
    isSellable.value &&
    !hasBlueProductBasePrice.value &&
    (product.value?.AddToBasketQty > 1 || product.value?.PackageQuantity > 1)
  );
});

const isLeaseProcurement = computed(() => {
  return procurement.value?.procurementType === ProcurementType.Lease;
});

const isAirbusPropriety = computed(() => {
  return product.value?.AirbusProprietary;
});

const isExhangePart = computed(() => {
  if (!procurement.value?.procurementType) {
    return false;
  }

  return isExchangeType(procurement.value?.procurementType) && isAirbusPropriety.value;
});

const isRepairPart = computed(() => {
  if (!procurement.value?.procurementType) {
    return false;
  }

  return procurement.value.procurementType === ProcurementType.Repair && isAirbusPropriety.value;
});

const showLeasePrice = computed(() => {
  return isLeaseProcurement.value && (product.value?.IsTool || isAirbusPropriety.value);
});

const openQuoteModal = () => {
  if (isAirbusMastershop.value && procurementType.value === ProcurementType.New) {
    openModal({
      modalComponent: 'ModalCreateAirbusQuote',
      params: { id: props.id },
    });
    return;
  }

  openModal({
    modalComponent:
      !isMarketplace.value && enableSatairQuotes.value
        ? 'ModalCreateSingleLineQuote'
        : 'ModalRequestQuote',
    params: { id: props.id, sellerId: sellerId.value, isMarketplace: isMarketplace.value },
  });
};

const openRepairPartPriceDetails = ($event: Event) => {
  $event.stopPropagation();
  openModal({
    modalComponent: 'ModalAirbusProprietaryPart',
    params: {
      key: 'AirbusProprietaryRepairPartsPriceDetails_ModalUrl',
      title: 'AirbusProprietaryRepairPartsPriceDetails_Name',
    },
  });
};

const openExchangePartPriceDetails = ($event: Event) => {
  $event.stopPropagation();
  openModal({
    modalComponent: 'ModalAirbusProprietaryPart',
    params: {
      key: 'AirbusProprietaryExchangePartsPriceDetails_ModalUrl',
      title: 'AirbusProprietaryExchangePartsPriceDetails_Name',
    },
  });
};

watch(
  () => userOwningSystem.value,
  () => {
    if (
      isAuthenticated.value &&
      props.forceFetch &&
      product.value &&
      !productAdditionalData.value &&
      userOwningSystem.value
    ) {
      if (isBlueOwningSystem.value && isBlueCustomer()) {
        airbusPartStore.queueAirbusParts({
          OfferId: product.value.Id,
          Quantity: 1,
        });
      }

      if (!isBlueOwningSystem.value && isRedCustomer()) {
        productPriceInfoStore.queueAdditionalProductData({
          OfferId: product.value.Id,
          Quantity: product.value.AddToBasketQty || 1,
        });
        productAdditionalInfoStore.queueAdditionalProductData({
          OfferId: product.value.Id,
          Quantity: product.value.AddToBasketQty || 1,
        });
      }
    }
  },
  { immediate: true },
);
</script>
