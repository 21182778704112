<template>
  <section class="product-details-section">
    <div class="product-details-row terms">
      <ProductPriceExpiration :id="product.Id" :owning-system="product.OwningSystem" />
      <template v-if="isLeaseTool">
        <ProductDetailsTermsAndConditions
          :owning-system="product.OwningSystem"
          :is-downloadable="false"
          terms-type="lease-terms"
        />
      </template>
      <template v-if="termsAndConditions">
        <ProductDetailsTermsAndConditions
          :owning-system="product.OwningSystem"
          :is-downloadable="true"
          :download-link="termsAndConditions.Url"
          :label="termsAndConditions.FriendlyName"
          terms-type="general-terms"
        />
      </template>
      <template v-else>
        <ProductDetailsTermsAndConditions :owning-system="product.OwningSystem" />
        <ProductDetailsOfferHandlingFee :owning-system="product.OwningSystem" />
      </template>
    </div>
  </section>
</template>

<script setup lang="ts">
import { MarketplaceMasterShop, Product } from '@/src/core/types/api';
import { useRoute } from '@/src/core/utils/router';
import { computed } from 'vue';

import ProductDetailsOfferHandlingFee from '@/src/market/components/product/product-details/product-details-offer-handling-fee/product-details-offer-handling-fee.vue';
import ProductDetailsTermsAndConditions from '@/src/market/components/product/product-details/product-details-terms-and-conditions/product-details-terms-and-conditions.vue';
import ProductPriceExpiration from '@/src/market/components/product/product-price-expiration/product-price-expiration.vue';
import { isLeaseTool as checkIfLeaseTool } from '@/src/market/services/procurements';

interface Props {
  product: Product;
  seller: MarketplaceMasterShop;
}

const props = defineProps<Props>();
const route = useRoute();

const termsAndConditions = computed(
  () => props.seller?.TermsAndPolicies.find((val) => val.Type === 'terms-and-conditions'),
);

const isLeaseTool = computed(() => {
  const procurementType = route.params.procurementType;
  return checkIfLeaseTool(procurementType, props.product);
});
</script>
