<template>
  <div v-ui-test="'password-step'">
    <div class="login__back">
      <a class="login__back__text" @click="goBack">
        <ArrowIcon class="login__back__icon" direction="left" />{{ textK('UI_COMMON_BACK_BUTTON') }}
      </a>
    </div>
    <form @submit.prevent="handleLogin">
      <div class="input-container">
        <p class="input-container__label pb-sm">
          {{ textK('UI_LOGIN_PASSWORD_SUBTITLE') }} {{ username }}
        </p>
        <VTextField
          v-ui-test="'login-password'"
          :disabled="siteInMaintenanceMode"
          :type="uiVariant.Secondary"
          name="password"
          input-type="password"
          :label="textK('UI_COMMON_PASSWORD')"
          required
          :value="password"
          @input="onInputChange"
          :valid="!passwordHasError"
          disable-type-validation
          :error-message="errorMessage"
          ref="input"
          :size="uiSize.Medium"
        ></VTextField>
      </div>
    </form>
    <div class="login__forgot-password">
      <a
        v-if="!siteInMaintenanceMode"
        @click="handleResetPassword"
        class="login__forgot-password__link"
        v-ui-test="'forgot-password'"
        >{{ textK('UI_COMMON_RESETPASSWORD') }}</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';
import { LOGIN_STEPS } from '@/src/core/stores/login';
import { computed, onMounted, ref } from 'vue';
import ArrowIcon from '../../ui/ani-icons/arrow/arrow-icon.vue';
import VTextField from '../../ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '../../ui/ui.types';

export interface Props {
  username: string;
  password: string;
  siteInMaintenanceMode: boolean;
  passwordHasError: boolean;
}

const props = defineProps<Props>();

const textK = useText();
const uiVariant = UiVariant;
const uiSize = UiSize;
const emit = defineEmits(['go-to-reset-password', 'go-back', 'update-password', 'login']);
const input = ref<InstanceType<typeof VTextField> | null>(null);

const handleResetPassword = () => {
  emit('go-to-reset-password');
};

const goBack = () => {
  emit('go-back', LOGIN_STEPS.PASSWORD);
};

const onInputChange = (value: string) => {
  emit('update-password', value);
};

const handleLogin = () => {
  emit('login');
};

const errorMessage = computed(() => {
  if (props.passwordHasError) {
    return textK('UI_LOGIN_PASSWORD_ERROR_MESSAGE');
  }
  return null;
});

onMounted(() => {
  setTimeout(() => {
    (input.value?.$refs.inputfield as HTMLInputElement)?.focus();
  }, 1000);
});
</script>
