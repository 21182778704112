import ContentLoader from '@/src/content/components/content-loader/content-loader.vue';
import ContentLayout from '@/src/content/layouts/content/content-layout.vue';
import FaqLayout from '@/src/content/layouts/faq/faq-layout.vue';

import { Site, useUIStore } from '@/src/core/stores/ui';

import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { Route, RouteConfig } from 'vue-router';

const routeConfigStore = useRouteConfigStore();
const uiStore = useUIStore();

const contentRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'main',
    component: ContentLayout,
    beforeEnter: (to: Route, from: Route, next: () => void) => {
      uiStore.setCurrentSite({ site: Site.Content });
      next();
    },
    children: [
      {
        path: '/pages/faq*',
        name: 'faq-test',
        components: {
          contentRoute: FaqLayout,
        },
      },
      {
        path: routeConfigStore.getMenuConfigPath('faq', '/help-and-support*'),
        name: 'faq',
        components: {
          contentRoute: FaqLayout,
        },
      },
      {
        path: routeConfigStore.getMenuConfigPath('brandguide', '/brandguide*'),
        name: 'brandguide',
        components: {
          contentRoute: FaqLayout,
        },
        props: {
          disableSearch: true,
        },
      },
      {
        path: '*',
        name: 'mainUmbracoContent',
        components: {
          contentRoute: ContentLoader,
        },
      },
    ],
  },
];

export default contentRoutes;
