<template>
  <div v-ui-test="'reset-password-step'">
    <div class="login__back">
      <a class="login__back__text" @click="goBack">
        <ArrowIcon class="login__back__icon" direction="left" />{{ textK('UI_COMMON_BACK_BUTTON') }}
      </a>
    </div>
    <div class="login__header">
      <h3>{{ textK('UI_LOGIN_RESET_PASSWORD_TITLE') }}</h3>
    </div>
    <form @submit.prevent="handleResetPassword">
      <div class="input-container">
        <VTextField
          v-ui-test="'reset-password-email'"
          :required="true"
          :type="uiVariant.Secondary"
          name="email"
          input-type="email"
          :label="textK('UI_LOGIN_USERNAME')"
          :value="username"
          @input="onInputChange"
          autocomplete="email"
          ref="emailInput"
          disable-type-validation
          :size="uiSize.Medium"
        ></VTextField>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';
import { LOGIN_STEPS } from '@/src/core/stores/login';
import ArrowIcon from '../../ui/ani-icons/arrow/arrow-icon.vue';
import VTextField from '../../ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '../../ui/ui.types';

export interface Props {
  username: string;
}

defineProps<Props>();
const textK = useText();
const uiVariant = UiVariant;
const uiSize = UiSize;
const emit = defineEmits(['go-back', 'reset-password', 'update-username']);

const goBack = () => {
  emit('go-back', LOGIN_STEPS.RESET_PASSWORD);
};

const handleResetPassword = () => {
  emit('reset-password');
};

const onInputChange = (value: string) => {
  emit('update-username', value);
};
</script>
