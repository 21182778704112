<template>
  <div class="convert-calculator-module-wrapper">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { CONVERT_CALCULATOR_URL } from '@/src/core/settings/integrations';
import { removeElement } from '@/src/core/utils/dom';
import { addScript, getScriptByUrl } from '@/src/core/utils/script';
import Vue from 'vue';
import Component from 'vue-class-component';

const closestInArray = (current: Element, queries: string[]) => {
  for (const query of queries) {
    const element = current.closest(query);

    if (element) {
      return element;
    }
  }

  return null;
};

@Component({
  components: {},
})
export default class ConvertCalculatorModule extends Vue {
  public mounted() {
    const script = getScriptByUrl(CONVERT_CALCULATOR_URL);

    if (script) {
      removeElement(script);
    }

    addScript(CONVERT_CALCULATOR_URL);

    const overflowClassNames = ['.cover-hero-text-module', '.l-content-main-content'];
    const closestOverflowElement = closestInArray(this.$el, overflowClassNames);

    if (!closestOverflowElement) {
      return;
    }

    closestOverflowElement.addEventListener('scroll', () => {
      if (closestOverflowElement?.scrollLeft !== 0) {
        closestOverflowElement.scrollLeft = 0;
      }
    });
  }
}
</script>
