import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { IASM } from '@/src/core/types/interfaces';
import Cookies from 'js-cookie';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AsmChecker extends Vue {
  public authenticationStore = useAuthenticationStore();
  private asmEvaluationTimerRunning: boolean = false;

  private get asm(): IASM {
    return this.authenticationStore.asm;
  }

  public mounted() {
    this.evaluateAsm();
  }

  private evaluateAsm() {
    // Start continous validating the ASM cookie
    if (!this.asmEvaluationTimerRunning) {
      setInterval(() => {
        this.asmEvaluationTimerRunning = true;
        this.evaluateAsm();
      }, 5000);
    }

    if (this.authenticationStore.isAuthenticated) {
      const asmCookie: string = Cookies.get('asm') as string;

      if (asmCookie && asmCookie.length > 0 && asmCookie.indexOf('||') > 0) {
        const asmCookieVal: IASM = {
          Active: true,
          Id: asmCookie.split('||')[0],
          Name: decodeURIComponent(window.atob(asmCookie.split('||')[1])),
          Email: asmCookie.split('||')[2],
        };
        this.authenticationStore.updateAsm({ asm: asmCookieVal });
      } else if (this.asm.Id) {
        this.authenticationStore.updateAsm({ asm: {} as IASM });
      }
    }
  }
}
