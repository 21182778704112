<template>
  <div
    class="notification-container"
    v-ui-test="'notification-pop-up-container'"
    :class="{ 'is-content-site': isContentSite, 'header-expanded': !isHeaderMinimized }"
  >
    <NotificationListItem
      class="notification-list-item"
      :notification="notification"
      v-for="notification in allNotifications"
      :key="notification.Id"
    ></NotificationListItem>
  </div>
</template>

<style lang="scss" src="./notification.scss" scoped></style>

<script lang="ts" setup>
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { computed, watch } from 'vue';
import NotificationListItem from './notification-item/notification-item.vue';

const authenticationStore = useAuthenticationStore();
const notificationsStore = useNotificationsStore();
const uiStore = useUIStore();

const allNotifications = computed(() => {
  return notificationsStore.notifications;
});

const isContentSite = computed(() => {
  return uiStore.site === Site.Content;
});

const isHeaderMinimized = computed(() => {
  return uiStore.navigationMinimized || isContentSite.value;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

watch(
  () => isAuthenticated.value,
  () => {
    notificationsStore.reset();
  },
);
</script>
