import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { pinia } from '@/src/core/utils/pinia-utils';
import { Route } from 'vue-router';

const routeConfigStore = useRouteConfigStore(pinia);
const uiStore = useUIStore();

const FinanceLayout = () => import('@/src/finance/layouts/finance-layout.vue');
const DocumentsLayout = () => import('@/src/finance/layouts/documents/documents-layout.vue');
const AccountStatementLayout = () =>
  import('@/src/finance/layouts/account-statement/account-statement-layout.vue');

const financeRoutes = [
  {
    path: routeConfigStore.getMenuConfigPath('finance', '/finance'),
    name: 'finance',
    redirect: { name: 'documents' },
    component: FinanceLayout,
    meta: {
      loginRestricted: true,
      betaRestricted: 'finance',
    },
    beforeEnter: (to: Route, from: Route, next: () => void) => {
      uiStore.setCurrentSite({ site: Site.Finance });
      next();
    },
    children: [
      {
        path: routeConfigStore.getMenuConfigPath('documents', '/documents'),
        name: 'documents',
        meta: {
          title: 'Billing documents',
          type: 'Finance page',
          loginRestricted: true,
          betaRestricted: 'finance',
        },
        component: DocumentsLayout,
        children: [
          {
            path: routeConfigStore.getMenuConfigPath('documentsSatair', 'satair'),
            name: 'documentsSatair',
            meta: {
              title: 'Billing Documents Satair',
              type: 'Documents Satair page',
              loginRestricted: true,
            },
            components: {
              accountRoute: DocumentsLayout,
            },
          },
          {
            path: routeConfigStore.getMenuConfigPath('documentsAirbus', 'airbus'),
            name: 'documentsAirbus',
            meta: {
              title: 'Billing Documents Airbus',
              type: 'Documents Airbus page',
              loginRestricted: true,
            },
            components: {
              accountRoute: DocumentsLayout,
            },
          },
        ],
      },
      {
        path: routeConfigStore.getMenuConfigPath('account-statement', '/account-statement'),
        name: 'account-statement',
        meta: {
          title: 'Account Statement',
          type: 'Finance page',
          loginRestricted: true,
          betaRestricted: 'finance',
        },
        component: AccountStatementLayout,
        children: [
          {
            path: routeConfigStore.getMenuConfigPath('accountStatementSatair', 'satair'),
            name: 'accountStatementSatair',
            meta: {
              title: 'Account Statement Satair',
              type: 'Account Statement Satair page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountStatementLayout,
            },
          },
          {
            path: routeConfigStore.getMenuConfigPath('accountStatementAirbus', 'airbus'),
            name: 'accountStatementAirbus',
            meta: {
              title: 'Account Statement Airbus',
              type: 'Account Statement Airbus page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountStatementLayout,
            },
          },
        ],
      },
    ],
  },
];

export default financeRoutes;
