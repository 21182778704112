<template>
  <div class="faq-content-wrapper">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInTranslateDown"
      leave-active-class="animated fadeOutTranslateDown"
      mode="out-in"
    >
      <Component :is="component"></Component>
    </transition>
    <Spinner :dark-theme="true" :has-overlay="true" v-if="isBusy"></Spinner>
  </div>
</template>
<style lang="scss" src="./faq-loader.scss"></style>

<script lang="ts" setup>
import AccordionModule from '@/src/content/components/content-modules/accordion-module/accordion-module.vue';
import ContentArticleModule from '@/src/content/components/content-modules/article-module/article-module.vue';
import ContentFullWidthMediaModule from '@/src/content/components/content-modules/full-width-element-module/full-width-element.vue';
import FaqBackButton from '@/src/content/components/faq/faq-back-button/faq-back-button.vue';
import { useFaqStore } from '@/src/content/stores/faq';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import AccordionItem from '@/src/core/components/ui/accordion/accordion-item/accordion-item.vue';
import Accordion from '@/src/core/components/ui/accordion/accordion.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { IWindowServerRendered } from '@/src/core/types/ui';
import Vue, { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch } from 'vue';

export interface IFaqSrData {
  categories: [];
  parent: string;
}

const uiStore = useUIStore();
const faqStore = useFaqStore();
const component = ref<string | null>(null);
const vm = getCurrentInstance()?.proxy;

const site = Site;

const content = computed(() => {
  return faqStore.content;
});

const isBusy = computed(() => {
  return faqStore.isBusy;
});

const createComponent = () => {
  const name: string = 'faqPage';

  Vue.component(name, {
    template: content.value,
    components: {
      Accordion,
      AccordionItem,
      ArrowIcon,
      ContentArticleModule,
      ContentAccordionModule: AccordionModule,
      FaqBackButton,
      MediaHandler,
      ContentFullWidthMediaModule,
    },
  });
  component.value = name;
};

watch(
  // TODO: Replace with useRoute.route after the Vue 3 update
  () => vm?.$route,
  () => {
    if (uiStore.site === site.Content) {
      faqStore.fetchFaqContent({ contentURL: vm?.$route.path || '' });
    }
  },
  { deep: true },
);

watch(content, () => {
  if (!content.value) {
    return;
  }
  createComponent();
});

onMounted(() => {
  if (content.value) {
    createComponent();
  }

  const srContent: HTMLElement | undefined = (window as unknown as IWindowServerRendered).srContent;
  const srData: IFaqSrData | undefined = (window as unknown as IWindowServerRendered)
    .srData as IFaqSrData;

  if (srContent) {
    faqStore.setFaqContent({
      content: srContent.outerHTML,
      contentURL: srContent.getAttribute('sr-content') || '',
      categories: srData && srData.categories,
      parent: srData && srData.parent,
    });

    (window as unknown as IWindowServerRendered).srContent = undefined;
    (window as unknown as IWindowServerRendered).srData = undefined;
  } else {
    faqStore.fetchFaqContent({ contentURL: vm?.$route.path || '' });
  }
});

onBeforeUnmount(() => {
  faqStore.reset();
});
</script>
