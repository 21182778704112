<template>
  <div class="table-module-wrapper">
    <div class="table-module">
      <div class="table-module__inner">
        <slot name="header" v-if="$slots.header"></slot>
        <div
          class="table-module-component-wrapper"
          :class="{
            'theme--dark': theme === 'dark',
            'theme--light': theme === 'light',
            'fixed-col': fixedFirstCol,
          }"
        >
          <div>
            <div class="content-table-wrapper">
              <div
                class="content-table-overflow"
                ref="scroller"
                @scroll.passive="fixedFirstCol ? onScroll($event) : () => {}"
              >
                <slot name="table"></slot>
              </div>
              <div
                :class="{ 'is-scrolled': isScrolled }"
                class="content-table-follower"
                v-if="fixedFirstCol && loaded"
                v-html="outPutRef"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./table.scss"></style>

<script lang="ts">
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InViewWatcher,
  },
})
export default class ContentTableModule extends Vue {
  public $refs: {
    scroller: HTMLElement;
  };

  @Prop() public theme: string;
  @Prop(Boolean) public fixedFirstCol: boolean;

  public isScrolled: boolean = false;

  /*tslint:disable:no-any*/
  // TODO: "ref" is not a VNODE (or at least it doesn't work, if defined as one)
  public ref: any = '';
  public outPutRef: HTMLElement | '' = '';
  public loaded: boolean = false;

  @Watch('ref') private refUpdated(val: any) {
    this.outPutRef = val[0].elm.outerHTML;

    if (!this.loaded) {
      this.loaded = true;
    }
  }

  /*tslint:enable:no-any*/

  public onScroll() {
    const scrolled = (this.$refs as any).scroller.scrollLeft;
    this.isScrolled = scrolled > 0;
  }

  public mounted() {
    if (this.fixedFirstCol) {
      this.ref = this.$slots.table;
    }
  }
}
</script>
