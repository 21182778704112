<template>
  <span class="checkbox-wrapper" :class="disabled">
    <input
      type="checkbox"
      ref="checkbox"
      :id="id"
      :checked="value"
      @input="inputChange"
      :name="name"
      :disabled="disabled"
    />
    <label class="label" :for="id">
      <Checkmark class="checkmark" :is-active="value" :type="UiVariant.Primary"></Checkmark>
      <slot></slot>
    </label>
  </span>
</template>

<style lang="scss" src="./checkbox.scss" scoped></style>

<script lang="ts" setup>
import { ref } from 'vue';

import Checkmark from '@/src/core/components/ui/ani-icons/checkmark/checkmark.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';

interface Props {
  label?: string;
  name?: string;
  disabled?: boolean;
  value?: boolean;
}

withDefaults(defineProps<Props>(), {
  label: '',
  name: '',
  disabled: false,
  value: false,
});

const id: string = new GenerateUniqueIdentifier().uuid4();
const checkbox = ref<HTMLInputElement | null>(null);
const emit = defineEmits(['input']);

const inputChange = (event: Event) => {
  emit('input', (event.target as HTMLInputElement).checked);
};
</script>
