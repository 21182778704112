<template>
  <div class="l_standard-sub-page">
    <UserDetails v-if="isAuthenticated"></UserDetails>
  </div>
</template>

<style src="./edit-user-layout.scss" lang="scss" scoped></style>

<script lang="ts">
import UserDetails from '@/src/account/components/admin/user-details/user-details.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    UserDetails,
  },
})
export default class EditUserLayout extends Vue {
  public authenticationStore = useAuthenticationStore();

  public get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }
}
</script>
