import { ListApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { QuoteList } from '@/src/core/types/api';
import { IQuoteSingle } from '@/src/core/types/interfaces';
import { useUserStore } from '@/src/profile/stores/user';
import { defineStore } from 'pinia';

interface QuoteSingleState {
  quoteSingle: IQuoteSingle;
  isBusy: boolean;
  isSingleQuote: boolean;
}

export const useQuoteSingleStore = defineStore('quoteSingle', {
  state: (): QuoteSingleState => ({
    quoteSingle: {
      CustomerEmail: '',
      Priority: 'RTN',
      RequestedDate: '',
      ShippingAddressId: '',
      Reference: '',
      ItemId: '',
      ItemRequestedQuantity: '1',
      ListCode: '',
    },
    isBusy: false,
    isSingleQuote: false,
  }),
  actions: {
    async submitQuoteList() {
      this.isBusy = true;

      if (!this.quoteSingle.RequestedDate) {
        this.quoteSingle.RequestedDate = '';
      }

      const { IsSuccess } = await Req({
        url: ListApi.CreateQuoteFromList,
        data: { createQuote: this.populateQuoteList() },
        method: 'POST',
      });

      if (IsSuccess) {
        this.reset();
      }

      this.isBusy = false;

      return { IsSuccess };
    },
    populateQuoteList() {
      const userStore = useUserStore();
      const userEmail = userStore.currentUser?.Email;

      return {
        CustomerEmail: userEmail,
        Reference: this.quoteSingle.Reference,
        Priority: this.quoteSingle.Priority,
        RequestedDate: this.quoteSingle.RequestedDate,
        ShippingAddressId: this.quoteSingle.ShippingAddressId,
        QuoteItems: [
          {
            Id: this.quoteSingle.ItemId,
            RequestedQuantity: this.quoteSingle.ItemRequestedQuantity,
          },
        ],
      } as QuoteList;
    },
    quoteSingleUpdated(payload: IQuoteSingle) {
      this.quoteSingle = Object.assign(this.quoteSingle, payload);
    },
    reset() {
      this.quoteSingle = Object.assign(
        {},
        {
          CustomerEmail: '',
          Priority: 'RTN',
          RequestedDate: '',
          ShippingAddressId: '',
          Reference: '',
          ItemId: '',
          ItemRequestedQuantity: '1',
          ListCode: '',
        },
      );
    },
  },
});
