<template>
  <section class="product-details-section" v-if="enableRecommendations">
    <div class="product-details-row">
      <div ref="recommendations" class="recommendation-list" v-ui-test="'recommendation-list'">
        <div class="recommendation-list-header typeset">
          <h3 v-if="!hasInterchangeableProducts">{{ textK('UI_RECOMMENDATION_HEADLINE') }}</h3>
          <p>{{ textK('UI_RECOMMENDATION_DISCLAIMER') }}</p>
        </div>
        <ProductDetailsRelationsLoader
          v-for="item in products.slice(0, results)"
          class="list-animation-item"
          :sku="sku"
          :item="item"
          :is-interchangeable-item="false"
          :key="item.Sku"
        >
        </ProductDetailsRelationsLoader>
        <button v-if="products.length > startResults" class="load-more" @click="loadMore">
          <span v-if="results === startResults">Show all</span>
          <span v-else>Show less</span>
        </button>
      </div>
    </div>
  </section>
</template>
<style scoped src="./product-details-recommendation-list.scss" lang="scss"></style>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { useRoute } from '@/src/core/utils/router';
import ProductDetailsRelationsLoader from '@/src/market/components/product/product-details/product-details-relations-loader/product-details-relations-loader.vue';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import { computed, onMounted, ref } from 'vue';

interface Props {
  sku: string;
}

const props = defineProps<Props>();

const textK = useText();
const productRelationsStore = useProductRelationsStore();
const router = useRoute();
const startResults = ref(3);
const results = ref(3);
const baseUrl = ref('');

const products = computed(() => productRelationsStore.recommendationsBySku(props.sku));
const hasInterchangeableProducts = computed(() =>
  productRelationsStore.hasInterchangeables(props.sku),
);

const loadMore = () => {
  if (products.value.length === 0) return;
  results.value =
    results.value === products.value.length ? startResults.value : products.value.length;
};

const enableRecommendations = computed(() => hasFeature(FEATURES.RECOMMENDATIONS));

onMounted(() => {
  const routePath = router.path.split('/');
  baseUrl.value = routePath.slice(0, routePath.length - 2).join('/') + '/';
});
</script>
