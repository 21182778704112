import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { Route } from 'vue-router';

const routeConfigStore = useRouteConfigStore();

const ProfileLayout = () => import('@/src/profile/layouts/profile-layout.vue');
const ProfileDetails = () =>
  import('@/src/profile/components/profile/profile-details/profile-details.vue');
const ProfilePermissions = () =>
  import('@/src/profile/components/profile/profile-permissions/profile-permissions.vue');
const uiStore = useUIStore();

const profileRoutes = [
  {
    path: routeConfigStore.getMenuConfigPath('user', '/user'),
    component: ProfileLayout,
    redirect: { name: 'profile' },
    meta: {
      loginRestricted: true,
    },
    beforeEnter(to: Route, from: Route, next: () => void) {
      uiStore.setCurrentSite({ site: Site.Profile });
      next();
    },
    children: [
      {
        path: routeConfigStore.getMenuConfigPath('profile', 'profile'),
        name: 'profile',
        meta: {
          title: 'Personal Details',
          type: 'Profile page',
          loginRestricted: true,
        },
        component: ProfileDetails,
      },
      {
        path: routeConfigStore.getMenuConfigPath('notifications', 'notifications'),
        name: 'profilePermissions',
        meta: {
          title: 'Notifications Preferences',
          type: 'Profile page',
          loginRestricted: true,
        },
        component: ProfilePermissions,
      },
    ],
  },
];

export default profileRoutes;
