<template>
  <footer class="footer" :class="{ 'dark-mode': darkmode }">
    <div class="logo-container">
      <div class="footer-logo">
        <slot name="logo" />
      </div>
      <span class="legal">
        <a class="cky-banner-element" @click="'#';" v-ui-test="'footer-cookie-link'">{{
          textK('UI_TERMS_COOKIE_POLICY')
        }}</a>
        <div class="legal-line"></div>
        <slot name="legal" />
      </span>
    </div>
  </footer>
</template>

<style lang="scss" src="./footer.scss" />

<script lang="ts">
// Declare the component name to avoid the warning of using a built-in or reserved HTML elements
export default { name: 'VFooter' };
</script>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';

interface Props {
  darkmode?: boolean;
}
withDefaults(defineProps<Props>(), {
  darkmode: false,
});

const textK = useText();
</script>
