<template>
  <div class="login-modal" :class="{ 'one-portal': isOnePortal, 'new-login': showNewLogin }">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Medium">
      <template #body>
        <div class="inner-body">
          <Login @openNeedHelp="isOpenNeedHelpModal"></Login>
          <div
            class="get-access-section"
            @click="requestAccessModal()"
            v-if="enableGetAccess && !siteInMaintenanceMode"
          >
            <AdobeTargetWrapper id="login-get-access" v-if="!isOnePortal">
              <RequestAccountNativeBanner />
            </AdobeTargetWrapper>
          </div>
        </div>
      </template>
    </StandardDialogModal>
  </div>
</template>

<style lang="scss" src="./ModalLogin.scss" scoped></style>

<script lang="ts" setup>
import AdobeTargetWrapper from '@/src/core/components/adobe-target-wrapper/adobe-target-wrapper.vue';
import Login from '@/src/core/components/login/login.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import RestrictedRouteChecker from '@/src/core/router/restricted-route-checker/restricted-route-checker';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useMaintenanceStore } from '@/src/core/stores/maintenance';
import { useModalStore } from '@/src/core/stores/modal';
import { computed, onUnmounted, ref, watch } from 'vue';
import RequestAccountNativeBanner from './request-account-banners/request-account-native-banner/request-account-native-banner.vue';

import { useRoute, useRouter } from '@/src/core/utils/router';

const uiSize = UiSize;
const authenticationStore = useAuthenticationStore();
const maintenanceStore = useMaintenanceStore();
const modalStore = useModalStore();
const isRedirectToSearch = ref(false);
const route = useRoute();
const router = useRouter();

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const modalParams = computed(() => {
  return modalStore.params as IRenameCartModalParams;
});

const enableGetAccess = computed(() => {
  return hasFeature(FEATURES.GET_ACCESS);
});

const showNewLogin = computed(() => {
  return hasFeature(FEATURES.NEW_LOGIN_DESIGN);
});

const requestAccessModal = (type = 'user') => {
  const backToModal = 'ModalLogin';
  modalStore.closeModal();
  modalStore.showModal({
    modalComponent: 'ModalRequestAccess',
    params: { type, backToModal },
  });
};

const siteInMaintenanceMode = async () => {
  await maintenanceStore.fetchMaintenanceModeStatus();
  return maintenanceStore.maintenanceMode;
};

const isOnePortal = computed(() => {
  return hasFeature(FEATURES.ONE_PORTAL_WAVE_0);
});

const reRoute = async () => {
  await router.push(await RestrictedRouteChecker(router.currentRoute.matched)).catch(() => {
    /**/
  });
};

const isOpenNeedHelpModal = (open: boolean) => {
  isRedirectToSearch.value = open;
};

watch(
  () => isAuthenticated.value,
  () => {
    if (modalParams.value && modalParams.value.next) {
      modalParams.value.next();
    }
    modalStore.closeModal();
  },
);

onUnmounted(() => {
  const restrictedByLogin: boolean = !!route.meta?.loginRestricted && route.meta?.loginRestricted;
  // re route user, is they close (destroy) LOGIN modal on restricted page
  if (!isAuthenticated.value && restrictedByLogin && !isRedirectToSearch.value) {
    reRoute();
  }
});

export interface IRenameCartModalParams {
  next: () => void;
}
</script>
