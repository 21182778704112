<template>
  <section class="product-details-section">
    <div class="product-details-row">
      <div class="product-details-image">
        <product-details-gallery v-if="hasImage" :id="product.Id">
          <img :src="defaultImage?.url" :alt="defaultImage?.alt" />
        </product-details-gallery>

        <product-details-gallery v-else>
          <svg class="placeholder-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
            />
          </svg>
        </product-details-gallery>
      </div>
      <div class="product-details-information">
        <ul class="product-details-list">
          <li class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCTPAGE_MANUFACTURER') }}
              </p>
              <p
                class="product-details-list-item-value"
                v-ui-test="'productDetailsManufacturerAid'"
              >
                <airbus-logo v-if="manufacturer"></airbus-logo>
                <template v-else>{{ product.ProductManufacturer.Name }}</template>
                {{ product.ProductManufacturer.CageCode }}
              </p>
            </div>
          </li>

          <li class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCT_SUPPLIER') }}
              </p>
              <product-details-sold-by :seller="product.SupplierDisplay"></product-details-sold-by>
            </div>
          </li>

          <!-- Condition -->
          <product-details-condition
            :seller="product.SupplierDisplay"
            :offer-state="offer.State"
            :id="product.Id"
            :owning-system="product.OwningSystem"
          ></product-details-condition>
          <!-- /Condition -->

          <!-- Certificate Type -->
          <li class="product-details-list-item">
            <product-details-certificate-type
              :id="product.Id"
              :owning-system="product.OwningSystem"
            ></product-details-certificate-type>
          </li>
          <!-- /Certificate Type -->

          <!-- Serial Number (Marketpalce / Airbus)-->
          <product-details-serial-number
            :id="product.Id"
            :owning-system="product.OwningSystem"
            :offer-serial-number="offer.SerialNumber"
          ></product-details-serial-number>
          <!-- /Serial Number (Marketpalce / Airbus)-->

          <!-- Availability -->
          <li v-if="isAuthenticated" class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCT_AVAILABILITY') }}
              </p>
              <p class="product-details-list-item-value" v-ui-test="'stock-indication'">
                <product-availability
                  force-fetch
                  :id="product.Id"
                  :owning-system="product.OwningSystem"
                  :offer-quantity="offer.Quantity"
                  :add-to-basket-quantity="product.AddToBasketQty"
                ></product-availability>
              </p>
            </div>
          </li>
          <!-- /Availability -->

          <!-- Ship from -->
          <li v-if="isAuthenticated" class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCT_LOCATION') }}
              </p>
              <p class="product-details-list-item-value" v-ui-test="'stock-location'">
                <product-location
                  force-fetch
                  :id="product.Id"
                  :owning-system="product.OwningSystem"
                ></product-location>
              </p>
              <div class="product-details-list-item-link">
                <product-details-warehouse-button
                  :id="product.Id"
                  :owning-system="product.OwningSystem"
                ></product-details-warehouse-button>
              </div>
            </div>
          </li>
          <!-- /Ship from -->

          <li v-if="hasHazmatCode" class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCTPAGE_HAZMAT_UN_CODE') }}
              </p>
              <p class="product-details-list-item-value" v-ui-test="'productDetailsHazmat'">
                <svgicon class="hazmat-icon" name="ui-hazmat"></svgicon> {{ product.HazmatCode }}
              </p>
            </div>
          </li>

          <product-details-airbus-hazmat
            v-if="isAirbusHazmat"
            :product-id="product.Id"
            :owning-system="product.OwningSystem"
          ></product-details-airbus-hazmat>

          <li v-if="isFlammable" class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCTPAGE_FLAMMABLE_LABEL') }}
              </p>
              <p class="product-details-list-item-value" v-ui-test="'productDetailsFlammable'">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2137 8.14213L7.99992 1.92834L1.78614 8.14213L7.99992 14.3559L14.2137 8.14213Z"
                    fill="#FFA800"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99992 1.47546L14.6666 8.14213L7.99992 14.8088L1.33325 8.14213L7.99992 1.47546ZM7.99992 2.38124L2.23903 8.14213L7.99992 13.903L13.7608 8.14213L7.99992 2.38124Z"
                    fill="black"
                  />
                  <path
                    d="M9.06766 6.03923C8.59559 5.66885 7.89839 5.4074 7.28835 5.28394C7.60789 6.5113 6.90343 7.08503 6.52578 7.52804C6.11182 8.01463 5.83585 8.56658 5.85763 9.23473C5.87216 9.75037 6.14813 10.266 6.55483 10.5928C6.57662 10.6073 6.59841 10.6291 6.6202 10.6437C6.85986 10.1716 7.24477 9.57607 7.84756 9.12579C8.42856 9.70679 8.76989 10.3096 8.92967 10.6945C9.39447 10.3895 9.96094 10.1716 10.3313 9.21294C10.8034 7.99284 9.96821 6.77275 9.06039 6.05376L9.06766 6.03923Z"
                    fill="black"
                  />
                </svg>
                Yes
              </p>
            </div>
          </li>

          <!-- Material status (Airbus) -->
          <product-details-material-status
            :product-id="product.Id"
            :owning-system="product.OwningSystem"
          ></product-details-material-status>
          <!-- /Material status (Airbus) -->

          <!-- Payment (Marketplace) -->
          <li v-if="hasPaymentTerms" class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_SELLERPAGE_PAYMENTTERMS') }}
              </p>
              <p class="product-details-list-item-value" v-ui-test="'productDetailsSupplier'">
                {{ seller.PaymentTerms }}
              </p>
            </div>
          </li>
          <!-- /Payment (Marketplace) -->

          <!-- Delivery Terms (Marketplace) -->
          <li v-if="product.IsMarketplace" class="product-details-list-item">
            <div class="product-details-list-item-content">
              <p class="product-details-list-item-title product-details-title-label">
                {{ textK('UI_PRODUCTPAGE_DELIVERYTERMS') }}
              </p>
              <p class="product-details-list-item-value" v-ui-test="'productDetailsSupplier'">
                <template v-if="!offer.Shop.TermsOfDelivery">
                  {{ textK('UI_NODATA_HASNODATA') }}
                </template>
                <template v-else>
                  {{ offer.Shop.TermsOfDelivery.Name }} ({{ offer.Shop.TermsOfDelivery.Code }})
                </template>
              </p>
            </div>
          </li>
          <!-- /Delivery Terms (Marketplace) -->
        </ul>
      </div>
      <product-details-actions
        class="product-details-cta"
        :id="product.Id"
        :owning-system="product.OwningSystem"
        :seller-id="sellerId"
      />
    </div>

    <template v-if="product.Description">
      <div class="product-details-title-label">{{ textK('UI_PRODUCTPAGE_DESCRIPTION') }}</div>
      <div class="product-details-description typeset">
        <p v-ui-test="'productDetailsDescription'">{{ product.Description }}</p>
      </div>
    </template>

    <template v-if="offer.Description">
      <div class="product-details-title-label">{{ textK('UI_PRODUCTPAGE_DESCRIPTION') }}</div>
      <div class="product-details-description typeset">
        <p v-ui-test="'productDetailsDescription'">{{ offer.Description }}</p>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';
import { MarketplaceMasterShop, Product, ProductOffer } from '@/src/core/types/api';
import { computed } from 'vue';

import AirbusLogo from '@/src/core/components/ui/detailed-icons/airbus-logo/airbus-logo.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import ProductAvailability from '@/src/market/components/product/product-availability/product-availability.vue';
import ProductDetailsActions from '@/src/market/components/product/product-details/product-details-actions/product-details-actions.vue';
import ProductDetailsAirbusHazmat from '@/src/market/components/product/product-details/product-details-airbus-hazmat/product-details-airbus-hazmat.vue';
import ProductDetailsCertificateType from '@/src/market/components/product/product-details/product-details-certificate-type/product-details-certificate-type.vue';
import ProductDetailsCondition from '@/src/market/components/product/product-details/product-details-condition/product-details-condition.vue';
import ProductDetailsGallery from '@/src/market/components/product/product-details/product-details-gallery/product-details-gallery.vue';
import ProductDetailsMaterialStatus from '@/src/market/components/product/product-details/product-details-material-status/product-details-material-status.vue';
import ProductDetailsSerialNumber from '@/src/market/components/product/product-details/product-details-serial-number/product-details-serial-number.vue';
import ProductDetailsSoldBy from '@/src/market/components/product/product-details/product-details-sold-by/product-details-sold-by.vue';
import ProductDetailsWarehouseButton from '@/src/market/components/product/product-details/product-details-warehouse-button/product-details-warehouse-button.vue';
import ProductLocation from '@/src/market/components/product/product-location/product-location.vue';

const props = defineProps<{
  product: Product;
  offer: ProductOffer;
  seller: MarketplaceMasterShop;
}>();

const textK = useText();
const authenticationStore = useAuthenticationStore();

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const sellerId = computed(() => {
  if (props.seller && props.seller.LeadShopId) {
    return props.seller.LeadShopId;
  }
  return 'satair';
});

const manufacturer = computed(() => props.product?.AirbusProprietary);

const hasHazmatCode = computed(() => props.product && props.product.HazmatCode !== 'N/A');

const isAirbusHazmat = computed(() => props.product.IsAirbusHazmat);

const isFlammable = computed(() => props.product.Flammable);

const hasImage = computed(() => props.product.HasImage);

const hasPaymentTerms = computed(() => props.seller && props.seller.PaymentTerms);

const defaultImage = computed(() => {
  if (hasImage.value) {
    return {
      url: `${props.product.Images[0].CdnUrl}&width=200&height=200&mode=crop`,
      alt: props.product.Images[0].AltText,
    };
  }
  return null;
});
</script>
