<template>
  <ul class="content-navigation" v-if="site === 0">
    <li class="content-navigation__item" v-for="(item, i) in navigation" :key="i">
      <router-link :to="item.Url" v-slot="{ href, navigate, isActive, isExactActive }">
        <a
          v-if="item.SubItems.length > 0"
          class="content-navigation__item__link"
          :class="{
            active: isActive,
            'exact-active': isExactActive,
            open: currentDropdownId === item.Id,
            closed: currentDropdownId !== item.Id && currentDropdownId !== null,
          }"
          @click.prevent="setNavigationDropdown(item.Id)"
        >
          {{ item.Name }}
        </a>
        <a
          v-else
          class="content-navigation__item__link"
          :class="{
            active: isActive,
            'exact-active': isExactActive,
            open: currentDropdownId === item.Id,
            closed: currentDropdownId !== item.Id && currentDropdownId !== null,
          }"
          @click="
            ($event) => {
              navigate($event);
              setNavigationDropdown(null);
            }
          "
          :href="href"
        >
          {{ item.Name }}
        </a>
      </router-link>

      <NavigationDropdown
        :lock-scroll="true"
        :dropdown-id="item.Id"
        @click="openDropDown = null"
        :size="uiSize.Large"
      >
        <NavigationDropdownContainer>
          <NavigationDropdownColumn v-for="column in item.SubItems" :key="column.Id">
            <router-link :to="column.Url" v-slot="{ href, navigate, isActive, isExactActive }">
              <a
                v-if="!column.NoLink"
                class="navigation-dropdown-column__title"
                :class="{ active: isActive, 'exact-active': isExactActive }"
                :href="href"
                @click="navigate"
                v-ui-test="'content-navigation-item'"
                >{{ column.Name }}</a
              >
              <span
                v-else
                class="navigation-dropdown-column__title"
                :class="{ active: isActive, 'exact-active': isExactActive }"
                v-ui-test="'content-navigation-item'"
                >{{ column.Name }}</span
              >
            </router-link>
            <ContentNavigationList :navigation="column.SubItems" :expanded="true" />
          </NavigationDropdownColumn>
        </NavigationDropdownContainer>
      </NavigationDropdown>
    </li>
  </ul>
</template>

<style lang="scss" src="./content-navigation.scss" sc />

<script lang="ts">
import ContentNavigationList from '@/src/content/components/content-navigation/content-navigation-list/content-navigation-list.vue';
import NavigationDropdownColumn from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-column/navigation-dropdown-column.vue';
import NavigationDropdownContainer from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-container/navigation-dropdown-container.vue';
import NavigationDropdown from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useNavigationStore } from '@/src/core/stores/navigation';
import { useUIStore } from '@/src/core/stores/ui';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    NavigationDropdown,
    NavigationDropdownContainer,
    NavigationDropdownColumn,
    ContentNavigationList,
  },
})
export default class ContentNavigation extends Vue {
  public authenticationStore = useAuthenticationStore();
  public navigationStore = useNavigationStore();
  public uiStore = useUIStore();
  public uiSize = UiSize;

  public get site() {
    return this.uiStore.site;
  }

  public get navigation() {
    return this.navigationStore.menu;
  }

  private get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }

  public get currentDropdownId() {
    return this.uiStore.navigationDropdownId;
  }

  public setNavigationDropdown(id: string) {
    if (id === this.currentDropdownId) {
      this.uiStore.setNavigationDropdownId({ id: null });
    } else {
      this.uiStore.setNavigationDropdownId({ id });
    }
  }

  @Watch('$route') private routeChange() {
    this.uiStore.setNavigationDropdownId({ id: null });
  }

  @Watch('isAuthenticated') private authChange() {
    this.navigationStore.fetchMenu({ force: true });
  }

  public mounted() {
    this.navigationStore.fetchMenu();
  }
}
</script>
