<template>
  <div>
    <div
      class="textarea-container"
      :class="{
        'is-active':
          presumedValue || isFocused || currentValue.length > 0 || placeholder || prefix || error,
        'has-value': currentValue.length > 0,
        error: error,
        'is-focused': isFocused || error,
        small: size === uiSize.Small,
        medium: size === uiSize.Medium,
        large: size === uiSize.Large,
        auto: size === uiSize.Auto,
        'shop-site': context === uiContext.ShopSite,
        'content-site': context === uiContext.ContentSite,
        default: type === uiVariant.Default || type === uiVariant.Primary,
        secondary: type === uiVariant.Secondary,
        transparent: type === uiVariant.Transparent,
        'is-required': required,
        'no-label': !label,
        'compressed-view': compressedView,
        'has-icon-after': $slots.append,
        'has-icon-before': $slots.prepend,
      }"
    >
      <div class="text-area-icon-before" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </div>
      <label class="textarea-label" v-if="label" :for="id" :class="{ 'can-animate': canAnimate }">{{
        label
      }}</label>
      <textarea
        ref="inputfield"
        @click="click()"
        @focus="focus()"
        @blur="blur()"
        @keyup="keyup($event)"
        @input="input()"
        @animationstart="checkAutoFill"
        @keydown.stop
        :type="inputType"
        :name="name"
        :id="id || uniqueId()"
        :disabled="!!disabled || silentDisabled"
        :required="!!required"
        :readonly="!!readonly"
        :placeholder="placeholder"
        :maxlength="vmaxLength"
        :minlength="vminLength"
        :autocomplete="autocomplete"
        v-model="currentValue"
        class="textarea"
        :class="{ 'silent-disabled': silentDisabled }"
        :style="prefixPush"
      />
      <div class="text-area-icon-after" v-if="$slots.append">
        <slot name="append"></slot>
      </div>
    </div>
    <TransitionExpand transform>
      <div class="error-message" v-if="dirty && error">
        <transition name="fade" mode="out-in">
          <span key="1" v-if="required && currentValue.length <= 0">{{
            textK('UI_INPUTERROR_EMPTY')
          }}</span>
        </transition>
      </div>
    </TransitionExpand>
  </div>
</template>

<style lang="scss" src="./textarea.scss" scoped></style>

<script lang="ts" setup>
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import { UiContext, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';
import { onMounted, ref, watch } from 'vue';

interface Props {
  value?: string | number;
  prefix?: string;
  inputType?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  id?: string;
  size?: UiSize;
  type?: UiVariant;
  context?: UiContext;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  disableTypeValidation?: boolean;
  silentDisabled?: boolean;
  autocomplete?: string;
  vmaxLength?: number;
  vminLength?: number;
  compressedView?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  inputType: 'text',
  name: '',
  label: '',
  placeholder: '',
  id: '',
  size: UiSize.Medium,
  type: UiVariant.Default,
  context: UiContext.ShopSite,
  required: false,
  autocomplete: undefined,
  vmaxLength: undefined,
  vminLength: undefined,
  prefix: undefined,
});

const textK = useText();
const uiSize = UiSize;
const uiVariant = UiVariant;
const uiContext = UiContext;
const inputfield = ref<HTMLInputElement | null>(null);
const prefixEle = ref<HTMLElement | null>(null);
const presumedValue = ref(false);
const isFocused = ref(false);
const prefixPush = ref({});
const canAnimate = ref(false);
const dirty = ref(false); // check if we should validate field
const error = ref(false);
const currentValue = ref<string | number>('');
const emit = defineEmits(['focus', 'click', 'blur', 'input', 'change', 'keyup', 'valid']);

const validate = (currentValue: string) => {
  error.value = props.required && currentValue.length <= 0;
  emitIsValid(!error.value);
};

const focus = () => {
  if (props.readonly) {
    return;
  }

  isFocused.value = true;
  emit('focus', currentValue.value);
};

const click = () => {
  emit('click', currentValue.value);
};

const blur = () => {
  const cv = '' + currentValue.value;
  validate(cv);
  isFocused.value = false;
  emit('blur', cv);
  dirty.value = props.required || cv.length > 0;
};

const keyup = ($event: KeyboardEvent) => {
  emit('keyup', $event);
};

const input = () => {
  emit('input', currentValue.value);
  emit('change', currentValue.value);
};

const emitIsValid = (valid: boolean) => {
  emit('valid', valid);
};

// Chrome autofill hack
const checkAutoFill = (event: AnimationEvent) => {
  if (event.animationName.indexOf('onAutoFillStart') > -1) {
    isFocused.value = true;
  }
};

const uniqueId = () => {
  return new GenerateUniqueIdentifier().uuid4();
};

watch(
  () => props.value,
  (val: string | number, oldVal: string | number) => {
    if (val === oldVal) {
      return;
    }
    currentValue.value = '' + val;
  },
);

watch(
  () => currentValue.value,
  (val: string) => {
    validate(val);
  },
);

onMounted(() => {
  currentValue.value = props.value || '';

  if (props.prefix && prefixEle.value) {
    prefixPush.value = {
      paddingLeft: 'calc(1em + ' + (prefixEle.value.offsetWidth + 5) + 'px)',
    };
  }

  // AVOID ANIMATING LABEL ON LOAD
  setTimeout(() => {
    canAnimate.value = true;
  }, 250);
});
</script>
