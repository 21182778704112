<template>
  <ul class="content-navigation-list" v-if="navigation.length > 0">
    <li
      v-for="navigationItem in navigation"
      :key="navigationItem.Url"
      class="content-navigation-list__item"
    >
      <router-link :to="navigationItem.Url" v-slot="{ href, navigate, isActive, isExactActive }">
        <ContentNavigationListItem
          :no-link="navigationItem.NoLink"
          :navigation-item="navigationItem"
          :route-href="href"
          :route-navigate="navigate"
          :active="isActive"
          :exact-active="isExactActive"
        >
        </ContentNavigationListItem>
      </router-link>
    </li>
  </ul>
</template>

<style lang="scss" src="./content-navigation-list.scss" />

<script lang="ts">
import ContentNavigationListItem from '@/src/content/components/content-navigation/content-navigation-list/content-navigation-list-item/content-navigation-list-item.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import { NavigationItem } from '@/src/core/types/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ContentNavigationListItem,
    TransitionExpand,
  },
})
export default class ContentNavigationList extends Vue {
  @Prop({ default: [] }) public navigation: NavigationItem[];
}
</script>
