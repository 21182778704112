<template>
  <VSelect
    :disabled="disabled"
    :required="required"
    :options-skeleton="workAreas.length === 0"
    :label="textK('UI_COMMON_PRIMARYWORKAREA')"
    :value="workAreas.indexOf(value)"
    :placeholder="value"
    @input="handleInput"
    :is-input-field="false"
    :options="workAreas"
    :type="uiVariant.Secondary"
    :ui-test-name-dropdown="'primary-workarea-dropdown-option'"
    @valid="$emit('valid', $event)"
    @open="fetchWorksAreas()"
  />
</template>

<script lang="ts" setup>
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useTextsStore } from '@/src/core/stores/texts';
import { computed } from 'vue';

interface Props {
  value: string;
  disabled?: boolean;
  required?: boolean;
}

withDefaults(defineProps<Props>(), {
  value: '',
  disabled: false,
  required: false,
});

const textK = useText();
const textsStore = useTextsStore();
const uiVariant = UiVariant;
const emit = defineEmits(['valid', 'input']);

const workAreas = computed(() => {
  return textsStore.workAreas;
});

const fetchWorksAreas = () => {
  if (workAreas.value.length <= 0) {
    textsStore.fetchWorkAreas();
  }
};

const handleInput = (inputValue: number) => {
  const selectedValue = workAreas.value[inputValue];

  if (selectedValue) {
    emit('input', selectedValue);
  }
};
</script>
