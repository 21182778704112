<template>
  <div class="product-details-seller__logo-wrapper" v-ui-test="'product-details-supplier-logo'">
    <span class="product-details-seller__logo-title">{{
      textK('UI_PRODUCT_SOLD_HANDLED_BY')
    }}</span>
    <img v-if="logo" class="product-details-seller__logo" :src="logo" :alt="alt" />
    <h3 v-else class="product-details-seller__title">{{ supplierName }}</h3>
  </div>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { OwningSystem } from '@/src/core/types/api';
import { computed } from 'vue';

interface Props {
  supplierLogo: string;
  supplierDisplayName: string;
  offerSupplierLogo?: string;
  masterShopName?: string;
  owningSystem: OwningSystem;
}

const props = defineProps<Props>();

const textK = useText();

const isNotMarketPlace = computed(() => props.owningSystem !== OwningSystem.MIRAKL);

const supplierName = computed(() =>
  isNotMarketPlace.value ? props.supplierDisplayName : props.masterShopName,
);

const logo = computed(() =>
  isNotMarketPlace.value ? props.supplierLogo : props.offerSupplierLogo,
);

const alt = computed(() => (supplierName.value ? `${supplierName.value} logo` : 'Supplier logo'));
</script>
