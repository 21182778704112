<template>
  <section
    class="product-details-section product-details-interchangeables"
    ref="tabGroup"
    v-ui-test="'product-details-relations-tab-group'"
    v-if="(hasInterchangeables && !isMarketplace) || recommendationProducts.length > 0"
  >
    <div class="product-details-row">
      <div class="product-details-relations-tab-group">
        <Tab
          v-if="hasInterchangeables && recommendationProducts.length > 0 && enableRecommendations"
        >
          <TabButton
            :active="component === ProductDetailsInterchangeableList"
            :type="uiVariant.Secondary"
            v-ui-test="'tab-product-details-interchangeables'"
            @click.native="switchComponent('ProductDetailsInterchangeableList')"
          >
            {{ textK('UI_INTERCHANGEABLES_TAB_HEADLINE') }}
          </TabButton>

          <TabButton
            :active="component === ProductDetailsRecommendationList"
            :type="uiVariant.Secondary"
            v-ui-test="'tab-product-details-recommendation'"
            @click.native="switchComponent('ProductDetailsRecommendationList')"
          >
            {{ textK('UI_RECOMMENDATION_TAB_HEADLINE') }}
          </TabButton>
        </Tab>
        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeInTranslate"
          leave-active-class="animated fadeOutTranslate"
          mode="out-in"
        >
          <component :is="component" :sku="sku" :id="id" :owning-system="owningSystem"></component>
        </transition>
      </div>
    </div>
  </section>
</template>

<style scoped src="./product-details-relations-tab-group.scss" lang="scss"></style>

<script lang="ts" setup>
import TabButton from '@/src/core/components/ui/tab/tab-button/tab-button.vue';
import Tab from '@/src/core/components/ui/tab/tab.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { useRoute } from '@/src/core/utils/router';
// these imports look unused but they are I promise
import ProductDetailsInterchangeableList from '@/src/market/components/product/product-details/product-details-interchangeable-list/product-details-interchangeable-list.vue';
import ProductDetailsRecommendationList from '@/src/market/components/product/product-details/product-details-recommendation-list/product-details-recommendation-list.vue';
import {
  isAirbus,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import { Component, computed, onMounted, ref, watch } from 'vue';

interface Props {
  sku: string;
  id: string;
  owningSystem: string;
  isMarketplace?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isMarketplace: false,
});

const textK = useText();
const productRelationsStore = useProductRelationsStore();
const route = useRoute();
const uiVariant = UiVariant;
const component = ref<Component>(ProductDetailsRecommendationList);
const tabGroup = ref<HTMLElement | null>(null);

const productAdditionalData = computed(() =>
  productAdditionalDataService(props.id, props.owningSystem),
);

const relations = computed(() => productRelationsStore.relationBySku(props.sku));

const isRecommendationsChecked = computed(() => {
  return relations.value?.IsRecommendationsChecked || false;
});

const isInterchangeablesChecked = computed(() => {
  return relations.value?.IsInterchangeablesChecked || false;
});

const isRelationsChecked = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return (
      productAdditionalData.value.interchangeables &&
      productAdditionalData.value.interchangeables.length > 0
    );
  }

  return isRecommendationsChecked.value && isInterchangeablesChecked.value;
});

const hasInterchangeables = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return (
      productAdditionalData.value.interchangeables &&
      productAdditionalData.value.interchangeables.length > 0
    );
  }

  return relations.value?.HasInterchangeables || false;
});

const recommendationProducts = computed(() => relations.value?.Recommendations || []);

const switchComponent = (name: string) => {
  component.value = name;
};

const enableRecommendations = computed(() => hasFeature(FEATURES.RECOMMENDATIONS));

const routeQueryPDP = computed(() => route.query.fromPDP);

function navigateToTop() {
  const subContentHeader = document.querySelector('.sub-content-header');
  if (subContentHeader && route.query.fromPDP) {
    subContentHeader.scrollIntoView({ behavior: 'smooth' });
  }
}

const displayInterChangeables = () => {
  const withInterChangeables = productRelationsStore.relations.some(
    (data) => data?.HasInterchangeables === true,
  );
  component.value =
    withInterChangeables || hasInterchangeables.value
      ? ProductDetailsInterchangeableList
      : ProductDetailsRecommendationList;
};

watch(
  () => isRelationsChecked.value,
  () => {
    if (hasInterchangeables.value || recommendationProducts.value.length > 0) {
      // Select default component
      displayInterChangeables();

      if (route.query.scrollTo === 'interchangeables') {
        setTimeout(() => {
          if (tabGroup.value !== undefined) {
            tabGroup.value?.scrollIntoView({ behavior: 'smooth' });
          }
        }, 500);
      }
    }
  },
  { immediate: true },
);

watch(
  () => routeQueryPDP.value,
  () => {
    navigateToTop();
  },
  { immediate: true },
);

onMounted(async () => {
  const fetchInterChangeable = await productRelationsStore.prefetchInterchangeables({
    sku: props.sku,
  });
  const { offerId } = route.params;
  if (fetchInterChangeable.IsSuccess && offerId === 'satair') {
    displayInterChangeables();
  }

  if (enableRecommendations.value) {
    await productRelationsStore.prefetchRecommendations({
      sku: props.sku,
    });
  }
});
</script>
