<template>
  <div ref="detailsWrapper" class="product-details-wrapper">
    <ProductDetailsSkeleton v-if="isBusy"></ProductDetailsSkeleton>
    <div v-else-if="product" class="product-details-component-wrapper">
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInTranslate"
        leave-active-class="animated fadeOutTranslate"
        mode="out-in"
      >
        <ProductDetailsPage :id="product.Id" />
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { TrackingCache } from '@/src/core/services/tracking-utils-service';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem, Product } from '@/src/core/types/api';
import { ProductAirbusPart } from '@/src/core/types/interfaces';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import ProductDetailsPage from '@/src/market/components/product/product-details/product-details-page/product-details-page.vue';
import ProductDetailsSkeleton from '@/src/market/components/product/product-details/product-details-skeleton/product-details-skeleton.vue';
import {
  handleProcurementUrlRedirects,
  redirectToCurrentPathWithoutProcurement,
  redirectToSaleNew,
} from '@/src/market/services/procurement-redirects';
import {
  productAdditionalData as getProductAdditionalData,
  isAirbusProduct,
} from '@/src/market/services/product-parts';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { serviceReferenceCheck } from '@/src/market/services/service-references';
import { isBlueCustomer, isMixedCustomer, isRedCustomer } from '@/src/market/services/user-service';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useProductStore } from '@/src/market/stores/product';
import { useProductDetailsStore } from '@/src/market/stores/product-details';
import { useServiceReferenceStore } from '@/src/market/stores/service-reference';
import { useUserStore } from '@/src/profile/stores/user';

const authenticationStore = useAuthenticationStore();
const serviceRefsStore = useServiceReferenceStore();
const productStore = useProductStore();
const productDetailsStore = useProductDetailsStore();
const userStore = useUserStore();
const airbusPartStore = useAirbusPartStore();

// TODO: When updating to Vue 3, switch to useRoute hook from 'vue'
const vm = getCurrentInstance()?.proxy;
const route = vm?.$route;

const isWindowResizing = ref(false);
const isNewLogin = ref(false);
const isTrackingExecuted = ref(false);
const detailsWrapper = ref<HTMLElement | null>(null);

const product = computed(() => {
  const { offerId, manufacturer, manufacturerAid } = vm?.$route?.params ?? {};
  const sku = `${manufacturerAid}:${manufacturer}`;

  if (offerId === 'airbus' || offerId === 'satair') {
    const owningSystem: OwningSystem = offerId === 'airbus' ? OwningSystem.BLUE : OwningSystem.RED;
    return productStore.productBySkuAndOwningSystem(sku.toUpperCase(), owningSystem);
  }

  return productStore.productById(offerId);
});

const productOwningSystem = computed(() => {
  return product.value?.OwningSystem as OwningSystem;
});

const productCode = computed(() => {
  return product.value?.Code;
});

const marketOfferUrl = computed(() => {
  return product.value?.MarketOfferUrl || null;
});

const isBusy = computed(() => {
  return productDetailsStore.isBusy;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const productAdditionalData = computed(() => {
  return getProductAdditionalData(productCode.value || '', productOwningSystem.value);
});

const fetchAirbusProcurements = async () => {
  if (
    productCode.value &&
    productOwningSystem.value &&
    isBlueOwningSystem(productOwningSystem.value)
  ) {
    await airbusPartStore.fetchAirbusParts({
      OfferId: productCode.value,
      Quantity: 1,
    });

    const partRequestData = getProductAdditionalData(productCode.value, productOwningSystem.value);
    if (product.value) {
      executeAirbusTracking(product.value);
    }

    if (!partRequestData) {
      return undefined;
    }

    return (partRequestData as ProductAirbusPart).procurements;
  } else {
    return undefined;
  }
};

const fetchProductDetails = () => {
  productDetailsStore.reset();

  return productDetailsStore.fetchProductDetails({
    manufacturer: route?.params.manufacturer,
    manufacturerAid: route?.params.manufacturerAid,
    offerId: route?.params.offerId,
  });
};

const correctProcurementUrl = async () => {
  const procurements = await fetchAirbusProcurements();
  handleProcurementUrlRedirects(procurements);
};

const currentUser = computed(() => {
  return userStore.currentUser;
});

const executeRedCustomerTracking = (product: Product) => {
  if (!isTrackingExecuted.value) {
    AdobeLaunchTracking.productView(product);
    isTrackingExecuted.value = true;
  }
};

const userUnitType = computed(() => {
  return isBlueCustomer() ? OwningSystem.BLUE : OwningSystem.RED;
});

const executeAirbusTracking = (product: Product) => {
  if (!isTrackingExecuted.value) {
    TrackingCache.setOwningSystem(product.OwningSystem);
    TrackingCache.setProductId(product.Id);
    AdobeLaunchTracking.productView(product);
    isTrackingExecuted.value = true;
  }
};

const executeTracking = (product: Product) => {
  if (isAirbusProduct(product.OwningSystem)) {
    executeAirbusTracking(product);
  } else {
    executeRedCustomerTracking(product);
  }
};

const compareOwningSystem = () => {
  if (
    vm?.$route?.params.procurementType &&
    isAuthenticated.value &&
    (isMixedCustomer() || isBlueCustomer())
  ) {
    // correct url to match shown procurement if necessary
    correctProcurementUrl();
  } else if (isAuthenticated.value === false || isRedCustomer()) {
    // show anonymous version of the page
    redirectToCurrentPathWithoutProcurement();
  }
};

const fetchProduct = async () => {
  const { offerId, manufacturer, manufacturerAid } = vm?.$route?.params ?? {};
  if (offerId === 'airbus' || offerId === 'satair') {
    const owningSystem = offerId === 'airbus' ? OwningSystem.BLUE : OwningSystem.RED;
    await productStore.lookupProduct({
      cageCode: manufacturer,
      productNumber: manufacturerAid,
      owningSystem,
    });
  } else {
    await productStore.fetchAndUpdateProductByOfferId({
      id: route?.params.offerId,
      force: true,
    });
  }

  scrollToTop();
};

/**
 * Detect if window is resizing and set isWindowResizing to true
 */
const setWindowResize = () => {
  const width = window.innerWidth;
  ResizeEventBus.$on('resize', () => {
    if (window.innerWidth !== width) {
      isWindowResizing.value = true;
    }
  });
};

// Always scroll to Top when navigating between PDP to PDP
const scrollToTop = () => {
  const elem = document.querySelector('.close-btn');
  if (elem) {
    elem.scrollIntoView({ behavior: 'smooth' });
  }
};

watch(
  () => isAuthenticated.value,
  () => {
    if (product.value && isAirbusProduct(product?.value.OwningSystem)) {
      productDetailsStore.setIsBusy(true);
    }
    isNewLogin.value = true;
  },
);

watch(
  () => vm?.$route.path,
  () => {
    console.log('fetch', vm?.$route.path);
    productDetailsStore.fetchProductDetails({
      manufacturer: vm?.$route?.params.manufacturer,
      manufacturerAid: vm?.$route?.params.manufacturerAid,
      offerId: vm?.$route?.params.offerId,
    });
    fetchProduct();
  },
);

watch(
  () => userUnitType.value,
  async (newValue) => {
    if (newValue && isNewLogin.value) {
      const procurements = await fetchAirbusProcurements();
      await redirectToSaleNew(marketOfferUrl.value, procurements);
    }
    await fetchProductDetails();
  },
);

watch(
  () => currentUser.value,
  async (newValue) => {
    if (newValue && product.value) {
      compareOwningSystem();
      await fetchProduct();
    }

    if (isRedCustomer() && !isBlueCustomer()) {
      productDetailsStore.setIsBusy(false);
    }
  },
);

watch(
  () => product.value,
  async (newValue, oldValue) => {
    if (!newValue) {
      await fetchProduct();
    }

    if (newValue?.Id !== oldValue?.Id && isAuthenticated.value) {
      compareOwningSystem();
    }

    if (product.value) {
      executeTracking(product.value);
    }
  },
);

watch(
  () => productAdditionalData.value,
  () => {
    if (
      productAdditionalData.value &&
      productCode.value &&
      productOwningSystem.value &&
      !isBlueOwningSystem(productOwningSystem.value)
    ) {
      const productData = productStore.productByCode(productCode.value);
      setWindowResize();

      if (!isWindowResizing.value && productData) {
        executeRedCustomerTracking(productData);
      }
    }
  },
);

onMounted(async () => {
  if (route?.params.procurementType && !isAuthenticated.value) {
    // show anonymous version of the page
    redirectToCurrentPathWithoutProcurement();
  }

  await fetchProduct();
  await fetchProductDetails();

  const { fetchServiceReferences } = serviceRefsStore;
  const { AirbusProprietary, IsTool } = product.value ?? {};
  const procurementType = vm?.$route?.params.procurementType;

  const shouldFetchServiceReferences = serviceReferenceCheck(
    procurementType,
    IsTool,
    AirbusProprietary,
  );

  if (shouldFetchServiceReferences && procurementType) {
    fetchServiceReferences(procurementType, IsTool, AirbusProprietary);
  }
});

onBeforeUnmount(() => {
  productDetailsStore.reset();
});
</script>
