<template>
  <div
    ref="assistant"
    class="assistant"
    :class="{
      'assistant--minified': visible === assistantStates.Minified,
      'assistant--fullscreen': visible === assistantStates.FullScreen,
    }"
  >
    <transition
      name="fade"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        class="assistant__overlay"
        :style="minifiedStyling"
        ref="assistantOverlay"
        v-if="visible !== assistantStates.Hidden"
      ></div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInScale"
      leave-active-class="animated fadeOutScale"
    >
      <div class="assistant__content" v-if="visible === assistantStates.FullScreen">
        <div class="assistant__inner-content">
          <transition
            name="fade"
            enter-active-class="animated fadeInTranslateUp"
            leave-active-class="animated fadeOutTranslateDown"
            mode="out-in"
            role="dialog"
          >
            <component :is="component"></component>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fadeDown">
      <button
        v-if="!assistantIsLocked && visible === assistantStates.FullScreen"
        @click="hideAssistant"
        class="hide-assistant-button button-invisible"
      >
        <svgicon name="ui-close"></svgicon>
      </button>
    </transition>
  </div>
</template>

<style lang="scss" src="./assistant-container.scss" scoped></style>

<script lang="ts" setup>
import assistantTools from '@/src/core/components/assistant/tools';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { AssistantStates } from '@/src/core/types/ui';
import Vue, { computed, ref, watch } from 'vue';

const assistantStore = useAssistantStore();
const assistantStates = AssistantStates;
const assistant = ref<HTMLElement | null>(null);
const assistantOverlay = ref<HTMLElement | null>(null);
const component = ref<string | null>(null);
const minifiedStyling = ref({});

const visible = computed(() => {
  return assistantStore.assistantState;
});

const assistantIsLocked = computed(() => {
  return assistantStore.assistantLocked;
});

const assistantComponent = computed(() => {
  return assistantStore.assistantComponent;
});

const assistantState = computed(() => {
  return assistantStore.assistantState;
});

const hideAssistant = () => {
  if (!assistantIsLocked.value) {
    assistantStore.closeAssistant();
  }
};

const minifyAssistantHandling = () => {
  const targetWidth: number = 90 / window.innerWidth;
  const targetHeight: number = 90 / window.innerHeight;
  return { transform: `scaleX(${targetWidth}) scaleY(${targetHeight})` };
};

watch(
  () => assistantState.value,
  (currentState: AssistantStates, oldState: AssistantStates) => {
    if (currentState === oldState) {
      return;
    }

    if (currentState === AssistantStates.Minified) {
      minifiedStyling.value = minifyAssistantHandling();
    } else {
      minifiedStyling.value = {};
    }
  },
);

watch(
  () => assistantComponent.value,
  () => {
    if (!assistantComponent.value) {
      return;
    }
    Vue.component(assistantComponent.value, assistantTools[assistantComponent.value]);
    component.value = assistantComponent.value;
  },
);
</script>
