export default function GenerateComponentName() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZ';
  const stringLength = 8;
  let randomString = '';

  for (let i = 0; i < stringLength; i++) {
    const num = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(num, num + 1);
  }

  return randomString;
}
