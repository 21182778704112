import { OrderApi } from '@/src/core/api';
import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { Order, OrderReceipt } from '@/src/core/types/api';
import { IOrderListQuery, IOrderOverview } from '@/src/core/types/interfaces';
import { OrdersService, RECENT_ORDERS_SIZE } from '@/src/market/services/orders-service';
import { SearchService } from '@/src/market/services/search-service';
import { defineStore } from 'pinia';
import { useCartStore } from './cart';

interface OrdersState {
  orders: Order[];
  page: number;
  totalPages: number;
  totalCount: number;
  query: string;
  recentOrders: Order[];
  receipt: OrderReceipt;
  filters: IOrderListQuery;
  isBusy: boolean;
}

export const useOrdersStore = defineStore('orders', {
  state: (): OrdersState => ({
    orders: [],
    page: 0,
    totalPages: 0,
    totalCount: 0,
    query: '',
    recentOrders: [],
    receipt: {} as OrderReceipt,
    filters: {} as IOrderListQuery,
    isBusy: false,
  }),
  actions: {
    orderListUpdated(payload: { OrderList: IOrderOverview; loadMore?: boolean }) {
      if (!payload.loadMore) {
        this.orders = [];
      }

      this.orders.push(...payload.OrderList.Orders);
      this.page = payload.OrderList.Page;
      this.totalPages = payload.OrderList.TotalPages;

      if (this.totalCount === 0) {
        this.totalCount = payload.OrderList.Count;
      }
    },
    updateOrderListFilters(payload: { Query: IOrderListQuery }) {
      this.filters = Object.assign({}, this.filters, payload.Query);
    },
    orderPlaced(payload: { receipt: OrderReceipt }) {
      this.receipt = payload.receipt;
    },
    clearFilterGroup(group: readonly string[]) {
      for (const key of group) {
        delete this.filters[key];
      }

      this.filters = {
        ...this.filters,
      };
    },
    async fetchRecentOrders() {
      this.isBusy = true;

      const facetMap = OrdersService.facetMapFromFilters({});
      const facets = OrdersService.buildFacetQuery(facetMap);
      const size = RECENT_ORDERS_SIZE;

      const {
        IsSuccess,
        Data: { Orders: Data },
      } = await Req({
        url: OrderApi.AllOrders,
        params: { facets, size },
      });

      if (IsSuccess) {
        this.recentOrders = [];
        this.recentOrders.push(...Data);
      }

      this.isBusy = false;
      return { IsSuccess };
    },
    async fetchOrdersList() {
      this.isBusy = true;

      const { fromDate, toDate } = OrdersService.extractDatesWithTimezone(
        SearchService.escape(this.filters),
      );
      const facetMap = OrdersService.facetMapFromFilters(SearchService.escape(this.filters));
      const facets = OrdersService.buildFacetQuery(facetMap);

      const { IsSuccess, Data } = await Req(
        {
          url: OrderApi.AllOrders,
          params: { facets, fromDate, toDate },
        },
        new ReqQueue(ReqQueueTypes.Default, 'OrderList'),
      );

      if (IsSuccess) {
        this.orderListUpdated({ OrderList: Data });
      }

      this.isBusy = false;

      return { IsSuccess };
    },
    async cancelFetchOrderList() {
      CancelReqCategory('OrderList');
    },
    async loadMoreOrdersList() {
      const pageNumber = this.page + 1;
      const facetMap = OrdersService.facetMapFromFilters(this.filters);
      const facets = OrdersService.buildFacetQuery(facetMap);
      const { fromDate, toDate } = OrdersService.extractDatesWithTimezone(this.filters);

      if (pageNumber === this.totalPages) {
        return;
      }

      this.isBusy = true;

      const { IsSuccess, Data } = await Req({
        url: OrderApi.AllOrders,
        params: {
          p: pageNumber,
          facets,
          fromDate,
          toDate,
        },
      });

      if (IsSuccess) {
        this.orderListUpdated({ OrderList: Data, loadMore: true });
      }

      this.isBusy = false;

      return { IsSuccess };
    },
    clearFilters() {
      this.filters = {};
    },
    async addOrderToCart(payload: { orderCode: string; cartCode: string }) {
      const cartStore = useCartStore();

      const { IsSuccess, Data } = await Req({
        url: OrderApi.AddOrderToCart(payload.orderCode, payload.cartCode),
      });

      if (IsSuccess) {
        cartStore.fetchCart();
      }

      return { IsSuccess, Data };
    },
  },
});
