<template>
  <VSimpleTable
    class="product-details-table"
    :headers="['Documentation files', '']"
    v-if="isAuthenticated && isAirbusProduct && downloadables.length > 0 && !isRestricted"
  >
    <tbody>
      <VSimpleTableRow
        v-for="download in downloadables"
        :key="download.documentID"
        v-ui-test="'product-document-infos'"
      >
        <VSimpleTableCell :style="{ width: '75%' }">
          {{ download.documentName }}
        </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="download-container">
          <DotLoader
            v-if="isDownloading && clickDocument === download.documentName"
            :loading="true"
          />
          <button
            v-else
            :disabled="isDownloading"
            class="download-link"
            type="button"
            @click="downloadDocument({ id: download.documentID, name: download.documentName })"
          >
            Download
          </button>
        </VSimpleTableCell>
      </VSimpleTableRow>
    </tbody>
  </VSimpleTable>
</template>

<style scoped src="./product-details-documents.scss" lang="scss"></style>

<script lang="ts" setup>
import { ProductApi } from '@/src/core/api';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { OwningSystem } from '@/src/core/types/api';
import { DocumentInfos, ProductAirbusPart } from '@/src/core/types/interfaces';
import { useRoute } from '@/src/core/utils/router';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { isBlueCustomer } from '@/src/market/services/user-service';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { computed, ref } from 'vue';

interface Props {
  id: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const authenticationStore = useAuthenticationStore();
const downloadsStore = useDownloadsStore();
const airbusPartStore = useAirbusPartStore();
const route = useRoute();

const isDownloading = ref(false);
const clickDocument = ref('');

const isAirbusProduct = computed(() => isBlueOwningSystem(props.owningSystem as OwningSystem));

const additionalData = computed<ProductAirbusPart | null | undefined>(() => {
  if (isAirbusProduct.value) {
    return airbusPartStore.airbusPartByOfferId(props.id);
  }
  return null;
});

const procurement = computed(() => {
  if (additionalData.value) {
    return getProcurementByRouteParams(route.params, additionalData.value.procurements);
  }
  return undefined;
});

const downloadables = computed<DocumentInfos[]>(() => {
  if (!procurement.value) {
    return [];
  }
  return procurement.value.documentInfos;
});

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const downloadDocument = async ({ id, name }: { id: string; name: string }) => {
  isDownloading.value = true;
  clickDocument.value = name;
  await downloadsStore.downloadFile({
    url: ProductApi.ProductDownloadFile,
    params: { id, fileName: name },
  });
  isDownloading.value = false;
  clickDocument.value = '';
};

const userOwningSystem = computed(() => (isBlueCustomer() ? OwningSystem.BLUE : OwningSystem.RED));

const isRestricted = computed(() => userOwningSystem.value !== props.owningSystem);
</script>
