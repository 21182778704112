<template>
  <div ref="dropdown" class="dropdown-wrapper">
    <portal to="main-root">
      <transition :name="transitionName()">
        <div
          class="dropdown-position"
          :class="{
            'mobile-bottom-position': mobileBottomPosition,
            'is-in-modal': modalVisible,
            'is-in-assistant': isAssistant,
            'is-in-product-card': isProductCard,
          }"
          :style="{ top: topPosition, left: leftPosition, minWidth: minWidth }"
          v-if="dropdownMounted"
        >
          <div
            :is="dropdownStyle ? 'ul' : 'div'"
            class="dropdown"
            ref="innerDropdown"
            @touchstart.stop
            @click.stop
            :class="{
              'dropdown--align-right': alignLeft(),
              'dropdown--align-bottom': vAlignBottom(),
              'dropdown-style': dropdownStyle,
            }"
          >
            <slot />
          </div>
        </div>
      </transition>
    </portal>
  </div>
</template>

<style scoped src="./dropdown.scss" lang="scss" />

<script lang="ts" setup>
import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { useModalStore } from '@/src/core/stores/modal';
import { useUIStore } from '@/src/core/stores/ui';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { useRoute } from '@/src/core/utils/router';
import { ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

interface Props {
  mobileBottomPosition?: boolean;
  dropdownStyle?: boolean;
  scrollToElementIndex?: number;
  isAssistant?: boolean;
  isProductCard?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  mobileBottomPosition: false,
  dropdownStyle: false,
  isAssistant: false,
  isProductCard: false,
  scrollToElementIndex: undefined,
});

const assistantStore = useAssistantStore();
const uiStore = useUIStore();
const modalStore = useModalStore();
const breakpointWidth = BreakpointWidth;
const uniqueId = new GenerateUniqueIdentifier().uuid4();
const topPosition = ref('');
const leftPosition = ref('');
const minWidth = ref('');
const dropdown = ref<HTMLElement | null>(null);
const innerDropdown = ref<HTMLElement | null>(null);
const dropdownMounted = ref(false);
const emit = defineEmits(['dropDownOpen']);
const route = useRoute();

const scrollParams = computed(() => {
  return { block: 'center' } as ScrollIntoViewOptions;
});

const modalVisible = computed(() => {
  return !!assistantStore.assistantComponent || modalStore.isVisible;
});

const storeDropdownIsOpen = computed(() => {
  return uiStore.dropdownIsOpen;
});

const scrollTo = (index: number) => {
  if (
    index < 0 ||
    !(innerDropdown.value && innerDropdown.value.children) ||
    index > innerDropdown.value.children.length - 1
  ) {
    return;
  }

  innerDropdown.value.children[index].scrollIntoView(scrollParams.value);
};

const mqBelowRabbit = () => {
  return window.innerWidth < breakpointWidth.Rabbit;
};

const closeDropdown = () => {
  emit('dropDownOpen', false);

  ScrollEventBus.$off('scroll', top);
  ScrollEventBus.$off('modalScroll', top);

  ResizeEventBus.$off('resize', left);
  ResizeEventBus.$off('resize', top);
  ResizeEventBus.$off('resize', width);

  if (storeDropdownIsOpen.value === uniqueId) {
    uiStore.setDropdownOpen({ id: '' });
  }
};

const openDropdown = () => {
  uiStore.setDropdownOpen({ id: uniqueId });

  emit('dropDownOpen', true);
  dropdownPositioning();

  // SCROLL TO A SELECTED VALUE on OPEN
  if (props.scrollToElementIndex) {
    setTimeout(() => {
      scrollTo(props.scrollToElementIndex);
    }, 50);
  }
};

const dropdownPositioning = () => {
  top();
  left();
  width();

  ScrollEventBus.$on('scroll', top);
  ScrollEventBus.$on('modalScroll', top);

  ResizeEventBus.$on('resize', left);
  ResizeEventBus.$on('resize', top);
  ResizeEventBus.$on('resize', width);
};

const top = () => {
  if (props.mobileBottomPosition && mqBelowRabbit()) {
    topPosition.value = '';
    return;
  }
  const el: HTMLElement | null = dropdown.value;

  // I just want a height... and that's hard when it's all moved to a PORTAL...
  // so now i recurse my way to the nearest 'height > 0'...
  const newTopPosition = (element: HTMLElement | null, includeHeight?: boolean): number => {
    const height = (includeHeight && element?.getBoundingClientRect().height) || 0;
    const top = element?.getBoundingClientRect().top || 0;
    return element
      ? element.getBoundingClientRect().height > 0
        ? top + height
        : newTopPosition(element.parentElement, includeHeight)
      : 0;
  };

  topPosition.value = el
    ? vAlignBottom()
      ? newTopPosition(el) - 10 + 'px'
      : newTopPosition(el, true) + 10 + 'px'
    : '';
};

const left = () => {
  if (props.mobileBottomPosition && mqBelowRabbit()) {
    leftPosition.value = '';
    return;
  }

  const newLeftPosition = (element: HTMLElement | null, includeWidth?: boolean): number => {
    const width = (includeWidth && element?.getBoundingClientRect().width) || 0;
    const left = element?.getBoundingClientRect().left || 0;
    return element
      ? element.getBoundingClientRect().width > 0
        ? left + width
        : newLeftPosition(element.parentElement, includeWidth)
      : 0;
  };

  const el: HTMLElement | null = dropdown.value;

  leftPosition.value = el
    ? alignLeft()
      ? newLeftPosition(el, true) + 'px'
      : newLeftPosition(el) + 'px'
    : '';
};

const width = () => {
  if (props.mobileBottomPosition && mqBelowRabbit()) {
    minWidth.value = '';
    return;
  }

  const newMinWidth = (element: HTMLElement | null): number => {
    const width = element?.getBoundingClientRect().width || 0;
    return element
      ? element.getBoundingClientRect().width > 0
        ? width
        : newMinWidth(element.parentElement)
      : 0;
  };

  const el: HTMLElement | null = dropdown.value;
  minWidth.value = el ? (newMinWidth(el) > 250 ? newMinWidth(el) + 'px' : '') : '';
};

const alignLeft = () => {
  const el: HTMLElement | null = dropdown.value;
  return el
    ? el.getBoundingClientRect().left + el.getBoundingClientRect().width < window.innerWidth
    : false;
};

const vAlignBottom = () => {
  if (!dropdown.value) {
    return false;
  }

  const el: HTMLElement | null = dropdown.value;
  return el
    ? el.getBoundingClientRect().height + el.getBoundingClientRect().bottom >
        window.innerHeight / 1.75
    : false;
};

const transitionName = () => {
  return (props.mobileBottomPosition && mqBelowRabbit()) || (!mqBelowRabbit() && vAlignBottom())
    ? 'fadeDown'
    : 'fadeUp';
};

watch(
  () => props.scrollToElementIndex,
  (index: number) => {
    scrollTo(index);
  },
);

watch(
  () => storeDropdownIsOpen.value,
  (openId: string) => {
    //  If dropdown is closed from store or another dropdown is opnened (another id) => CLOSE IT
    if (openId !== uniqueId) {
      closeDropdown();
    }
  },
);

watch(
  () => route,
  () => {
    closeDropdown();
  },
);

watch(
  () => modalVisible.value,
  () => {
    closeDropdown();
  },
);

onMounted(() => {
  if (uiStore.dropdownIsOpen !== uniqueId) {
    uiStore.setDropdownOpen({ id: uniqueId });
  }

  openDropdown();
  dropdownMounted.value = true;
});

onBeforeUnmount(() => {
  closeDropdown();
});
</script>
