<template>
  <a
    @click.prevent="
      openModal({
        modalComponent: 'ModalLoadContent',
        params: { url: blockLink, title: title },
        first: true,
      })
    "
    :href="href"
    ><slot
  /></a>
</template>

<script lang="ts" setup>
import useModal from '@/src/core/hooks/useModal';
import { useLinksStore } from '@/src/core/stores/links';

interface Props {
  to: string;
  href: string;
  title: string;
}

const props = defineProps<Props>();

const openModal = useModal();
const linkStore = useLinksStore();

const blockLink = () => {
  const key = linkStore.links.find((link) => link.Value === props.to)?.Key.split(' ')[0];
  if (key) {
    const modalKey: any = `${key}_ModalUrl`;
    return linkStore.getKey(modalKey);
  } else {
    return props.to;
  }
};
</script>
