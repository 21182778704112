import { FEATURES, hasFeature } from '@/src/core/services/features';
import { NavigationGuardNext, Route } from 'vue-router';

export const repairExchangeBeforeEnterFeatureCheck = (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
) => {
  if (
    (to.name === 'repairExchangeOrder' ||
      to.name === 'repairExchangeOrderProduct' ||
      to.name === 'repairExchangeOrderOffer') &&
    !hasFeature(FEATURES.REPAIR_EXCHANGE_SERVICE_ORDERS)
  ) {
    next({
      name: 'orders',
    });
  } else {
    next();
  }
};

export const leaseOrderBeforeEnterFeatureCheck = (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
) => {
  if (
    (to.name === 'leaseOrder' ||
      to.name === 'leaseOrderProduct' ||
      to.name === 'leaseOrderOffer') &&
    !hasFeature(FEATURES.LEASE_SERVICE_ORDERS)
  ) {
    next({
      name: 'orders',
    });
  } else {
    next();
  }
};
