<template>
  <div class="product__company-logo-wrapper">
    <span class="product__company-logo-title">{{ textK('UI_PRODUCT_SOLD_HANDLED_BY') }}</span>
    <img
      class="product__company-logo"
      v-if="supplierLogo"
      :src="supplierLogo"
      :alt="supplierDisplay"
    />
    <span
      class="product__supplier-text"
      v-else
      v-ui-test="'supplier'"
      :title="titleTag(product.SupplierDisplay)"
    >
      {{ product.SupplierDisplay }}
    </span>
  </div>
</template>

<style lang="scss" src="./product-company-logo.scss" scoped></style>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { Product } from '@/src/core/types/api';
import { computed } from 'vue';

interface Props {
  product: Product;
}

const props = defineProps<Props>();
const textK = useText();
const titleTag = useTitleTag;
const product = computed(() => props.product);

const supplierLogo = computed(() => {
  if (product.value.NewSupplierLogoCdnUrl) {
    return product.value.NewSupplierLogoCdnUrl;
  }

  if (product.value.NewSupplierLogoUrl) {
    return product.value.NewSupplierLogoUrl;
  }

  return product.value.SupplierLogo;
});

const supplierDisplay = computed(() => {
  return product.value.SupplierDisplay;
});
</script>
