<template>
  <div
    class="content-navigation-list-item"
    :class="{ 'content-navigation-list-item--large': size === uiSize.Large }"
    v-if="navigationItem"
  >
    <div class="content-navigation-list-item__name">
      <a
        v-if="!noLink"
        class="content-navigation-list-item__name__link"
        :class="{ active: active, 'exact-active': exactActive }"
        :href="routeHref"
        @click="
          ($event) => {
            routeNavigate($event);
            closeDropdown();
          }
        "
        >{{ navigationItem.Name }}</a
      >
      <span
        v-else
        class="content-navigation-list-item__name__link"
        :class="{ active: active, 'exact-active': exactActive }"
        @click="isExpanded = !isExpanded"
        >{{ navigationItem.Name }}</span
      >
      <span
        v-if="navigationItem.SubItems.length > 0"
        class="content-navigation-list-item__expand-toggle"
      >
        <PlusMinusAnimation
          class="content-navigation-list-item__expand-toggle__icon"
          @click.native.stop.prevent="isExpanded = !isExpanded"
          :open="isExpanded"
        />
      </span>
    </div>
    <TransitionExpand>
      <ContentNavigationList
        v-if="navigationItem.SubItems.length > 0 && isExpanded"
        :navigation="navigationItem.SubItems"
      />
    </TransitionExpand>
  </div>
</template>

<style lang="scss" src="./content-navigation-list-item.scss" scoped />

<script lang="ts">
import PlusMinusAnimation from '@/src/core/components/ui/ani-icons/plus-minus/plus-minus.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useUIStore } from '@/src/core/stores/ui';
import { NavigationItem } from '@/src/core/types/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ContentNavigationList: () =>
      import(
        '@/src/content/components/content-navigation/content-navigation-list/content-navigation-list.vue'
      ),
    PlusMinusAnimation,
    TransitionExpand,
  },
})
export default class ContentNavigationListItem extends Vue {
  @Prop() public size: UiSize;
  @Prop() public navigationItem: NavigationItem;
  @Prop() public routeHref: string;
  @Prop() public routeNavigate: () => void;
  @Prop() public active: boolean;
  @Prop() public exactActive: boolean;
  @Prop() public noLink: boolean;

  public uiStore = useUIStore();
  public uiSize = UiSize;
  public isExpanded: boolean = false;

  public closeDropdown() {
    this.uiStore.setNavigationDropdownId({ id: null });
  }

  private mounted() {
    this.isExpanded = this.active;
  }
}
</script>
