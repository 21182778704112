<template>
  <form @submit.prevent="deleteUserProfile">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Medium">
      <template #header>
        <div class="typeset">
          <h3>
            {{ textK('UI_ADMINPAGE_DELETECONFIRMATION') }}
            {{ userData.Name || userData.Uid }}
          </h3>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'delete-user-profile-confirm-button'"
          :loading="isBusy"
          @click.prevent="deleteUserProfile"
          >{{ textK('UI_COMMON_DELETE') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script setup lang="ts">
import { useAdminStore } from '@/src/account/stores/admin';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { IUser } from '@/src/core/types/interfaces';
import { useRoute, useRouter } from '@/src/core/utils/router';
import { computed, onMounted, ref, watch } from 'vue';

import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';

const adminStore = useAdminStore();
const authenticationStore = useAuthenticationStore();
const modalStore = useModalStore();

const textK = useText();
const uiVariant = UiVariant;
const uiSize = UiSize;
const route = useRoute();
const router = useRouter();

const userData = ref<IUser>({} as IUser);

const isBusy = computed(() => adminStore.isBusy);
const users = computed(() => adminStore.users);
const currentUserId = computed(() => authenticationStore.getGlobalId);

watch(users, () => {
  modalStore.closeModal();
  modalStore.lockCurrentModal(false);
});

const deleteUserProfile = () => {
  if (userData.value.GlobalId) {
    modalStore.lockCurrentModal(true);

    if (userData.value.GlobalId !== currentUserId.value) {
      adminStore.deleteUser({ userId: userData.value.GlobalId }).then(() => {
        if (route.params && route.params.userid) {
          router.push({ name: 'admin' });
        }
      });
    } else {
      adminStore.deleteCurrentUser({ userId: userData.value.GlobalId });
    }
  }
};

const modalParams = computed(() => modalStore.params as IUser);

onMounted(() => {
  userData.value = modalParams.value || ({} as IUser);
});
</script>
